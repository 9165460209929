import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import { DefaultBadge } from "../../atoms/StatusBadge";
import { StringCustomField } from "../../components/CustomFieldForm";
import DeleteIconButton from "../../components/DeleteIconButton";
import { usePluginInfo } from "../../hooks/usePlugins";
import { EditButton } from "../../molecules/organization/integrations/Buttons";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { PluginInferenceTableCustomFieldModal } from "../modals/custom-fields/PluginInferenceTableModal";
import { fetchAssets } from "../../hooks/useAssets";
import { useNavigate } from "react-router-dom";
import { navigateToAsset } from "../../components/AssetNavigator";

type PluginInferenceTableCustomFieldProps = {
  value: string;
  onChange: (value: string) => void;
  edit?: boolean;
};

export const PluginInferenceTableCustomField = ({
  value,
  onChange,
  edit,
}: PluginInferenceTableCustomFieldProps) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const navigate = useNavigate();
  const isEmpty = !value || value === "";
  const { data: mlflow } = usePluginInfo("mlflow");
  const { data: databricksInfo } = usePluginInfo("databricks");
  const isDatabricksIntegration = mlflow?.tracking_server?.toLowerCase() === "databricks";

  const onClick = async () => {
    try {
      const assets = await fetchAssets({
        name: [value],
        "asset_type_name[]": ["Dataset"],
      });
      if ((assets?.count ?? 0) > 0) {
        const asset = assets.results[0];
        navigateToAsset({
          navigate,
          assetId: asset.id,
          assetNumber: asset.asset_num,
          asset: asset,
        });
      } else {
        if (isDatabricksIntegration) {
          window.open(`${databricksInfo.workspace_url}/explore/data/${value.replace(/\./g, "/")}`);
        }
      }
    } catch (e) {}
  };

  return isDatabricksIntegration ? (
    <>
      <PluginInferenceTableCustomFieldModal
        open={open}
        onClose={() => setOpen(false)}
        onChange={onChange}
      />
      {isEmpty && edit && (
        <TableTextWithEllipsis
          value="Add inference table"
          fullWidth={false}
          onClick={edit ? () => setOpen(true) : undefined}
          color={edit ? theme.palette.custom.hyperlink : theme.palette.custom.grayTypography}
          tootipPlacement="bottom-start"
        />
      )}
      {isEmpty && !edit && <TableTextWithEllipsis hideTooltip value="No inference table" />}
      {value !== "" && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <DefaultBadge value={value} onClick={onClick} />
          {edit && (
            <Box display="flex" flexDirection="row" gap="4px">
              <EditButton size="small" onEdit={() => setOpen(true)} />
              <DeleteIconButton
                size="14px"
                deleteConfirmationDialogTitle="Unlink inference table"
                onDeleteConfirm={() => onChange("")}
                deleteConfirmationButtonDescription="Unlink"
                deleteConfirmationDialogDescription="Are you sure you want to unlink the inference table?"
              />
            </Box>
          )}
        </Box>
      )}
    </>
  ) : (
    <>
      {edit && (
        <StringCustomField value={value} onChange={onChange} error={false} errorMessage="" />
      )}
      {!edit && <TableTextWithEllipsis value={value} />}
    </>
  );
};
