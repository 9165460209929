import { Box, useTheme } from "@mui/material";
import { NavbarIcon } from "./Icon";

interface INavbarArrowProps {
  onClick: () => void;
  marginLeft?: string;
}

const CloseNavbarButton = ({ onClick, marginLeft }: INavbarArrowProps) => {
  const theme = useTheme();
  return (
    <Box
      onClick={() => onClick()}
      sx={{
        borderRadius: "25px",
        color: "#FFFFFF",
        cursor: "pointer",
        backgroundColor: theme.palette.custom.navArrowBackground,
        width: "30px",
        height: "30px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: marginLeft ?? 0,
      }}
    >
      <NavbarIcon variant="close" color="#FFFFFF" sx={{ width: "16px", height: "16px" }} />
    </Box>
  );
};

export default CloseNavbarButton;
