import { Box, CircularProgress, Typography } from "@mui/material";

const LoaderLabeled = () => (
  <Box textAlign={"center"} p={4}>
    <CircularProgress />
    <Typography>Loading ...</Typography>
  </Box>
);

export default LoaderLabeled;
