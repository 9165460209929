import { Box, useTheme } from "@mui/material";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { PageDetailLayout } from "../../components/layout/PageDetailLayout";
import { ElementMapping, EvidenceTest } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { ChildrenBoxLoader } from "../../organisms/skeleton-loaders/ChildrenBoxLoader";
import { DetailsLoader } from "../../organisms/skeleton-loaders/DetailsLoader";
import { ListViewLoader } from "./ListViewLoader";
import { MappedElementsColumnsVisibility } from "../../organisms/tables/mapped-elements/MappedElementsColumnVisibility";
import { CustomFieldLoader } from "../../organisms/skeleton-loaders/CustomFieldLoader";

export const LibraryDetailsLoader = () => {
  const theme = useTheme();

  const fillBlankSpace = true;

  return (
    <PageDetailLayout
      leftComponent={
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
          <SecondaryBox>
            <DetailsLoader />
          </SecondaryBox>
          <ListViewLoader
            columnsVisibility={MappedElementsColumnsVisibility({
              theme,
              id: "",
              isFairoAdmin: true,
              editing: true,
              onEdit: (element: ElementMapping) => {},
              onDelete: (element: ElementMapping) => {},
            })}
            tableSize="small"
          />
        </Box>
      }
      rightComponent={
        <Box display="flex" flexDirection="column" gap="20px">
          <SecondaryBox>
            <Box display="flex" width="100%" flexDirection="column" gap="10px">
              <ChildrenBoxLoader />
            </Box>
          </SecondaryBox>
          <SecondaryBox>
            <Box display="flex" width="100%" flexDirection="column" gap="10px">
              <CustomFieldLoader showTitle={false} />
            </Box>
          </SecondaryBox>
          <SecondaryBox>
            <Box display="flex" width="100%" flexDirection="column" gap="10px">
              <ChildrenBoxLoader />
            </Box>
          </SecondaryBox>
        </Box>
      }
    />
  );
};
