import { Box, CircularProgress } from "@mui/material";
import moment from "moment";
import {
  createContext,
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import SubscriptionFailure from "../components/SubscriptionFailure";
import StripeStatusCodes from "../models/Stripe";
import {
  cancelSubscription,
  changeSubscription,
  getSubscriptionPlans,
  getSubscriptions,
  startSubscription,
} from "../services/OrganizationService";
import { useOrganizationGet } from "../state/QueryStore";
import { useSnackbarContext } from "./SnackbarContext";

// TODO: get better response from backend instead of raw Stripe subscription object
export type ISubscription = {
  id: string;
  status:
    | "incomplete"
    | "incomplete_expired"
    | "trialing"
    | "active"
    | "past_due"
    | "canceled"
    | "unpaid"
    | "paused";
  [key: string]: any;
};

export interface IPlan {
  id: string;
  created: number;
  active: boolean;
  default_price?: string;
  description?: string;
  images: string[];
  name: string;
  metadata: {
    CAN_SELF_SUBSCRIBE: "FALSE" | "TRUE";
    DISPLAY_PRICE: string;
    FEATURE_LIST: string;
  };
}

interface ISubscriptionContext {
  createSubscription: (planId: string) => Promise<void>;
  daysLeft: number;
  subscriptions: ISubscription[];
  cancel: () => Promise<void>;
  plans: IPlan[];
  currentPlan: IPlan | null;
  // Whether the current plan is cancelled (if it exists)
  isCancelled: boolean;
  updateSubscription: (planId: string) => Promise<void>;
  // Whether the initial data load completed
  isLoading: boolean;
  // Whether subscription info is getting updated
  isUpdating: boolean;

  // isDemoPlan chekc
  isDemoPlan: boolean;
}

const SubscriptionContext = createContext({} as ISubscriptionContext);

interface iSubscriptionProviderProps {
  children: ReactNode;
}

export const SubscriptionProvider: FunctionComponent<iSubscriptionProviderProps> = ({
  children,
}) => {
  const { data, isLoading: isOrgLoading } = useOrganizationGet();
  const { openSnackbar } = useSnackbarContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [daysLeft, setDaysLeft] = useState(0);
  const [subscriptions, setSubscriptions] = useState<ISubscription[]>([]);
  const [plansList, setPlansList] = useState<IPlan[]>([]);
  const [currentPlan, setCurrentPlan] = useState<IPlan | null>(null);
  const [isCancelled, setIsCancelled] = useState<boolean>(false);
  const isDemoPlan = currentPlan?.name === "Demo Trial";
  const billingStripeId = data?.billing_stripe_id;
  const cannotLoad = !isOrgLoading && !billingStripeId;
  const getSubscriptionInfo = async () => {
    if (billingStripeId) {
      const resp = await getSubscriptions(data.id);
      const subscriptions = resp.data;

      setSubscriptions(subscriptions);

      if (subscriptions.length === 0) {
        setIsOpen(true);
        return;
      }
      // assume 1st subscription is the current one
      const subscription = subscriptions[0];
      if (subscription.status === "paused") {
        setIsOpen(true);
      }
      if (subscription.status === "canceled") {
        setIsOpen(true);
      }
      setIsCancelled(subscription.cancel_at_period_end);
      switch (subscription.status) {
        case StripeStatusCodes.ended:
          setIsOpen(true);
          break;
        case StripeStatusCodes.trialing:
          const trialEnd = moment(subscription.trial_end * 1000);
          const daysLeft = trialEnd.diff(moment(), "days");
          setDaysLeft(daysLeft);
          break;
        default:
          break;
      }
    }
  };

  const getPlans = async () => {
    if (billingStripeId) {
      const resp = await getSubscriptionPlans(data.id);
      setPlansList(resp.data.sort((a, b) => a.created - b.created));
    }
  };

  // Load subscription info
  useEffect(() => {
    const loadData = async () => {
      const promises = [];
      promises.push(getSubscriptionInfo());
      promises.push(getPlans());
      await Promise.all(promises);
      setIsLoading(false);
    };
    if (billingStripeId && isLoading) {
      loadData();
    }
  }, [billingStripeId, isLoading]);

  // Set `currentPlan`
  useEffect(() => {
    if (subscriptions.length && plansList.length) {
      if (subscriptions[0].items?.data.length) {
        let subscribedPlan = subscriptions[0].items?.data[0].plan;
        let myPlan = plansList.find((p) => p.id === subscribedPlan.product);
        if (myPlan) {
          setCurrentPlan(myPlan);
        }
      }
    }
  }, [plansList, subscriptions]);

  const cancel = async () => {
    if (billingStripeId) {
      await cancelSubscription(data.id);
      openSnackbar("Subscription Canceled", "success", () => {});
      await getSubscriptionInfo();
    }
  };

  const createSubscription = async (planId: string) => {
    setIsUpdating(true);
    try {
      const resp = await startSubscription(data!.id, planId);
      if (resp.data.url) {
        // Couldn't auto subscribe. Redirect user to stripe page
        window.location.href = resp.data.url;
      } else {
        // Success! fetch latest subscription info
        await getSubscriptionInfo();
      }
    } finally {
      setIsUpdating(false);
    }
  };

  const updateSubscription = async (planId: string) => {
    setIsUpdating(true);
    try {
      await changeSubscription(data!.id, planId);
      await getSubscriptionInfo();
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <SubscriptionContext.Provider
      value={{
        createSubscription,
        updateSubscription,
        daysLeft,
        subscriptions,
        plans: plansList,
        currentPlan,
        cancel,
        isLoading,
        isUpdating,
        isCancelled,
        isDemoPlan,
      }}
    >
      {isLoading && !cannotLoad ? (
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          sx={{ zIndex: 1 }}
        >
          <CircularProgress size="3rem" />
        </Box>
      ) : (
        <>
          <SubscriptionFailure
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            subscriptions={subscriptions}
          />
          {children}
        </>
      )}
    </SubscriptionContext.Provider>
  );
};

export const useSubscription = () => useContext(SubscriptionContext);
