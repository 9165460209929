import { Box } from "@mui/material";
import { useState } from "react";
import { FairoPolicyTemplate } from "../../../components/FairoPolicyTemplate";
import { SmallModal } from "../../../components/SmallModal";
import { ExternalTask, JiraIssue } from "../../../models/types";
import { CreateNewJiraIssue } from "./jira/CreateNewJiraIssue";
import { SelectExistingJiraIssue } from "./jira/SelectExistingJiraIssue";

export const CreateJiraTaskModal = (props: {
  externalTaskId: ExternalTask | null;
  setExternalTaskId: (externalTaskId: ExternalTask | null) => void;
  jiraIssue: JiraIssue | null;
  setJiraIssue: (jiraIssue: JiraIssue | null) => void;
  open: boolean;
  onClose: () => void;
}) => {
  const { externalTaskId, setExternalTaskId, jiraIssue, setJiraIssue, open, onClose } = props;
  const [selectExistingIssue, setSelectExistingIssue] = useState<boolean>(!!externalTaskId);

  return (
    <SmallModal
      open={open}
      onClose={() => onClose()}
      title={`${selectExistingIssue ? "Select" : "Create"} Jira Issue`}
      size="small"
    >
      <Box display="flex" flexDirection="column" gap="10px" width="100%">
        {!selectExistingIssue && (
          <>
            <FairoPolicyTemplate
              hideButton
              title="Jira Issue already created?"
              description="You can connect your Fairo task to an existing Jira issue."
              buttonText="Select Issue"
              onClick={() => setSelectExistingIssue(true)}
              buttonSize="large"
            />
            <CreateNewJiraIssue
              jiraIssue={jiraIssue}
              onAdd={(issue: JiraIssue | null) => {
                setJiraIssue(issue);
                setExternalTaskId(null);
                onClose();
              }}
            />
          </>
        )}
        {selectExistingIssue && (
          <SelectExistingJiraIssue
            onCreateNew={() => setSelectExistingIssue(false)}
            jiraIssue={externalTaskId}
            onAdd={(issue: ExternalTask | null) => {
              setExternalTaskId(issue);
              setJiraIssue(null);
              onClose();
            }}
          />
        )}
      </Box>
    </SmallModal>
  );
};
