import { Box, useMediaQuery, useTheme } from "@mui/material";
import OrganizationContainer from "../../components/OrganizationContainer";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

export const OrganizationSettingsPlanLoader = () => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <OrganizationContainer
      content={
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent="center"
          gap="15px"
          flexWrap="wrap"
          alignItems={isSmScreen ? "center" : "stretch"}
        >
          <RectangularLoader height="465px" width="325px" />
          <RectangularLoader height="465px" width="325px" />
        </Box>
      }
    />
  );
};
