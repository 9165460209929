import { useEffect } from "react";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { useAction, useActions } from "../../hooks/useActions";
import { FieldHandlerProps, Action } from "../../models/types";
import { Awaitable } from "../../utilities/common";

export const ActionCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<Action[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const { data: action } = useAction(value || "");
  useEffect(() => {
    if (action && setObjectAttributes) {
      setObjectAttributes(action ?? {});
    }
  }, [action]);
  return (
    <SearchableDropdown<Action>
      label="Action"
      required={required}
      error={error}
      value={action || null}
      onChange={(newValue) => (newValue != null ? onChange(newValue.id) : onChange(null))}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [obj.name, obj.description ?? ""]}
    />
  );
};

export const ActionListCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<Action[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const { data: items } = useActions({ id: value });
  useEffect(() => {
    if (items && items.results && items.results.length && setObjectAttributes) {
      setObjectAttributes(items.results[0] ?? {});
    }
  }, [items]);
  return (
    <SearchableDropdown<Action>
      label="Action"
      required={required}
      error={error}
      multiple={true}
      value={value.length === 0 ? [] : items?.results ?? []}
      onChange={(newValue) => newValue != null && onChange(newValue.map((item) => item.id))}
      getOptionLabel={(obj) => obj.name}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [obj.name, obj?.description ?? ""]}
    />
  );
};
