import { LoadingButton } from "@mui/lab";
import { Box, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { SearchableDropdown } from "../../../components/SearchableDropdown";
import { useInputState } from "../../../hooks/useInputTextState";
import { searchUseCases } from "../../../hooks/useUseCases";
import { UseCase, User } from "../../../models/types";
import { YesOrNoSelectField } from "../../custom-fields/BooleanSelect";
import { CreateUseCaseForm } from "./CreateUseCaseForm";
import { UseCaseReadOnly } from "./UseCaseReadOnly";

type DefineUseCaseProps = {
  onUseCaseDefine: (data: {
    useCase?: string;
    useCaseName?: string;
    useCaseOwner?: string;
    useCaseDescription?: string;
  }) => void;
  onBack: () => void;
  onContinue: () => void;
  selectOnly?: boolean;
  selectedUseCase?: UseCase;
};

export const DefineUseCase = (props: DefineUseCaseProps) => {
  const { onUseCaseDefine, onBack, onContinue, selectOnly, selectedUseCase } = props;
  const theme = useTheme();

  const [selectedYes, setSelectedYes] = useState(false);
  const [selectedNo, setSelectedNo] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [useCaseReviewed, setUseCaseReviewed] = useState(false);

  const [showBooleanForm, setShowBooleanForm] = useState(true);
  const [showUseCaseDetails, setShowUseCaseDetails] = useState(false);
  const [showUseCaseForm, setShowUseCaseForm] = useState(false);

  const [useCase, setUseCase] = useState<UseCase | null>(null);

  const [name, setName, nameError, setNameError] = useInputState("");
  const [useCaseOwner, setUseCaseOwner] = useState<User | null>(null);
  const [description, setDescription] = useState<string>("");

  const handleNext = () => {
    setShowBooleanForm(false);
    if ((selectedYes && useCase) || (selectOnly && useCase)) {
      setShowUseCaseDetails(true);
      if (useCase && useCaseReviewed) {
        onUseCaseDefine({
          useCase: useCase.id,
        });
      }
      setUseCaseReviewed(true);
      return;
    }
    if (selectedNo) {
      if (name) {
        onUseCaseDefine({
          useCaseName: name,
          useCaseOwner: useCaseOwner?.id ?? "",
          useCaseDescription: description,
        });
      }
      setShowUseCaseForm(true);
      return;
    }
    if ((!selectedYes && !selectedNo) || (selectOnly && !useCase)) {
      onContinue();
    }
  };

  const handleBack = () => {
    if (showUseCaseDetails || showUseCaseForm) {
      setShowUseCaseDetails(false);
      setShowUseCaseForm(false);
      setUseCaseReviewed(false);
      setShowBooleanForm(true);
      return;
    }
    onBack();
  };

  useEffect(() => {
    if (selectedYes && useCase) {
      setDisabled(false);
    }
    if (selectedNo) {
      if (showUseCaseForm) {
        if (name) {
          setDisabled(false);
        } else {
          setDisabled(true);
        }
        return;
      }
      setDisabled(false);
    }
  }, [selectedYes, useCase, selectedNo, showUseCaseForm, name]);

  useEffect(() => {
    if (selectedUseCase) {
      setSelectedYes(true);
      setSelectedNo(false);
      setUseCase(selectedUseCase);
    }
  }, [selectedUseCase]);

  return (
    <Box display="flex" flexDirection="column" gap="10px" justifyContent="space-between">
      {selectOnly && !showUseCaseDetails && (
        <Box display="flex" flexDirection="column" gap="10px" width="100%" alignItems="center">
          <Typography variant="h1">Select Use Case</Typography>
          <Typography variant="body2">
            We noticed that you already have existing use cases. Would you like to link one to your
            assessment?
          </Typography>
          <Box display="flex" flexDirection="column" gap="10px" width="100%">
            <Typography variant="body2" color={theme.palette.custom.grayTypography}>
              Select the use case that you want to create the impact assessment for.
            </Typography>
            <SearchableDropdown<UseCase>
              label="Select or Search Use Case"
              required={true}
              value={useCase}
              onChange={(value) => setUseCase(value)}
              getOptions={searchUseCases}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={(obj) => obj.name ?? "-"}
            />
          </Box>
        </Box>
      )}

      {!selectOnly && showBooleanForm && (
        <Box display="flex" flexDirection="column" gap="10px" width="100%" alignItems="center">
          <Typography variant="h1">Select or Create Use Case</Typography>
          <Typography variant="body2">
            We noticed that you have use cases already created, do you want to select an existing
            one or create a new one?
          </Typography>
          <YesOrNoSelectField
            value={selectedYes ? "Yes" : selectedNo ? "No" : ""}
            onChange={(value) => {
              if (value === "Yes") {
                setSelectedYes(true);
                setSelectedNo(false);
              } else {
                setSelectedNo(true);
                setSelectedYes(false);
              }
            }}
            required={true}
            yesLabel="Yes, select existing use case"
            noLabel="No, create a new one"
          />
          {selectedYes && (
            <Box display="flex" flexDirection="column" gap="10px" width="100%">
              <Typography variant="body2" color={theme.palette.custom.grayTypography}>
                Select the use case that you want to create the impact assessment for.
              </Typography>
              <SearchableDropdown<UseCase>
                label="Select or Search Use Case"
                required={true}
                value={useCase}
                onChange={(value) => setUseCase(value)}
                getOptions={searchUseCases}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(obj) => obj.name ?? "-"}
              />
            </Box>
          )}
        </Box>
      )}
      {showUseCaseDetails && useCase && <UseCaseReadOnly useCaseId={useCase.id} />}
      {showUseCaseForm && (
        <CreateUseCaseForm
          name={name}
          setName={(name: string) => setName(name)}
          nameError={nameError}
          useCaseOwner={useCaseOwner}
          setUseCaseOwner={(owner: User | null) => setUseCaseOwner(owner)}
          description={description}
          setDescription={(description: string) => setDescription(description)}
        />
      )}
      <Box width="100%" display="flex" gap="10px" justifyContent="space-between">
        <LoadingButton onClick={() => handleBack()}>Go back</LoadingButton>
        <LoadingButton
          variant="contained"
          onClick={() => handleNext()}
          loading={loading}
          // disabled={disabled}
        >
          Next
        </LoadingButton>
      </Box>
    </Box>
  );
};
