import { useQuery } from "react-query";
import { MetricFetchOptions } from "../models/types";
import { getMetric, getMetrics } from "../services/MetricService";
import queryClient, { QueryKey } from "../state/QueryStore";
import { getVersionHistory } from "../services/VersionHistoryService";

export const useMetrics = (params: MetricFetchOptions) => {
  return useQuery([QueryKey.Metric, params], async () => {
    const { data } = await getMetrics(params);
    return data;
  });
};

export const useMetric = (
  metricId: string,
  params: { fairo_data: boolean; deleted?: boolean },
  version_num?: number
) => {
  const searchForVersionNum = !!version_num;
  const queryKey = searchForVersionNum
    ? [QueryKey.Metric, metricId, version_num]
    : [QueryKey.Metric, metricId];
  const queryFn = searchForVersionNum
    ? async () => {
        const { data } = await getVersionHistory({
          object_id: [metricId],
          version_num: [version_num],
        });
        return JSON.parse(data.results[0].object);
      }
    : async () => {
        if (metricId === "") return null;
        const { data } = await getMetric(metricId, params);
        return data;
      };
  return useQuery(queryKey, queryFn);
};

export const fetchMetrics = (params: MetricFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.Metric, params], async () => {
    const { data } = await getMetrics(params);
    return data;
  });
};

export const searchMetrics = async (searchText: string) => {
  const metrics = await fetchMetrics({ search: searchText });
  return metrics.results;
};

export const fetchMetric = async (metricId: string, params: { fairo_data: boolean }) => {
  return queryClient.fetchQuery([QueryKey.Metric, metricId], async () => {
    const { data } = await getMetric(metricId, params);
    return data;
  });
};
