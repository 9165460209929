import { ObjectTag, ObjectTagFetchOptions, Tag, TagFetchOptions } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const tagEndpoints = {
  tags: () => "/tags",
  tag: (tagId: string) => `/tags/${tagId}`,
  object_tags: () => "/object_tags",
  object_tag: (objectTagId: string) => `/object_tags/${objectTagId}`,
};

export const getObjectTags = (params: ObjectTagFetchOptions) => {
  return httpGetAuthenticated<ListResponse<ObjectTag>>(tagEndpoints.object_tags(), {
    params,
  });
};

export const getObjectTag = (objectTagId: string) => {
  return httpGetAuthenticated<ObjectTag>(tagEndpoints.object_tag(objectTagId));
};

export const addObjectTag = async (payload: { parent_object_id: string; tag: string }) => {
  const { data } = await httpPostAuthenticated<Tag>(tagEndpoints.object_tags(), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.ObjectTag, payload.parent_object_id] });
  return data;
};

export const deleteObjectTag = async (objectTagId: string) => {
  const resp = await httpDeleteAuthenticated(tagEndpoints.object_tag(objectTagId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.ObjectTag] });
  return resp;
};

export const getTags = (params: TagFetchOptions) => {
  return httpGetAuthenticated<ListResponse<Tag>>(tagEndpoints.tags(), {
    params,
  });
};

export const getTag = (tagId: string) => {
  return httpGetAuthenticated<Tag>(tagEndpoints.tag(tagId), {
    params: { fairo_data: true },
  });
};

export const addTag = async (payload: { name: string; color: string }) => {
  const { data } = await httpPostAuthenticated<Tag>(tagEndpoints.tags(), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Tag] });
  return data;
};

export const editTag = async (
  tagId: string,
  payload: {
    name: string;
    color: string;
  }
) => {
  const { data } = await httpPatchAuthenticated(tagEndpoints.tag(tagId), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Tag] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.ObjectTag] });
  return data;
};

export const deleteTag = async (tagId: string) => {
  const resp = await httpDeleteAuthenticated(tagEndpoints.tag(tagId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Tag, tagId] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.Tag] });
  return resp;
};
