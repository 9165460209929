import { PartialUser } from "./types";

interface AssetType {
  id: string;
  name: string;
  description: string;
  created_by: PartialUser;
}

interface ICreateAssetTypeRequestBody {
  name: string;
  description: string;
}

interface IUpdateAssetTypeRequestBody {
  name: string;
  description: string;
}

const parseAssetTypeResult = (raw: any): AssetType => {
  return {
    id: raw.id,
    description: raw.description,
    name: raw.name,
    created_by: raw.created_by,
  };
};

export { parseAssetTypeResult };
export type { AssetType, ICreateAssetTypeRequestBody, IUpdateAssetTypeRequestBody };
