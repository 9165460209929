import { LoadingButton } from "@mui/lab";
import { Box, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import UserTag from "../../components/UserTag";
import { useExecutionTasks } from "../../hooks/useExecutionTasks";
import { Question } from "../../models/types";
import { QuestionAssignUserModal } from "../../organisms/modals/questionnaire/QuestionAssignUserModal";
import { RectangularLoader } from "../skeleton-loader/RectangularLoader";

type QuestionAssignedUserProps = {
  question: Question;
  questionnaireId: string;
  impactAssessmentId: string;
  handleNext: () => void;
  loading: boolean;
  isSubmitStep: boolean;
  disabled: boolean;
  preview?: boolean;
  showAssignUser?: boolean;
};

export const QuestionAssignedUser = ({
  question,
  questionnaireId,
  impactAssessmentId,
  disabled,
  handleNext,
  loading,
  isSubmitStep,
  preview = false,
  showAssignUser = true,
}: QuestionAssignedUserProps) => {
  const { data: pendingTasks, isLoading: isLoadingTasks } = useExecutionTasks({
    "parent_object_id[]": [questionnaireId],
    status: "PENDING",
    task_type: ["Answer Question"],
    "metadata_question_ref[]": [question.ref],
    page: 1,
    page_size: 1,
  });
  const [openAssignUserModal, setOpenAssignUserModal] = useState<boolean>(false);
  const hasAssignedUser = pendingTasks?.results && pendingTasks?.results.length > 0;
  const theme = useTheme();

  const getNextButtonLabel = () => {
    if ((disabled || preview) && isSubmitStep) {
      return "Close";
    }
    if (disabled) {
      return "Next";
    }
    if (isSubmitStep) {
      return "Submit";
    }
    return question.button_label ?? "Next";
  };

  return isLoadingTasks && !preview ? (
    <RectangularLoader width="100%" height="36px" />
  ) : (
    <Box display="flex" gap="10px" justifyContent="end" width="100%">
      {!preview && showAssignUser && (
        <>
          {hasAssignedUser && (
            <Box display="flex" gap="5px" alignItems="center">
              <Typography variant="h4" color={theme.palette.custom.secondaryTypography}>
                Assigned to:
              </Typography>
              <UserTag
                name={pendingTasks?.results[0]?.assignee_name}
                onClick={() => !disabled && setOpenAssignUserModal(true)}
              />
            </Box>
          )}
          {!hasAssignedUser && (
            <LoadingButton disabled={disabled} onClick={() => setOpenAssignUserModal(true)}>
              Assign User
            </LoadingButton>
          )}
        </>
      )}
      <LoadingButton variant="contained" onClick={() => handleNext()} loading={loading}>
        {getNextButtonLabel()}
      </LoadingButton>
      <QuestionAssignUserModal
        open={openAssignUserModal}
        onClose={() => setOpenAssignUserModal(false)}
        taskType="Answer Question"
        questionRef={question.ref}
        questionnaireId={questionnaireId}
        impactAssessmentId={impactAssessmentId}
        setAssignedUser={(userId: string) => {}}
        userId={hasAssignedUser ? pendingTasks?.results[0]?.assignee : null}
      />
    </Box>
  );
};
