const StripeStatusCodes = {
  active: "active",
  past_due: "past_due",
  unpaid: "unpaid",
  canceled: "canceled",
  incomplete: "incomplete",
  incomplete_expired: "incomplete_expired",
  trialing: "trialing",
  paused: "paused",
  all: "all",
  ended: "ended",
};

export default StripeStatusCodes;
