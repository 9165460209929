import { Box, IconButton, Theme, Typography } from "@mui/material";
import {
  BaseFilterOptions,
  ElementMapping,
  ElementMappingFilters,
  FilterOption,
  SupportedElementMappingModels,
} from "../../../models/types";
import { IColumnVisibility } from "../../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../../molecules/TableTextWithEllipsis";
import { defaultDateDisplay, isCreatedByFairo } from "../../../utilities/UIHelper";
import UserTag from "../../../components/UserTag";
import { NavbarIcon } from "../../../atoms/navbar/Icon";
import { ReactComponent as DeleteIcon } from "../../../assets/general/delete.svg";

export const mappedElementsFilterOptions: FilterOption<ElementMappingFilters>[] = [
  { label: "From Object", value: "from_object" },
  { label: "To Object", value: "to_object" },
  { label: "Relationship Name", value: "relationship_name" },
  { label: "Mapping Type", value: "mapping_type" },
  { label: "To Object Type", value: "to_object_type" },
  { label: "From Object Type", value: "from_object_type" },
  ...BaseFilterOptions,
];

type MappedElementsColumnsVisibilityProps = {
  theme: Theme;
  id: string;
  isFairoAdmin: boolean;
  editing: boolean;
  onEdit: (element: ElementMapping) => void;
  onDelete: (element: ElementMapping) => void;
};

export const MappedElementsColumnsVisibility = ({
  theme,
  id,
  isFairoAdmin,
  editing,
  onEdit,
  onDelete,
}: MappedElementsColumnsVisibilityProps): IColumnVisibility<ElementMapping>[] => [
  {
    field: "from_object",
    headerName: "Direction",
    visible: true,
    renderer: (element) => {
      let direction = "";
      if (id === element.from_object) {
        direction = "To";
      } else {
        direction = "From";
      }
      return (
        <Typography variant="body2" color={theme.palette.custom.secondaryTypography}>
          {direction}
        </Typography>
      );
    },
  },
  {
    field: "to_object_name",
    headerName: "Name",
    visible: true,
    renderer: (element) => {
      let objectType: SupportedElementMappingModels | null = null;
      let objectName = "";
      let objectId = "";
      if (id === element.from_object) {
        objectType = element.to_object_type;
        objectName = element.to_object_name ?? "";
        objectId = element.to_object;
      } else {
        objectType = element.from_object_type;
        objectName = element.from_object_name ?? "";
        objectId = element.from_object;
      }
      return <TableTextWithEllipsis value={objectName} fontWeight={600} />;
    },
  },
  {
    field: "to_object_type",
    headerName: "Type",
    visible: true,
    renderer: (element) => {
      let objectType = "";
      if (id === element.from_object) {
        objectType = element.to_object_type;
      } else {
        objectType = element.from_object_type;
      }
      return (
        <Typography variant="body2" color={theme.palette.custom.secondaryTypography}>
          {objectType.split(".")[1] ?? objectType}
        </Typography>
      );
    },
  },
  {
    field: "updated_on",
    headerName: "Mapped On",
    visible: true,
    renderer: (element) => (
      <Typography variant="body2" color={theme.palette.custom.secondaryTypography}>
        {defaultDateDisplay(element.updated_on)}
      </Typography>
    ),
  },
  {
    field: "updated_by",
    headerName: "Mapped By",
    visible: true,
    renderer: (element) => (
      <UserTag
        showNameInitials
        createdByFairo={isCreatedByFairo(element.updated_by)}
        name={element.updated_by.name}
      />
    ),
  },
  ...(!editing
    ? []
    : ([
        {
          field: "created_by",
          headerName: "",
          visible: true,
          renderer: (element: ElementMapping) =>
            isFairoAdmin && (
              <Box
                display="flex"
                width="100%"
                justifyContent="flex-end"
                sx={{ paddingRight: "20px" }}
              >
                <IconButton onClick={() => onEdit(element)}>
                  <NavbarIcon
                    variant="edit-icon"
                    sx={{
                      height: "17px",
                      width: "16px",
                    }}
                    color={
                      !editing
                        ? theme.palette.custom.grayTypography
                        : theme.palette.custom.blueTypography
                    }
                  />
                </IconButton>
                <IconButton onClick={() => onDelete(element)}>
                  <DeleteIcon
                    width="16px"
                    height="17px"
                    color={
                      !editing
                        ? theme.palette.custom.grayTypography
                        : theme.palette.custom.redTypography
                    }
                  />
                </IconButton>
              </Box>
            ),
        },
      ] as const)),
];
