import { useQueries, useQuery } from "react-query";
import { TestCase, TestResultFetchOptions } from "../models/types";
import { getTestResult, getTestResults } from "../services/DataService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useTestResults = (params: TestResultFetchOptions) => {
  return useQuery([QueryKey.TestResult, params], async () => {
    const { data } = await getTestResults(params);
    return data;
  });
};

export const useTestCaseResults = (testCases: TestCase[], params: TestResultFetchOptions) => {
  return useQueries(
    testCases.map((testCase) => ({
      queryKey: [QueryKey.TestResult, { testCaseId: testCase.id, ...params }],
      queryFn: async () => {
        const { data } = await getTestResults({ test_case: testCase.id, ...params });
        if (data.results && data.results.length > 0) {
          return data.results[0];
        }
        return null;
      },
    }))
  );
};

export const useTestResult = (testResultId: string) => {
  return useQuery([QueryKey.TestResult, testResultId], async () => {
    const { data } = await getTestResult(testResultId);
    return data;
  });
};

export const fetchTestResult = (testResultId: string) => {
  return queryClient.fetchQuery([QueryKey.TestResult, testResultId], async () => {
    const { data } = await getTestResult(testResultId);
    return data;
  });
};

export const fetchTestResults = (params: TestResultFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.TestResult, params], async () => {
    const { data } = await getTestResults(params);
    return data;
  });
};

export const searchTestResults = async (searchText: string) => {
  const testResults = await fetchTestResults({
    search: searchText,
  });
  return testResults.results;
};

export const searchTestResultsByTestRun = async (testRunId: string, searchText: string) => {
  const testResults = await fetchTestResults({
    test_run: testRunId,
    search: searchText,
  });
  return testResults.results;
};
