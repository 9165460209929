import { Asset, AssetFetchOptions, ObjectSummary } from "../models/types";
import { httpGetAuthenticated, httpPostAuthenticated } from "./ApiService";

const assetEndpoints = {
  assets: () => "/assets",
  asset: (assetId: string) => `/assets/${assetId}`,
  summary: () => "/assets/summary",
};

export const getAsset = (assetId: string) => {
  return httpGetAuthenticated<Asset>(assetEndpoints.asset(assetId));
};

export const getAssetsSummary = (params: AssetFetchOptions) => {
  return httpGetAuthenticated<ObjectSummary>(assetEndpoints.summary(), {
    params,
  });
};

export const createModelDatabricksMonitor = (
  assetId: string,
  payload: {
    output_schema_name: string;
    assets_dir: string;
    model_id_col: string;
    problem_type: "PROBLEM_TYPE_CLASSIFICATION" | "PROBLEM_TYPE_REGRESSION";
    prediction_col: string;
    timestamp_col: string;
    slicing_exprs?: string[];
    granularities: string[];
    baseline_table_name?: string;
  }
) => {
  return httpPostAuthenticated(`${assetEndpoints.asset(assetId)}/create_monitor`, payload);
};
