import { Box, Typography } from "@mui/material";
import { useReferences } from "../../hooks/useReferences";
import { BadgeOption } from "../BadgeOption";
import { RectangularLoader } from "../skeleton-loader/RectangularLoader";

type ReferenceListReadOnlyCustomFieldProps = {
  value: string[];
};

export const ReferenceListReadOnlyCustomField = ({
  value,
}: ReferenceListReadOnlyCustomFieldProps) => {
  const { data: references, isLoading } = useReferences({ id: value });

  return isLoading ? (
    <RectangularLoader height="20px" width="100%" />
  ) : references?.count && value.length > 0 ? (
    <Box display="flex" gap="5px" flexWrap="wrap">
      {references.results.map((reference, index) => {
        return (
          <BadgeOption
            key={index}
            name={reference?.title ?? ""}
            onClick={() => {}}
            maxWidth="325px"
          />
        );
      })}
    </Box>
  ) : (
    <Typography variant="body2">No references</Typography>
  );
};
