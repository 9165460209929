import { Box, MenuItem, Select, useTheme } from "@mui/material";
import { DashboardOptionsType, useOrganizationContext } from "../../context/OrganizationContext";
import { useSubscription } from "../../context/SubscriptionContext";

export const HomeDashboardSelector = ({ padding }: { padding?: string }) => {
  const { currentDashboard, setCurrentDashboard } = useOrganizationContext();
  const { isDemoPlan } = useSubscription();
  const theme = useTheme();
  return (
    <Box padding={padding} display="flex" width="100%" justifyContent="flex-end">
      <Select
        fullWidth={false}
        sx={{
          height: "30px",
          "&: hover": {
            backgroundColor: theme.palette.custom.hoverButton,
          },
          "&.MuiInputBase-root": {
            "& fieldset": {
              border: "0 !important",
            },
          },
        }}
        value={currentDashboard}
        onChange={(e) => setCurrentDashboard(e.target.value as DashboardOptionsType)}
      >
        <MenuItem value="demo">Demo Dashboard</MenuItem>
        <MenuItem value="home">Overview Dashboard</MenuItem>
        <MenuItem value="executive">Executive Dashboard</MenuItem>
      </Select>
    </Box>
  );
};
