import config from "../config/config";
import {
  IntercomEvent,
  IntercomEventFetchOptions,
  Organization,
  QuickLinkEventType,
  QuickLinkPage,
  User,
} from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";
import { getUserHash } from "./AuthService";

const endpoints = {
  events: () => "/quick_links",
  event: (id: string) => `/quick_links/${id}`,
  complete: (id: string) => `/quick_links/${id}/complete`,
  unmark_complete: (id: string) => `/quick_links/${id}/unmark_complete`,
};

export const getIntercomEvents = (params: IntercomEventFetchOptions) => {
  return httpGetAuthenticated<ListResponse<IntercomEvent>>(endpoints.events(), {
    params,
  });
};

export const getQuickLink = (id: string) => {
  return httpGetAuthenticated<IntercomEvent>(`${endpoints.events()}/${id}`);
};

export const createQuickLink = async (payload: {
  name: string;
  related_object_type: QuickLinkPage;
  event_type: QuickLinkEventType | null;
  value: string | null;
}) => {
  const { data } = await httpPostAuthenticated(endpoints.events(), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.IntercomEvent] });
  return data;
};

export const editQuickLink = async (
  id: string,
  payload: {
    name: string;
    related_object_type: QuickLinkPage;
    event_type: QuickLinkEventType | null;
    value: string | null;
  }
) => {
  const { data } = await httpPatchAuthenticated(endpoints.event(id), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.IntercomEvent] });
  return data;
};

export const deleteQuickLink = async (quickLinkId: string) => {
  const resp = await httpDeleteAuthenticated(endpoints.event(quickLinkId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.IntercomEvent] });
  return resp;
};

export const setQuickLinkCompleted = async (quickLinkId: string) => {
  const resp = await httpGetAuthenticated(endpoints.complete(quickLinkId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.IntercomEvent] });
  return resp;
};

export const setQuickLinkIncomplete = async (quickLinkId: string) => {
  const resp = await httpGetAuthenticated(endpoints.unmark_complete(quickLinkId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.IntercomEvent] });
  return resp;
};

export const updateIntercomLoggedOut = () => {
  window.Intercom("boot", {
    api_base: config.intercom.baseUrl,
    app_id: config.intercom.appId,
  });
  window.Intercom("update");
};

export const updateIntercomLoggedIn = (user: User, organization: Organization) => {
  window.Intercom("boot", {
    api_base: config.intercom.baseUrl,
    app_id: config.intercom.appId,
    name: user.name,
    email: user.email,
    user_hash: getUserHash(),
    user_id: user.id,
    company: {
      id: organization.id,
      name: organization.name,
    },
  });
  window.Intercom("update");
};

export const launchChecklist = (checklistNum: number) => {
  window.Intercom("startChecklist", checklistNum);
};

export const shutdownIntercom = () => {
  window.Intercom("shutdown");
};

export const getHelp = (requestId: string) => {
  window.Intercom(
    `Hello, I need assistance with audit log request ID- ${requestId}. Could you please provide me with more details or guidance on this?`
  );
};

export const openGuide = (guideChecklistNum: number) => {
  window.Intercom("startChecklist", guideChecklistNum);
};

export const moreInfo = () => {
  window.Intercom("");
};

export const sendIntercomEvent = (eventName: string) => {
  window.Intercom("trackEvent", eventName);
};

export const launchGenericIntercomEvent = (eventName: string, value: string) => {
  window.Intercom(eventName, value);
};

export const openIntercom = () => {
  window.Intercom("show");
};
