import { useQuery } from "react-query";
import queryClient, { QueryKey } from "../state/QueryStore";
import { getReference, getReferences } from "../services/ReferenceService";
import { ReferenceFetchOptions } from "../models/types";

export const useReferences = (params: ReferenceFetchOptions) => {
  return useQuery([QueryKey.Reference, params], async () => {
    const { data } = await getReferences({ ...params, fairo_data: true });
    return data;
  });
};

export const useReference = (parameterId: string) => {
  return useQuery([QueryKey.Reference, parameterId], async () => {
    const { data } = await getReference(parameterId);
    return data;
  });
};

export const fetchReferences = (params: ReferenceFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.Reference, params], async () => {
    const { data } = await getReferences({ ...params, fairo_data: true });
    return data;
  });
};

export const searchReferences = async (searchText: string) => {
  const parameters = await fetchReferences({ search: searchText });
  return parameters.results;
};
