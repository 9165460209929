import { TestCase } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { httpGetAuthenticated, httpPatchAuthenticated } from "./ApiService";

const testCaseEndpoints = {
  testCase: (testId: string) => `/test_cases/${testId}`,
};

export const getTestCase = (testId: string) => {
  return httpGetAuthenticated<TestCase>(testCaseEndpoints.testCase(testId));
};

export const saveAdvanceSettings = async (
  testId: string,
  params: {
    required_parameters: string[];
    optional_parameters: string[];
    value_type: string | null;
    operator: string | null;
    test_value: string;
    associated_metric: string;
  }
) => {
  const { data } = await httpPatchAuthenticated(testCaseEndpoints.testCase(testId), params);
  queryClient.invalidateQueries({ queryKey: [QueryKey.TestCase] });

  return data;
};
