import { DefaultBadge, StatusBadge } from "../../atoms/StatusBadge";
import UserTag from "../../components/UserTag";
import {
  FilterOption,
  BaseFilters,
  BaseFilterOptions,
  ComplianceElement,
} from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { getFrameworkIcon } from "../FrameworkIconHelper";

export const FILTER_OPTIONS: FilterOption<BaseFilters>[] = [
  { label: "Element", value: "element_num" },
  { label: "External ID", value: "external_id" },
  { label: "Name", value: "name" },
  ...BaseFilterOptions,
];
export const columnsVisibility: IColumnVisibility<ComplianceElement>[] = [
  {
    field: "external_id",
    headerName: "ID",
    visible: true,
    renderer: (element) => <TableTextWithEllipsis fontWeight={600} value={element.external_id} />,
    columnMinWidth: 180,
    columnMaxWidth: 180,
  },
  {
    field: "name",
    headerName: "Name",
    visible: true,
    renderer: (element) => (
      <TableTextWithEllipsis expanded={true} fullWidth={true} value={element.name} />
    ),
    columnMinWidth: 400,
  },
  {
    field: "element_type",
    headerName: "Type",
    visible: true,
    columnMinWidth: 200,
    columnMaxWidth: 200,
    renderer: (element) => <DefaultBadge value={element.element_type} />,
  },
  {
    field: "framework",
    headerName: "Frameworks",
    visible: true,
    columnMinWidth: 180,
    columnMaxWidth: 180,
    renderer: (element) =>
      element.framework ? (
        <DefaultBadge
          renderIcon={() => getFrameworkIcon(element?.framework_name?.name, "small")}
          value={element?.framework_name?.name}
        />
      ) : (
        ""
      ),
  },
  {
    field: "status",
    headerName: "Status",
    visible: true,
    columnMaxWidth: 120,
    columnMinWidth: 120,
    renderer: (element) => <StatusBadge status={element.status as any} />,
  },
  {
    field: "assignee",
    headerName: "Owner",
    visible: true,
    columnMaxWidth: 250,
    columnMinWidth: 200,
    renderer: (element) => (element.assignee_name ? <UserTag name={element?.assignee_name} /> : ""),
  },
];
