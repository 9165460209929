import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useOrganizationGet } from "../state/QueryStore";
import { useSubscription } from "../context/SubscriptionContext";

export const Checkout = () => {
  const { status } = useParams();
  const navigate = useNavigate();

  //TODO: Update Organization Object

  React.useEffect(() => {
    if (status === "success") {
      navigate("/organization");
    }
    if (status === "canceled") {
      navigate("/");
    }
  }, [status, navigate]);

  return <></>;
};
