import { useQuery } from "react-query";
import { NotificationFetchOptions } from "../models/types";
import { getNotification, getNotifications } from "../services/NotificationService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useNotifications = (params: NotificationFetchOptions) => {
  return useQuery([QueryKey.Notification, params], async () => {
    const { data } = await getNotifications(params);
    return data;
  });
};

export const useNotification = (notificationId: string) => {
  return useQuery([QueryKey.Notification], async () => {
    const { data } = await getNotification(notificationId);
    return data;
  });
};

export const fetchNotifications = (params: NotificationFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.Notification, params], async () => {
    const { data } = await getNotifications(params);
    return data;
  });
};

export const searchNotification = async (searchText: string) => {
  const tests = await fetchNotifications({ search: searchText });
  return tests.results;
};
