import { ComplianceFramework, ComplianceFrameworkFetchOptions } from "../models/types";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { httpGetAuthenticated } from "./ApiService";

const frameworkEndpoints = {
  frameworks: () => "/compliance_frameworks",
  framework: (frameworkId: string) => `/compliance_frameworks/${frameworkId}`,
};

export const getFrameworks = (params: ComplianceFrameworkFetchOptions) => {
  return httpGetAuthenticated<ListResponse<ComplianceFramework>>(frameworkEndpoints.frameworks(), {
    params,
  });
};

export const getFramework = (frameworkId: string, params?: { fairo_data?: boolean }) => {
  return httpGetAuthenticated<ComplianceFramework>(frameworkEndpoints.framework(frameworkId), {
    params,
  });
};
