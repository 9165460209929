import { Box } from "@mui/material";
import OrganizationContainer from "../../components/OrganizationContainer";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

export const OrganizationSettingsBillingLoader = () => {
  return (
    <OrganizationContainer
      content={
        <Box
          sx={{
            paddingTop: "50px",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <RectangularLoader height="35px" width="550px" />
          <RectangularLoader height="43px" width="550px" />
          <RectangularLoader height="43px" width="550px" />
          <RectangularLoader height="43px" width="550px" />
          <RectangularLoader height="43px" width="550px" />
          <RectangularLoader height="43px" width="550px" />
          <Box marginY="20px"></Box>
          <RectangularLoader height="25px" width="550px" />
          <RectangularLoader height="43px" width="550px" />
          <RectangularLoader height="35px" width="550px" />
          <RectangularLoader height="27px" width="550px" />
        </Box>
      }
    />
  );
};
