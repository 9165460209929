import { QueryKey, useMutation, useQuery } from "react-query";
import { ICreateObjectFileAttachmentRequestBody } from "../models/IFileAttachment";
import {
  ICreateObjectCustomFieldRequestBody,
  IUpdateObjectCustomFieldRequestBody,
} from "../models/IObjectCustomField";
import { CreateOrUpdateResult } from "../utilities/ApiResponseHelper";
import {
  ObjectCustomFieldMethods,
  ObjectFileAttachmentMethods,
  ObjectMethods,
  ObjectRelationshipsMethods,
} from "../utilities/DataServiceHelper";
import queryClient from "./QueryStore";
import { getVersionHistory } from "../services/VersionHistoryService";

interface IGetListQueryParams {
  page: number;
  page_size: number;
  filters?: string;
}

const objectHooks = <
  TObjectListViewModel,
  TObjectDetailViewModel,
  TCreateObjectRequestBody,
  TUpdateObjectRequestBody,
  TGetListQueryParams extends IGetListQueryParams
>(config: {
  methods: ObjectMethods;
  baseQueryKey: string;
  listViewModelConverter: (data: any) => TObjectListViewModel;
  detailViewModelConverter: (data: any) => TObjectDetailViewModel;
}) => {
  const { methods, baseQueryKey, listViewModelConverter, detailViewModelConverter } = config;
  return {
    useObjectsGet: (queryParams: TGetListQueryParams) =>
      useQuery(
        [baseQueryKey, queryParams.page, queryParams.page_size, queryParams.filters],
        () =>
          methods.objectListGet({
            queryParams,
            itemConverter: (raw) => listViewModelConverter(raw),
          }),
        {}
      ),

    useObjectGet: (objectId: string, version_num?: number) => {
      useQuery<unknown, unknown, TObjectDetailViewModel>([baseQueryKey, objectId], () =>
        methods.objectGet(objectId, (raw) => detailViewModelConverter(raw))
      );
      const queryKey = version_num
        ? [baseQueryKey, objectId, version_num]
        : [baseQueryKey, objectId];
      const queryFn = version_num
        ? async () => {
            const { data } = await getVersionHistory({
              object_id: [objectId],
              version_num: [version_num],
            });
            return detailViewModelConverter(JSON.parse(data.results[0].object));
          }
        : async () => {
            const { data } = await methods.objectGet(objectId);
            return detailViewModelConverter(data);
          };
      return useQuery(queryKey, queryFn);
    },

    useObjectCreate: () =>
      useMutation<CreateOrUpdateResult<any>, unknown, TCreateObjectRequestBody>(
        (object) => methods.objectCreate(object),
        {
          onSuccess: () => {
            queryClient.refetchQueries([baseQueryKey]);
          },
        }
      ),
    useObjectUpdate: (objectId: string) =>
      useMutation<CreateOrUpdateResult<any>, unknown, TUpdateObjectRequestBody>(
        (params) => methods.objectUpdate(objectId, params),
        {
          onSuccess: () => {
            queryClient.invalidateQueries([baseQueryKey]);
          },
        }
      ),
    useObjectDelete: () =>
      useMutation<any, unknown, string>((objectId) => methods.objectDelete(objectId), {
        onSuccess: () => {
          queryClient.invalidateQueries([baseQueryKey]);
        },
      }),
  };
};

type ObjectHooks = ReturnType<typeof objectHooks>;

/** Helper to generate custom field method hooks for any object */
const objectCustomFieldHooks = (config: {
  methods: ObjectCustomFieldMethods;
  onSuccessInvalidateQueries: QueryKey;
}) => {
  const { methods, onSuccessInvalidateQueries } = config;

  return {
    useObjectCustomFieldValueCreate: (objectId: string) =>
      useMutation<
        CreateOrUpdateResult<ICreateObjectCustomFieldRequestBody>,
        unknown,
        ICreateObjectCustomFieldRequestBody
      >((params) => methods.objectCreateCustomFieldValue(objectId, params), {
        onSuccess: () => {
          queryClient.invalidateQueries(onSuccessInvalidateQueries);
        },
      }),
    useObjectCustomFieldValueUpdate: (customFieldValueId: string) =>
      useMutation<
        CreateOrUpdateResult<IUpdateObjectCustomFieldRequestBody>,
        unknown,
        IUpdateObjectCustomFieldRequestBody
      >((params) => methods.objectUpdateCustomFieldValue(customFieldValueId, params), {
        onSuccess: () => {
          queryClient.invalidateQueries(onSuccessInvalidateQueries);
        },
      }),
    useObjectCustomFieldValueDelete: () =>
      useMutation<any, unknown, string>(
        (customFieldValueId) => methods.objectDeleteCustomFieldValue(customFieldValueId),
        {
          onSuccess: () => {
            queryClient.invalidateQueries(onSuccessInvalidateQueries);
          },
        }
      ),
  };
};

type ObjectCustomFieldHooks = ReturnType<typeof objectCustomFieldHooks>;

/** Helper to generate file attachment hooks for any object */
const objectFileAttachmentHooks = (config: {
  methods: ObjectFileAttachmentMethods;
  onSuccessInvalidateQueries: QueryKey;
}) => {
  const { methods, onSuccessInvalidateQueries } = config;
  return {
    useObjectFileAttachmentCreate: (objectId: string) =>
      useMutation<any, unknown, ICreateObjectFileAttachmentRequestBody>(
        (params) => methods.attachFileToObject(objectId, params),
        {
          onSuccess: () => {
            queryClient.invalidateQueries(onSuccessInvalidateQueries);
          },
        }
      ),
    /**pass actual file id */
    useObjectFileAttachmentDelete: () =>
      useMutation<any, unknown, string>((fileId) => methods.removeFileFromObject(fileId), {
        onSuccess: () => {
          queryClient.invalidateQueries(onSuccessInvalidateQueries);
        },
      }),
  };
};

type ObjectFileAttachmentHooks = ReturnType<typeof objectFileAttachmentHooks>;

const objectRelationshipsHooks = (config: {
  objectId: string;
  methods: ObjectRelationshipsMethods;
  onSuccessInvalidateQueries: QueryKey;
}) => {
  const { objectId, methods, onSuccessInvalidateQueries } = config;
  return {
    useAddAction: () =>
      useMutation<any, unknown, string>((actionId) => methods.addAction(objectId, actionId), {
        onSuccess: () => {
          queryClient.invalidateQueries(onSuccessInvalidateQueries);
        },
      }),
    useRemoveAction: () =>
      useMutation<any, unknown, string>((actionId) => methods.removeAction(objectId, actionId), {
        onSuccess: () => {
          queryClient.invalidateQueries(onSuccessInvalidateQueries);
        },
      }),
    useAddTestCase: () =>
      useMutation<any, unknown, string>((testId) => methods.addTestCase(objectId, testId), {
        onSuccess: () => {
          queryClient.invalidateQueries(onSuccessInvalidateQueries);
        },
      }),
    useRemoveTestCase: () =>
      useMutation<any, unknown, string>((testId) => methods.removeTestCase(objectId, testId), {
        onSuccess: () => {
          queryClient.invalidateQueries(onSuccessInvalidateQueries);
        },
      }),

    useAddAsset: () =>
      useMutation<any, unknown, string>((assetId) => methods.addAsset(objectId, assetId), {
        onSuccess: () => {
          queryClient.invalidateQueries(onSuccessInvalidateQueries);
        },
      }),
    useRemoveAsset: () =>
      useMutation<any, unknown, string>((assetId) => methods.removeAsset(objectId, assetId), {
        onSuccess: () => {
          queryClient.invalidateQueries(onSuccessInvalidateQueries);
        },
      }),
  };
};
type ObjectRelationshipsHooks = ReturnType<typeof objectRelationshipsHooks>;

export { objectHooks, objectCustomFieldHooks, objectFileAttachmentHooks, objectRelationshipsHooks };
export type {
  ObjectHooks,
  ObjectCustomFieldHooks,
  ObjectFileAttachmentHooks,
  ObjectRelationshipsHooks,
  IGetListQueryParams,
};
