import { NavigateFunction } from "react-router-dom";
import { DefaultBadge, StatusBadge } from "../../../atoms/StatusBadge";
import { BaseFilterOptions, BaseFilters, FilterOption, RiskScenario } from "../../../models/types";
import { IColumnVisibility } from "../../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../../molecules/TableTextWithEllipsis";

export const riskScenarioListFilters: FilterOption<BaseFilters>[] = [
  { label: "Name", value: "name" },
  ...BaseFilterOptions,
];
export const riskScenarioListColumnsVisibility = ({
  navigate,
}: {
  navigate: NavigateFunction;
}): IColumnVisibility<RiskScenario>[] => [
  {
    field: "name",
    headerName: "Scenario",
    visible: true,
    renderer: (risk) => (
      <TableTextWithEllipsis expanded={true} maxChars={240} value={risk.name} fullWidth={false} />
    ),
    columnMinWidth: 500,
  },
  {
    field: "category",
    headerName: "Category",
    visible: true,
    columnMaxWidth: 300,
    columnMinWidth: 300,
    renderer: (risk) => <DefaultBadge value={risk.category_name.name} />,
  },
  {
    field: "id",
    headerName: "Associated Use Cases",
    visible: true,
    renderer: (risk) => risk.associated_use_cases,
    columnMaxWidth: 170,
    columnMinWidth: 170,
  },
  {
    field: "status",
    headerName: "Status",
    visible: true,
    columnMaxWidth: 190,
    columnMinWidth: 190,
    renderer: (risk) => <StatusBadge status={risk.status} />,
  },
];
