import { IconButton, SxProps, Theme, useTheme } from "@mui/material";
import { useState } from "react";
import AppStrings from "../strings";

import { DeleteIconFilled } from "./AppIcons";
import { ReactComponent as DeleteIcon } from "../assets/general/delete.svg";
import { ConfirmationModal } from "./ConfirmationModal"; // Import the ConfirmationModal component
import { NavbarIcon } from "../atoms/navbar/Icon";
import { ButtonIcon } from "../molecules/ButtonIcon";

type Props = {
  deleteConfirmationDialogTitle: string;
  deleteConfirmationDialogDescription?: string;
  filledButton?: boolean;
  onDeleteConfirm: () => void;
  sx?: SxProps<Theme>;
  size?: string;
  disabled?: boolean;
  deleteConfirmationButtonDescription?: string;
};

/** TrashCan icon button. On click shows a confirmation dialog. */
const DeleteIconButton = ({
  deleteConfirmationDialogTitle,
  deleteConfirmationDialogDescription,
  deleteConfirmationButtonDescription = "Delete",
  filledButton,
  onDeleteConfirm,
  size = "20px",
  sx,
  disabled,
}: Props) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const theme = useTheme();

  const openConfirmation = () => {
    setIsConfirmationOpen(true);
  };

  const closeConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const onDeleteClick = (ev: any) => {
    ev.stopPropagation();
    openConfirmation();
  };

  const onConfirmClick = () => {
    onDeleteConfirm();
    closeConfirmation();
  };

  return (
    <>
      <ButtonIcon
        iconSize={size}
        emptyBackground={true}
        iconColor={theme.palette.custom.redTypography}
        action={onDeleteClick}
        label=""
        disabled={disabled}
        variant="trash-can"
      />
      <ConfirmationModal
        open={isConfirmationOpen}
        title={deleteConfirmationDialogTitle}
        description={
          deleteConfirmationDialogDescription ?? AppStrings.deleteConfirmationDescription
        }
        onAccept={onConfirmClick}
        onClose={closeConfirmation}
        acceptText={deleteConfirmationButtonDescription}
        rejectText="Cancel"
      />
    </>
  );
};

export default DeleteIconButton;
