import { Box, useTheme } from "@mui/material";
import { useState } from "react";
import { NavbarIcon } from "../../atoms/navbar/Icon";
import DeleteIconButton from "../../components/DeleteIconButton";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { getFile } from "../../services/FileService";
import { AddHyperlinkOrFileModal } from "../modals/assets/AddHyperlinkOrFile";

type FileOrLinkCustomFieldProps = {
  value: string | null;
  onChange: (value: string | null) => void;
  edit: boolean;
  relatedObjectId: string;
};

export const FileOrLinkCustomField = (props: FileOrLinkCustomFieldProps) => {
  const { value, onChange, edit, relatedObjectId } = props;
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isEmpty = !value || value === "" || value === "{}";

  const parsedValue = value && value !== "" ? JSON.parse(value) : {};
  const handleClick = async () => {
    try {
      if (parsedValue?.type === "hyperlink" && parsedValue?.url) {
        window.open(parsedValue?.url, "_blank");
      }
      if (parsedValue?.type === "file" && parsedValue?.id) {
        const fileAttachment = await getFile({ id: parsedValue.id });
        window.open(fileAttachment.data.file_object, "_blank");
      }
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Box display="flex">
      <AddHyperlinkOrFileModal
        relatedObjectId={relatedObjectId}
        onChange={onChange}
        open={open}
        onClose={() => setOpen(false)}
      />
      {isEmpty && edit && (
        <TableTextWithEllipsis
          value="Add file or hyperlink"
          fullWidth={false}
          onClick={edit ? () => setOpen(true) : undefined}
          color={edit ? theme.palette.custom.hyperlink : theme.palette.custom.grayTypography}
          tootipPlacement="bottom-start"
        />
      )}
      {isEmpty && !edit && <TableTextWithEllipsis hideTooltip value="No file or hyperlink" />}
      {!isEmpty && (
        <Box
          display="flex"
          flexGrow={1}
          maxWidth="100%"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box display="flex" maxWidth="90%" flexDirection="row" gap="5px" alignItems="center">
            <NavbarIcon
              variant={parsedValue.type === "hyperlink" ? "hyperlink" : "documentation"}
              sx={{
                width: "18px",
                minWidth: "18px",
                minHeight: "18px",
                height: "18px",
              }}
              color={theme.palette.custom.hyperlink}
            />
            <TableTextWithEllipsis
              value={
                parsedValue?.name && parsedValue?.name !== ""
                  ? parsedValue?.name
                  : parsedValue?.url ?? ""
              }
              fullWidth={false}
              expanded
              onClick={() => handleClick()}
              color={theme.palette.custom.hyperlink}
              tootipPlacement="bottom-start"
            />
          </Box>
          {edit && (
            <DeleteIconButton
              deleteConfirmationDialogTitle="Remove file or hyperlink"
              onDeleteConfirm={() => onChange(JSON.stringify({}))}
              deleteConfirmationButtonDescription="Remove"
              deleteConfirmationDialogDescription="Are you sure you want to remove this file or hyperlink?"
            />
          )}
        </Box>
      )}
    </Box>
  );
};
