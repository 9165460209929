import { SettingsBluetoothTwoTone } from "@mui/icons-material";
import {
  createContext,
  FunctionComponent,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from "react";
import { ObjectHooks } from "../state/QueryStoreHelper";

interface INavigatorContext {
  buttonLabel: string;
  title: string;
  subtitle: string;
  emptyButtonTitle: string;
  showCreateButton: boolean;
  buttonAction: () => void;
  currentIcon: ReactElement;
  tableDescription: string;
  showTrashButton: boolean;
  trashButtonProps: ITrashButtonProps | null;
  menuMobileOpen: boolean;
  setMenuMobileOpen: (data: boolean) => void;
  setNavigationContext: (props: ISetNavigatorContext) => void;
  setTrashButtonProps: (data: ITrashButtonProps) => void;
}

interface INavigatorProvider {
  children: ReactNode;
}

interface ISetNavigatorContext {
  buttonLabel?: string;
  title?: string;
  subtitle?: string;
  emptyButtonTitle?: string;
  showCreateButton?: boolean;
  buttonAction?: () => void;
  currentIcon?: ReactElement;
  tableDescription?: string;
  showTrashButton?: boolean;
  trashButtonProps?: ITrashButtonProps;
}

interface ITrashButtonProps {
  hooks: ObjectHooks;
  objectId: string;
  objectName: string;
  onDeleted?: () => void;
}

export const NavigatorContext = createContext({} as INavigatorContext);

export const NavigatorProvider: FunctionComponent<INavigatorProvider> = ({ children }) => {
  const [buttonLabel, setButtonLabel] = useState<string>("");
  const [showCreateButton, setShowCreateButton] = useState<boolean>(false);
  const [buttonAction, setButtonAction] = useState<() => void>(() => {});
  const [showTrashButton, setShowTrashButton] = useState<boolean>(false);
  const [trashButtonProps, setTrashButtonProps] = useState<ITrashButtonProps | null>(null);
  const [currentIcon, setCurrentIcon] = useState<ReactElement>(<></>);
  const [title, setTitle] = useState<string>("");
  const [subtitle, setSubtitle] = useState<string>("");
  const [emptyButtonTitle, setEmptyButtonTitle] = useState<string>("");
  const [tableDescription, setTableDescription] = useState<string>("");
  const [menuMobileOpen, setMenuMobileOpen] = useState<boolean>(false);

  const setNavigationContext = (props: ISetNavigatorContext) => {
    setButtonLabel(props.buttonLabel ?? "");
    setTitle(props.title ?? "");
    setSubtitle(props.subtitle ?? "");
    setEmptyButtonTitle(props.emptyButtonTitle ?? "");
    setShowCreateButton(props.showCreateButton ?? false);
    setButtonAction(props.buttonAction ?? (() => {}));
    setCurrentIcon(props.currentIcon ?? <></>);
    setTableDescription(props.tableDescription ?? "");
    setShowTrashButton(props.showTrashButton ?? false);
    setTrashButtonProps(props.trashButtonProps ?? null);
  };

  return (
    <NavigatorContext.Provider
      value={{
        menuMobileOpen,
        setMenuMobileOpen,
        title,
        subtitle,
        emptyButtonTitle,
        currentIcon,
        buttonLabel,
        showCreateButton,
        buttonAction,
        tableDescription,
        showTrashButton,
        trashButtonProps,
        setNavigationContext,
        setTrashButtonProps,
      }}
    >
      {children}
    </NavigatorContext.Provider>
  );
};
