/* eslint-disable react-hooks/rules-of-hooks */
import { LoadingButton } from "@mui/lab";
import { DialogContentText, Stack, TextField, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { SmallModal } from "../../../components/SmallModal";
import { openSnackbar } from "../../../context/SnackbarContext";
import { useInputState } from "../../../hooks/useInputTextState";
import { useRatingLevelOption } from "../../../hooks/useRatingLevelOptions";
import {
  addRatingLevelOption,
  editRatingLevelOption,
} from "../../../services/RatingLevelOptionService";
import { CustomAxiosError } from "../../../utilities/ErrorResponseHelper";

type CreateRatingLevelModalProps = {
  /** Title for the modal */
  title: string;
  /** Should the modal be open? */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
  /** The id of the rating level to edit */
  ratingLevelId?: string | null;
};

export const CreateRatingLevelModal = (props: CreateRatingLevelModalProps) => {
  const { open, onClose, title, ratingLevelId } = props;
  const theme = useTheme();
  const [name, setName, nameError, setNameError] = useInputState("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const innerTextColor = theme.palette.text.secondary;
  const data = useRatingLevelOption(ratingLevelId ?? "");
  const onCreateCallback = async () => {
    if (!name.trim()) {
      setNameError(true);
      return;
    }
    if (name === "") {
      openSnackbar("Please complete the required fileds to continue", "error");
      return;
    }
    try {
      setIsLoading(true);
      if (ratingLevelId) {
        await editRatingLevelOption(ratingLevelId, { name });
      } else {
        await addRatingLevelOption({ name });
      }
      setName("");
      onClose();
    } catch (error) {
      if (error instanceof CustomAxiosError) {
        error.showAuditLogSnackbar("Failed to create object");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data?.data) {
      setName(data.data.name);
    }
  }, [data?.data]);

  useEffect(() => {
    if (!ratingLevelId) setName("");
  }, [ratingLevelId]);
  return (
    <SmallModal open={open} onClose={() => onClose()} title={title}>
      <Stack gap="10px">
        <DialogContentText variant="h2" color={innerTextColor}>
          Name
        </DialogContentText>
        <TextField
          error={nameError}
          helperText={nameError && "Please enter name"}
          value={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          size="small"
          required
          label="Name"
        />
        <LoadingButton variant="contained" fullWidth loading={isLoading} onClick={onCreateCallback}>
          {ratingLevelId ? "Save" : "Create"}
        </LoadingButton>
      </Stack>
    </SmallModal>
  );
};
