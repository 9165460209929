import { TextField, useTheme } from "@mui/material";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { addYears, format } from "date-fns";
import { NavbarIcon } from "../atoms/navbar/Icon";

interface ICustomCalendarProps<T> {
  value: T | null;
  onChange: (val: any) => void;
  renderInput?: (params: any) => JSX.Element;
  label?: string;
  disabled?: boolean;
  timePicker?: boolean;
  allowEmpty?: boolean;
  minDate?: string;
}

const CustomCalendar = ({
  value,
  onChange,
  renderInput,
  label,
  disabled = false,
  timePicker = false,
  allowEmpty,
  minDate,
}: ICustomCalendarProps<Date | string>) => {
  const theme = useTheme();
  const CalendarComponent: React.ElementType = () => {
    return (
      <NavbarIcon
        variant="calendar"
        sx={{ width: "16px", height: "16px" }}
        color={theme.palette.custom.withoutFillButton}
      />
    );
  };
  const ChevronComponent: React.ElementType = () => {
    return <NavbarIcon variant="chevron-down-sm" color={theme.palette.custom.withoutFillButton} />;
  };
  const ArrowLeft: React.ElementType = () => {
    return (
      <NavbarIcon
        variant="left-arrow"
        sx={{ width: "18px", height: "18px" }}
        color={theme.palette.custom.withoutFillButton}
      />
    );
  };
  const ArrowRight: React.ElementType = () => {
    return (
      <NavbarIcon
        variant="right-arrow"
        sx={{ width: "18px", height: "18px" }}
        color={theme.palette.custom.withoutFillButton}
      />
    );
  };

  return !timePicker ? (
    <DatePicker
      disabled={disabled}
      value={value}
      label={label}
      views={["month", "year", "day"]}
      onChange={(val) => {
        if (allowEmpty && val == null) return onChange(val);
        return val != null && !isNaN(new Date(val).getTime()) && onChange(val);
      }}
      minDate={minDate}
      orientation="landscape"
      renderInput={(params) => (renderInput ? renderInput(params) : <TextField {...params} />)}
      components={{
        OpenPickerIcon: CalendarComponent,
        SwitchViewIcon: ChevronComponent,
        LeftArrowIcon: ArrowLeft,
        RightArrowIcon: ArrowRight,
      }}
      maxDate={format(addYears(new Date(), 10), "yyyy-MM-dd")}
    />
  ) : (
    <DateTimePicker
      disabled={disabled}
      value={value}
      label={label}
      views={["month", "year", "day", "hours", "minutes"]}
      onChange={(val) => val != null && onChange(val)}
      renderInput={(params) => (renderInput ? renderInput(params) : <TextField {...params} />)}
      components={{
        OpenPickerIcon: CalendarComponent,
        SwitchViewIcon: ChevronComponent,
        LeftArrowIcon: ArrowLeft,
        RightArrowIcon: ArrowRight,
      }}
      maxDate={format(addYears(new Date(), 10), "yyyy-MM-dd")}
    />
  );
};

export default CustomCalendar;
