import { Box, useTheme } from "@mui/material";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { WorkflowTemplateLoader } from "../../organisms/skeleton-loaders/WorkflowTemplateLoader";
import { CardTable } from "../../organisms/CardTable";
import { ListViewLoader } from "./ListViewLoader";

export const WorkflowListPageLoader = () => {
  const theme = useTheme();

  return (
    <Box paddingBottom="60px">
      <Box
        sx={{
          height: "100%",
          gap: "15px",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <SecondaryBox gap="10px">
          <Box width="100%" display="flex" flexDirection="column" gap="10px">
            <RectangularLoader width="140px" height="17px" />
            <RectangularLoader width="100%" height="17px" />
            <CardTable
              renderItem={(item) => <></>}
              hasPaddingTop={false}
              hasPaddingBottom={false}
              hasBorderRadiusTop={false}
              params={{}}
              useGetData={() => ({
                data: { results: [], previous: null, next: null, count: 0 },
                isLoading: true,
              })}
              rowsPerPage={4}
              padding={"0px"}
              height="100%"
              showBorder={false}
              rowsMenu={[4, 8, 12]}
              loader={<WorkflowTemplateLoader />}
            />
          </Box>
        </SecondaryBox>
        <ListViewLoader type="workflow" tableSize="small" />
      </Box>
    </Box>
  );
};
