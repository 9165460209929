import { Box } from "@mui/material";
import { BadgeOption } from "./BadgeOption";

interface IBadgeList {
  values: string[];
  displayAll?: boolean;
  padding?: string | number;
  maxCount?: number;
  width?: string;
  pillMaxWidth?: string;
}

const BadgeList = ({
  values,
  displayAll,
  padding,
  maxCount = 2,
  width = "188px",
  pillMaxWidth = "100%",
}: IBadgeList) => {
  const moreThanTwoParams = values?.length! > 2;
  console.log();
  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box
        display="flex"
        alignItems="flex-start"
        alignContent="flex-start"
        flex="1 0 0"
        flexWrap="wrap"
        padding={padding ?? "5px"}
        gap="5px"
        width={width}
      >
        {displayAll
          ? values?.map((value) => (
              <BadgeOption key={value} maxWidth={pillMaxWidth} onClick={() => {}} name={value} />
            ))
          : values
              ?.slice(0, maxCount)
              .map((value) => (
                <BadgeOption key={value} maxWidth={pillMaxWidth} onClick={() => {}} name={value} />
              ))}
        {!displayAll && moreThanTwoParams && (
          <BadgeOption
            maxWidth={pillMaxWidth}
            onClick={() => {}}
            name={`+${values?.length! - maxCount}`}
          />
        )}
      </Box>
    </Box>
  );
};

export default BadgeList;
