import { useEffect, useState } from "react";
import { Box, Typography, Stack, CircularProgress } from "@mui/material";
import { useLocationParams } from "../hooks/useLocationParams";
import { useAuth } from "../hooks/useAuth";
import { createJiraAppOauthIntegration } from "../services/PluginService";
import { useNavigate } from "react-router-dom";

const JiraIntegrationPage = () => {
  const locationParams = useLocationParams();
  const { organization } = useAuth();
  const [loading, setLoading] = useState(true);
  const [integrationCreated, setIntegrationCreated] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  let params = Object.fromEntries(locationParams.entries());
  useEffect(() => {
    console.log(locationParams);
    const code = locationParams.get("code");
    if (code && organization?.id) {
      const setIntegration = async () => {
        try {
          let { data } = await createJiraAppOauthIntegration(
            code,
            JSON.stringify({
              ...params,
            })
          );
          let { is_success, message } = data;
          setIntegrationCreated(is_success);
          setErrorOccured(!is_success);
          setMessage(message);
          setLoading(false);

          if (is_success) {
            message += ". You will be redirected to the plugins page now.";
            setMessage(message);
            setTimeout(() => {
              navigate("/organization/integrations");
            }, 1000);
          }
        } catch (error) {
          setMessage(`Error: ${error}`);
          console.error("error setting jira integration", error);
          setErrorOccured(true);
        }
      };
      setIntegration();
    }
  }, [organization?.id]);
  return (
    <Box>
      {!loading && (
        <Stack alignItems="center" margin={3}>
          <Typography>Authorization {integrationCreated ? " Successful" : "Failed"}</Typography>
          <Typography>{message}</Typography>
        </Stack>
      )}

      {loading && (
        <>
          <Stack alignItems="center" margin={3}>
            <Typography>Page is loading</Typography>
          </Stack>
        </>
      )}
    </Box>
  );
};

export default JiraIntegrationPage;
