import { Button, Stack, Typography, useTheme } from "@mui/material";
import { SmallModal } from "../../../components/SmallModal";
import { useEffect, useState } from "react";
import CKEditorField from "../../../components/custom-fields/CKEditorField";
import { CustomInputValidation } from "../../CustomInputValidation";
import { searchAssetsWithParams, useAsset } from "../../../hooks/useAssets";
import { TableTextWithEllipsis } from "../../../molecules/TableTextWithEllipsis";
import { navigateToAsset } from "../../../components/AssetNavigator";
import { useNavigate } from "react-router-dom";
import { ShowMore } from "../../../molecules/ShowMore";

type AssetWithDescriptionCustomFieldModalProps = {
  open: boolean;
  onClose: () => void;
  onAdd: (value: string) => void;
  selectedForEdit: {
    id: string;
    name: string;
    description: string;
  } | null;
  title: string;
  assetType: string;
  readonly?: boolean;
};

export const AssetWithDescriptionCustomFieldModal = (
  props: AssetWithDescriptionCustomFieldModalProps
) => {
  const { open, onClose, onAdd, selectedForEdit, title, assetType, readonly } = props;
  const navigate = useNavigate();
  const theme = useTheme();
  const textColor = theme.palette.custom.secondaryTypography;

  const [asset, setAsset] = useState<string | null>(selectedForEdit ? selectedForEdit.id : null);
  const [description, setDescription] = useState<string>(
    selectedForEdit ? selectedForEdit.description : ""
  );

  const { data: assetData } = useAsset(asset ?? "");

  useEffect(() => {
    if (selectedForEdit) {
      setAsset(selectedForEdit.id);
      setDescription(selectedForEdit.description);
    } else {
      setAsset(null);
      setDescription("");
    }
  }, [selectedForEdit]);

  const handleClose = () => {
    setAsset(null);
    setDescription("");
    onClose();
  };

  return (
    <SmallModal
      open={open}
      onClose={handleClose}
      title={`${readonly ? "" : selectedForEdit ? "Edit" : "Add"} ${title}`}
      size="small"
    >
      <Stack gap="10px">
        <Typography variant="h3" color={textColor}>
          {title}
        </Typography>
        {readonly ? (
          <TableTextWithEllipsis
            value={assetData?.name}
            color={theme.palette.custom.hyperlink}
            onClick={() =>
              navigateToAsset({
                navigate,
                assetId: assetData?.id,
                assetNumber: assetData?.asset_num,
              })
            }
          />
        ) : (
          <CustomInputValidation
            value={asset}
            onChange={setAsset}
            label="Asset"
            required
            valueError={false}
            errorMessage="Asset is required"
            field_type="Asset"
            searchAssets={searchAssetsWithParams({ "asset_type_name[]": [assetType] })}
          />
        )}
        <Typography variant="h3" color={textColor}>
          Reason
        </Typography>
        {readonly ? (
          <ShowMore description={description} maxChar={250} richText />
        ) : (
          <CKEditorField value={description} onChange={setDescription} />
        )}
        {!readonly && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onAdd(JSON.stringify({ id: assetData?.id, name: assetData?.name, description }));
              handleClose();
            }}
            disabled={!asset}
          >
            {selectedForEdit ? "Update" : "Add"}
          </Button>
        )}
      </Stack>
    </SmallModal>
  );
};
