import { getServerBaseUrl } from "../services/ApiService";
import { FileAttachment } from "./types";

/**Parses file attachment list of object (workflow, process, etc) */
const parseObjectFileAttachments = (data: any[] | undefined): FileAttachment[] => {
  if (!data) return [];

  return data.map((e) => {
    const fileDownloadUrl =
      process.env.NODE_ENV === "development"
        ? getServerBaseUrl() + e.file_relative_url
        : e.file_object;

    return {
      id: e.id,
      file_id: e.file_id,
      filename: e.filename,
      file_relative_url: fileDownloadUrl,
      file_object: e.file_object,
    };
  });
};

export { parseObjectFileAttachments };
