import { Action } from "../models/types";
import { httpGetAuthenticated } from "./ApiService";

const actionEndpoints = {
  getActionTypes: () => `/actions/get_action_types`,
  action: (actionId: string) => `/actions/${actionId}`,
};

export const getActionTypes = () => {
  return httpGetAuthenticated(actionEndpoints.getActionTypes());
};

export const getAction = (actionId: string) => {
  return httpGetAuthenticated<Action>(actionEndpoints.action(actionId));
};
