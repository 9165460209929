import { useQuery } from "react-query";
import { ActionFetchOptions } from "../models/types";
import { getActions } from "../services/DataService";
import queryClient, { QueryKey } from "../state/QueryStore";
import { getAction } from "../services/ActionService";
import { getVersionHistory } from "../services/VersionHistoryService";

export const useActions = (params: ActionFetchOptions) => {
  return useQuery([QueryKey.Action, params], async () => {
    const { data } = await getActions(params);
    return data;
  });
};

export const fetchActions = (params: ActionFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.Action, params], async () => {
    const { data } = await getActions(params);
    return data;
  });
};

export const searchActions = async (searchText: string) => {
  const actions = await fetchActions({ search: searchText });
  return actions.results;
};

export const useAction = (actionId: string, version_num?: number) => {
  const searchForVersionNum = !!version_num;
  const queryKey = searchForVersionNum
    ? [QueryKey.Action, actionId, version_num]
    : [QueryKey.Action, actionId];
  const queryFn = searchForVersionNum
    ? async () => {
        const { data } = await getVersionHistory({
          object_id: [actionId],
          version_num: [version_num],
        });
        return JSON.parse(data.results[0].object);
      }
    : async () => {
        const { data } = await getAction(actionId);
        return data;
      };
  return useQuery(queryKey, queryFn);
};

export const fetchAction = (actionId: string) => {
  return queryClient.fetchQuery([QueryKey.Action, actionId], async () => {
    const { data } = await getAction(actionId);
    return data;
  });
};
