import { useQuery } from "react-query";
import { TagFetchOptions } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { getObjectTag, getObjectTags, getTag, getTags } from "../services/TagService";

export const useTags = (params: TagFetchOptions) => {
  return useQuery([QueryKey.Tag, params], async () => {
    const { data } = await getTags(params);
    return data;
  });
};

export const useTag = (tagId: string) => {
  return useQuery([QueryKey.Tag, tagId], async () => {
    const { data } = await getTag(tagId);
    return data;
  });
};

export const fetchTags = (params: TagFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.Tag, params], async () => {
    const { data } = await getTags(params);
    return data;
  });
};

export const searchTags = async (searchText: string) => {
  const items = await fetchTags({ search: searchText });
  return items.results;
};

export const useObjectTags = (objectId: string) => {
  return useQuery([QueryKey.ObjectTag, objectId], async () => {
    const { data } = await getObjectTags({ parent_object_id: objectId });
    return data.results;
  });
};

export const useObjectTag = (objectTagId: string) => {
  return useQuery([QueryKey.ObjectTag, objectTagId], async () => {
    const { data } = await getObjectTag(objectTagId);
    return data;
  });
};
