import {
  Box,
  TableContainer,
  useTheme,
  Table as MuiTable,
  TableHead,
  TableRow,
  TableCell,
  Divider,
  Typography,
  TableBody,
} from "@mui/material";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
import { TableLoader } from "./TableLoader";

type APITableLoaderProps = {
  columnsVisibility: IColumnVisibility<any>[];
  hideColumnVisibility?: boolean;
  tableSize?: "small" | "big";
  secondComponent?: boolean;
  tableSearch?: boolean;
  filtersVisibility?: boolean;
  dateFilters?: boolean;
};

export const APITableLoader = (props: APITableLoaderProps) => {
  const {
    tableSize = "small",
    columnsVisibility,
    hideColumnVisibility,
    secondComponent,
    tableSearch,
    filtersVisibility,
    dateFilters,
  } = props;

  const theme = useTheme();
  const isSmallScreen = ScreenSmallerThen("1100px");
  const visibleColumns = columnsVisibility.filter((col) => col.visible);
  const rows = tableSize === "small" ? 5 : 10;
  const showPadding = tableSearch || secondComponent || !hideColumnVisibility;

  return (
    <>
      <Box
        bgcolor={theme.palette.background.paper}
        display="flex"
        justifyContent="space-between"
        padding={showPadding ? "10px" : "0px"}
        paddingTop={hideColumnVisibility ? "0px" : "10px"}
        gap="10px"
        flexWrap="wrap"
        borderColor={theme.palette.custom.secondaryBorder}
      >
        {tableSearch && (
          <RectangularLoader
            maxWidth={isSmallScreen ? "100%" : "280px"}
            width="100%"
            height="35px"
          />
        )}
        <Box
          display="flex"
          gap="10px"
          flexWrap="wrap"
          flexGrow={3}
          justifyContent={isSmallScreen ? "flex-start" : "space-between"}
        >
          <Box display="flex" gap="10px" flexWrap="wrap" justifyContent="flex-start">
            {!hideColumnVisibility && <RectangularLoader width="200px" height="35px" />}
          </Box>
        </Box>
      </Box>
      <TableLoader columnsVisibility={columnsVisibility} rows={rows} />
    </>
  );
};
