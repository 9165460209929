import { Skeleton, useTheme } from "@mui/material";

type WorkflowTemplateItemLoaderProps = {
  width: string;
  height: string;
};

export const WorkflowTemplateItemLoader = ({ width, height }: WorkflowTemplateItemLoaderProps) => {
  const theme = useTheme();
  return (
    <Skeleton
      variant="rounded"
      width={width}
      height={height}
      sx={{
        borderRadius: "3px",
        border: `1px solid ${theme.palette.custom.secondaryBorder}`,
        flex: "1 1 20%",
        "@media(max-width: 850px)": {
          flexBasis: "calc(50% - 8px)",
        },
      }}
    />
  );
};

export const WorkflowTemplateLoader = () => {
  return (
    <>
      <WorkflowTemplateItemLoader width="100%" height="280px" />
      <WorkflowTemplateItemLoader width="100%" height="280px" />
      <WorkflowTemplateItemLoader width="100%" height="280px" />
      <WorkflowTemplateItemLoader width="100%" height="280px" />
    </>
  );
};
