import {
  VendorProcurementRequirement,
  VendorProcurementRequirementFetchOptions,
} from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const vendorProcurementRequirementEndpoints = {
  vendorPorcurementRequirements: "/vendor_procurement_requirements",
  vendorProcurementRequirement: (id: string) => `/vendor_procurement_requirements/${id}`,
};

export const getVendorProcurementRequirement = (id: string) => {
  return httpGetAuthenticated<VendorProcurementRequirement>(
    vendorProcurementRequirementEndpoints.vendorProcurementRequirement(id)
  );
};

export const getVendorProcurementRequirements = (
  params: VendorProcurementRequirementFetchOptions
) => {
  return httpGetAuthenticated<ListResponse<VendorProcurementRequirement>>(
    vendorProcurementRequirementEndpoints.vendorPorcurementRequirements,
    {
      params,
    }
  );
};

export const addVendorProcurementRequirement = async (payload: {
  vendor: string;
  name: string;
  requirement_type: string;
  requirement: string;
  assignee: string;
  status: string;
}) => {
  const { data } = await httpPostAuthenticated<VendorProcurementRequirement>(
    vendorProcurementRequirementEndpoints.vendorPorcurementRequirements,
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.VendorProcurementRequirement] });
  return data;
};

export const editVendorProcurementRequirement = async (
  id: string,
  payload: {
    name?: string;
    requirement_type?: string;
    requirement?: string;
    assignee?: string;
    status?: string;
  }
) => {
  const resp = await httpPatchAuthenticated(
    vendorProcurementRequirementEndpoints.vendorProcurementRequirement(id),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.VendorProcurementRequirement] });
  return resp;
};

export const deleteVendorProcurementRequirement = async (id: string) => {
  const resp = await httpDeleteAuthenticated(
    vendorProcurementRequirementEndpoints.vendorProcurementRequirement(id)
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.VendorProcurementRequirement] });
  return resp;
};
