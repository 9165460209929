import { useQuery } from "react-query";
import { getExecutionTask, getExecutionTasks } from "../services/ExecutionPlanService";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ExecutionTaskFetchOptions } from "../models/types";

export const useExecutionTask = (taskId: string) => {
  return useQuery([QueryKey.ExecutionTask, taskId], async () => {
    const { data } = await getExecutionTask(taskId);
    return data;
  });
};

export const fetchExecutionTask = (taskId: string) => {
  return queryClient.fetchQuery([QueryKey.ExecutionTask, taskId], async () => {
    const { data } = await getExecutionTask(taskId);
    return data;
  });
};

export const searchExecutionTasks = async (searchText: string) => {
  const tasks = await fetchExecutionTasks({ search: searchText });
  return tasks.results;
};

export const fetchExecutionTasks = (params: ExecutionTaskFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.ExecutionTask, params], async () => {
    const { data } = await getExecutionTasks(params);
    return data;
  });
};
