import { ActionIndicatorIcon } from "../components/AppIcons";
import { actionHooks } from "../state/QueryStore";
import { IGetListQueryParams } from "../state/QueryStoreHelper";
import AppStrings from "../strings";
import { ObjectDetailViewModel, ObjectListViewModel } from "./IObjectCommonFields";
import { RoleDetailViewModel } from "./Role";
import { ActionType } from "./types";

class ActionDetailViewModel extends ObjectDetailViewModel {
  authorizedRoles: RoleDetailViewModel[];
  action_type: string | undefined;
  action_num: number;

  constructor(rawData: any) {
    super(rawData, rawData.action_num);
    this.summary = rawData.name;
    this.action_type = rawData.action_type;
    this.action_num = rawData.action_num;
    this.authorizedRoles = ((rawData.authorized_roles ?? []) as any[]).map(
      (e) => new RoleDetailViewModel(e)
    );
  }

  get objectIndicatorName() {
    return AppStrings.actions.indicatorLabel;
  }
  get objectIndicatorIcon() {
    return <ActionIndicatorIcon />;
  }

  get objectName() {
    return AppStrings.actions.name;
  }

  get objectHooks() {
    return actionHooks as any;
  }
}

interface ICreateActionRequestBody {
  name: string;
  description: string;
  actionType?: ActionType;
}

interface IUpdateActionRequestBody {
  name: string;
  description: string;
}

interface IGetActionListRequestBody extends IGetListQueryParams {}

interface IActionAddRoleRequest {
  actionId: string;
  roleId: string;
}

interface IActionRemoveRoleRequest extends IActionAddRoleRequest {}

export { ActionDetailViewModel as ActionListViewModel, ActionDetailViewModel };
export type {
  ICreateActionRequestBody,
  IUpdateActionRequestBody,
  IGetActionListRequestBody,
  IActionAddRoleRequest,
  IActionRemoveRoleRequest,
};
