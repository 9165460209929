import { Box, Stack, Typography, useTheme } from "@mui/material";
import { SmallModal } from "../../components/SmallModal";
import { INavbarIconVariant, NavbarIcon } from "../../atoms/navbar/Icon";
import { LoadingButton } from "@mui/lab";
import { getFeaturePaywallDetails } from "../../utilities/UIHelper";
import { openIntercom } from "../../services/IntercomService";

type FeaturePaywallModalProps = {
  /** Should the modal be open? */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
  /** Feature paywall route */
  route: string;
};

export const FeaturePaywallModal = (props: FeaturePaywallModalProps) => {
  const { open, onClose, route } = props;

  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const backgroundColor = isDarkMode ? "rgba(140, 255, 71, 0.35)" : "rgba(140, 255, 71, 0.35)";

  const { icon, title, description } = getFeaturePaywallDetails(route);

  return (
    <SmallModal
      open={open}
      onClose={onClose}
      size="medium"
      sx={{
        backdropFilter: "blur(2px)",
      }}
    >
      <Stack gap="10px" alignItems="center">
        <NavbarIcon
          variant={icon as INavbarIconVariant}
          sx={{
            width: "100%",
            height: "100%",
          }}
        />
        <Box display="flex" flexDirection="column" gap="8px" paddingY="10px">
          <Box display="flex" flexDirection="column" gap="10px" alignItems="center">
            <Box gap="5px" display="flex" alignItems="center" flexDirection="row">
              <NavbarIcon
                variant="lock"
                color={theme.palette.custom.secondaryTypography}
                sx={{ width: "24px", height: "24px" }}
              />
              <Box
                borderRadius="3px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ backgroundColor }}
              >
                <Typography variant="h4" lineHeight="15px" fontSize="12px" padding="4.5px 4px">
                  Enterprise
                </Typography>
              </Box>
            </Box>
            <Typography variant="h1" textAlign="center">
              {title}
            </Typography>
          </Box>
          <Typography variant="body1" textAlign="center">
            {description}
          </Typography>
        </Box>
        <LoadingButton
          variant="contained"
          sx={{
            width: "232px",
          }}
          onClick={() => openIntercom()}
        >
          Contact Us
        </LoadingButton>
      </Stack>
    </SmallModal>
  );
};
