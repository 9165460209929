import { Box } from "@mui/material";
import { DefaultBadge } from "../atoms/StatusBadge";

type DefaultBadgeListProps = {
  badges: string[];
  renderIcon?: (badge: string) => React.ReactNode;
  onClick?: (badge: string) => void;
};

export const DefaultBadgeList = ({ badges, renderIcon, onClick }: DefaultBadgeListProps) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      maxWidth="100%"
      gap="5px"
      flexWrap="wrap"
    >
      {badges.map((badge, index) => (
        <DefaultBadge
          renderIcon={renderIcon ? () => renderIcon(badge) : undefined}
          key={index}
          value={badge}
          onClick={onClick ? () => onClick(badge) : undefined}
        />
      ))}
    </Box>
  );
};
