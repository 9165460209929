import { Box, Checkbox, Link, Stack, Typography } from "@mui/material";
import { SmallModal } from "../../../components/SmallModal";
import { NavbarIcon } from "../../../atoms/navbar/Icon";
import { LoadingButton } from "@mui/lab";
import config from "../../../config/config";
import { useState } from "react";
import { acceptTermsConditions } from "../../../services/OrganizationService";
import { useOrganizationGet } from "../../../state/QueryStore";

type WelcomeToFairoModalProps = {
  /** Should the modal be open? */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
  /** Welcome text description */
  welcomeDescription: string;
  /** Callback to open the invite users modal */
  onStart: () => void;
  /** To see if onboarding for first time */
  onboarding?: boolean;
};

export const WelcomeToFairoModal = (props: WelcomeToFairoModalProps) => {
  const { data: org, isLoading } = useOrganizationGet();
  const { open, onClose, welcomeDescription, onStart, onboarding = false } = props;

  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleStart = async () => {
    setLoading(true);
    try {
      if (org) {
        await acceptTermsConditions(org!.id, {
          terms_conditions_accepted: true,
        });
        window.location.href = "/organization";
      }
      onStart();
    } catch (e) {
      console.error("Failed accepting terms and conditions", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SmallModal open={open} onClose={onClose} size="medium" preventBackdrop={true}>
      <Stack gap="10px">
        <NavbarIcon
          variant="welcome-to-fairo"
          sx={{
            width: "100%",
            height: "100%",
          }}
        />
        <Box display="flex" flexDirection="column" gap="8px" alignItems="center" paddingTop="10px">
          <Typography variant="h1">Welcome to Fairo</Typography>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <Typography variant="body1" textAlign="center">
              {welcomeDescription}
            </Typography>
          </Box>
        </Box>
        {onboarding && (
          <Box display="flex" alignItems="center" gap="8px" justifyContent="center">
            <Checkbox
              checked={termsAccepted}
              onChange={(_, checked) => setTermsAccepted(checked)}
            />
            <Typography variant="body2">
              I agree to Fairo{" "}
              <Link href={config.links.termsAndConditions} target="_blank" fontWeight="700">
                terms and conditions
              </Link>{" "}
              and{" "}
              <Link href={config.links.privacyPolicy} target="_blank" fontWeight="700">
                privacy policies.
              </Link>
            </Typography>
          </Box>
        )}
        <Box display="flex" justifyContent="center">
          <LoadingButton
            variant="contained"
            onClick={handleStart}
            sx={{
              width: "232px",
            }}
            disabled={onboarding && !termsAccepted}
            loading={loading}
          >
            Start
          </LoadingButton>
        </Box>
      </Stack>
    </SmallModal>
  );
};
