import { Box, Typography, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

export const QuickLinksLoader = () => {
  const theme = useTheme();
  return (
    <Box
      display="flex"
      flexDirection="column"
      padding="10px"
      gap="10px"
      width="100%"
      border={`1px solid ${theme.palette.custom.secondaryBorder}`}
      borderRadius="6px"
      height="100%"
      bgcolor={theme.palette.background.paper}
    >
      <Typography variant="h3">Quick Links</Typography>
      {[...Array(4)].map((_, index) => (
        <RectangularLoader height="35px" width="100%" />
      ))}
    </Box>
  );
};
