import { useQuery } from "react-query";
import { WorkflowFetchOptions } from "../models/types";
import { getWorkflows } from "../services/ExecutionPlanService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useWorkflows = (params: WorkflowFetchOptions) => {
  return useQuery([QueryKey.Workflow, params], async () => {
    const { data } = await getWorkflows(params);
    return data;
  });
};

export const fetchWorkflows = (params: WorkflowFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.Workflow, params], async () => {
    const { data } = await getWorkflows(params);
    return data;
  });
};

export const searchWorkflows = async (searchText: string) => {
  const workflows = await fetchWorkflows({ search: searchText });
  return workflows.results;
};
