import { RoleIndicatorIcon } from "../components/AppIcons";
import { roleHooks } from "../state/QueryStore";
import { IGetListQueryParams } from "../state/QueryStoreHelper";
import AppStrings from "../strings";
import { ObjectDetailViewModel } from "./IObjectCommonFields";
import { IUser, parseUserFromListResult } from "./IUser";

class RoleDetailViewModel extends ObjectDetailViewModel {
  authorizedUsers: IUser[];
  role_num: number;
  constructor(rawData: any) {
    super(rawData, rawData.role_num);
    this.role_num = rawData.role_num;
    this.summary = rawData.name;

    this.authorizedUsers = ((rawData.authorized_users ?? []) as any[]).map((e) =>
      parseUserFromListResult(e)
    );
  }

  get objectIndicatorName() {
    return AppStrings.roles.indicatorLabel;
  }

  get objectIndicatorIcon() {
    return <RoleIndicatorIcon />;
  }

  get objectName() {
    return AppStrings.roles.name;
  }

  get objectHooks() {
    return roleHooks as any;
  }
}

interface ICreateRoleRequestBody {
  name: string;
  description: string;
}

interface IUpdateRoleRequestBody {
  name: string;
  description: string;
}

interface IGetRoleListRequestBody extends IGetListQueryParams {}

interface IRoleAddUserRequest {
  roleId: string;
  username: string;
}

interface IRoleRemoveUserRequest extends IRoleAddUserRequest {}

export { RoleDetailViewModel as RoleListViewModel, RoleDetailViewModel };
export type {
  ICreateRoleRequestBody,
  IUpdateRoleRequestBody,
  IGetRoleListRequestBody,
  IRoleAddUserRequest,
  IRoleRemoveUserRequest,
};
