import { useQuery } from "react-query";
import { getFrameworks, getFramework } from "../services/FrameworkService";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ComplianceFrameworkFetchOptions } from "../models/types";

export const useFrameworks = (params: ComplianceFrameworkFetchOptions) => {
  return useQuery([QueryKey.ComplianceFramework, params], async () => {
    const { data } = await getFrameworks(params);
    return data;
  });
};

export const useFramework = (frameworkId: string, params?: { fairo_data?: boolean }) => {
  return useQuery([QueryKey.ComplianceFramework, frameworkId], async () => {
    const { data } = await getFramework(frameworkId, params);
    return data;
  });
};

export const fetchFrameworks = (params: any) => {
  return queryClient.fetchQuery([QueryKey.ComplianceFramework, params], async () => {
    const { data } = await getFrameworks({ ...params, fairo_data: true });
    return data;
  });
};

export const fetchFramework = (frameworkId: string, params?: { fairo_data?: boolean }) => {
  return queryClient.fetchQuery([QueryKey.ComplianceFramework, frameworkId], async () => {
    const { data } = await getFramework(frameworkId, params);
    return data;
  });
};

export const searchFrameworks = async (searchText: string) => {
  const frameworks = await fetchFrameworks({ search: searchText });
  return frameworks.results;
};
