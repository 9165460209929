import { Box, Typography, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { CircularLoader } from "../../molecules/skeleton-loader/CircularLoader";

type GraphLoaderProps = {
  title?: string;
  variant?: "circular" | "rectangular";
  height?: string;
  width?: string;
  maxWidth?: string;
  reducerFilter?: boolean;
  timeFilter?: boolean;
  dateFilter?: boolean;
  legend?: boolean;
  legendHeight?: string;
  legendWidth?: string;
};

export const GraphLoader = (props: GraphLoaderProps) => {
  const {
    title,
    variant,
    height = "279px",
    width,
    maxWidth = "100%",
    reducerFilter,
    timeFilter,
    dateFilter,
    legend,
    legendHeight,
    legendWidth,
  } = props;

  const theme = useTheme();

  return (
    <Box sx={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
      {reducerFilter || timeFilter || dateFilter ? (
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }} // Use column layout on xs (mobile) screens, and row layout on sm (small) screens and above
          padding="10px"
          justifyContent="start"
          alignItems="start"
          gap="10px"
          sx={{
            borderRadius: 0,
            borderBottom: "1px solid",
            borderBottomColor: theme.palette.custom.secondaryBorder,
          }}
        >
          {reducerFilter && <RectangularLoader width="33%" maxWidth="170px" height="35px" />}
          {timeFilter && <RectangularLoader width="33%" maxWidth="170px" height="35px" />}
          {dateFilter && <RectangularLoader width="33%" maxWidth="170px" height="35px" />}
          {dateFilter && <RectangularLoader width="33%" maxWidth="170px" height="35px" />}
        </Box>
      ) : (
        <></>
      )}
      {title && (
        <Box padding="10px">
          <Typography variant="h2">{title}</Typography>
        </Box>
      )}
      <Box padding="10px" display="flex" gap="10px" justifyContent="center" alignItems="end">
        {variant === "rectangular" ? (
          <RectangularLoader width={width} maxWidth="100%" height={height} />
        ) : variant === "circular" ? (
          <CircularLoader width={width} maxWidth="100%" height={height} />
        ) : (
          <></>
        )}
        {legend && <RectangularLoader width={legendWidth} maxWidth="100%" height={legendHeight} />}
      </Box>
    </Box>
  );
};
