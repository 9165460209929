import { useNavigate } from "react-router-dom";
import { SubscriptionModal } from "./SubscriptionModal";
import { ReactComponent as CoreIcon } from "../assets/subscriptionIcons/badgePlans.svg";
import { ReactComponent as ResubscribeIcon } from "../assets/subscriptionIcons/reSubscribe.svg";
import { useAuth } from "../hooks/useAuth";
import { ISubscription } from "../context/SubscriptionContext";

interface ISubscriptionFailureProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  subscriptions: ISubscription[];
}

const SubscriptionFailure = ({ isOpen, setIsOpen, subscriptions }: ISubscriptionFailureProps) => {
  const navigate = useNavigate();
  const { user: loggedInUser } = useAuth();
  const isAdmin = loggedInUser?.permissions === "Admin";
  const isTrialPaused = subscriptions[0]?.status === "paused";
  const isSubscriptionCanceled = subscriptions[0]?.status === "canceled";
  return isTrialPaused && isAdmin ? (
    <SubscriptionModal
      open={isOpen}
      title="Your Trial Has Ended."
      description="Continue using the platform by adding a payment method or upgrading to a new plan."
      acceptText="Add Payment Method"
      onAccept={async () => {
        setIsOpen(false);
        navigate("/organization/settings/plan");
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      Icon={CoreIcon}
    />
  ) : isSubscriptionCanceled && isAdmin ? (
    <SubscriptionModal
      open={isOpen}
      title="It’s Sad To See You Going."
      description="Continue using the platform by re-subscribing or upgrading to another plan."
      acceptText="Re-Subscribe"
      onAccept={async () => {
        setIsOpen(false);
        navigate("/organization/settings/plan");
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      Icon={ResubscribeIcon}
    />
  ) : (
    <SubscriptionModal
      open={isOpen}
      title=" Your Trial Has Ended."
      description="Contact your admin to continue using the platform by adding a payment method or upgrading to a new plan."
      onAccept={async () => {
        setIsOpen(false);
      }}
      onClose={() => {
        setIsOpen(false);
      }}
      Icon={CoreIcon}
    />
  );
};

export default SubscriptionFailure;
