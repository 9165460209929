import { RatingLevelOption } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const ratingLevelOptionEndpoints = {
  options: () => "/rating_level_options",
  option: (optionId: string) => `/rating_level_options/${optionId}`,
};

export const getRatingLevelOptions = (params: any) => {
  return httpGetAuthenticated<ListResponse<RatingLevelOption>>(
    ratingLevelOptionEndpoints.options(),
    {
      params: { ...params, fairo_data: true },
    }
  );
};

export const getRatingLevelOption = (optionId: string) => {
  return httpGetAuthenticated<RatingLevelOption>(ratingLevelOptionEndpoints.option(optionId), {
    params: { fairo_data: true },
  });
};

export const addRatingLevelOption = async (payload: { name: string }) => {
  const { data } = await httpPostAuthenticated<RatingLevelOption>(
    ratingLevelOptionEndpoints.options(),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.RatingLevelOptions] });
  return data;
};

export const editRatingLevelOption = async (
  optionId: string,
  payload: {
    name: string;
  }
) => {
  const { data } = await httpPatchAuthenticated(
    ratingLevelOptionEndpoints.option(optionId),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.RatingLevelOptions] });
  return data;
};

export const deleteRatingLevelOption = async (optionId: string) => {
  const resp = await httpDeleteAuthenticated(ratingLevelOptionEndpoints.option(optionId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.RatingLevelOptions, optionId] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.RatingLevelOptions] });
  return resp;
};
