import { Box, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { IconButton } from "../../../../atoms/IconButton";
import { NavbarIcon } from "../../../../atoms/navbar/Icon";
import { EvidenceTestInScopeResource } from "../../../../models/types";

type EvidenceInScopeResourcesThreeDotsMenuProps = {
  resource: EvidenceTestInScopeResource;
  onOpenHistory: () => void;
  onEvaluate: () => void;
  onExclude: () => void;
  onActivate: () => void;
};

export const EvidenceInScopeResourcesThreeDotsMenu = (
  props: EvidenceInScopeResourcesThreeDotsMenuProps
) => {
  const { resource, onExclude, onEvaluate, onOpenHistory, onActivate } = props;
  const theme = useTheme();
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuVisible(!isMenuVisible);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuVisible(!isMenuVisible);
  };

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <IconButton
        variant="three-dots-vertical"
        width="4px"
        height="16px"
        color="none"
        onClick={(e) => {
          toggleMenu(e);
        }}
      />
      <Menu
        elevation={0}
        id="three-dots-menu"
        MenuListProps={{
          "aria-labelledby": "three-dots-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          gap: "5px",
          "& .MuiPaper-root": {
            "& .MuiMenu-list": {
              padding: "4px",
              width: "192px",
            },
            "& .MuiMenuItem-root": {
              padding: "4px",
              "&:hover": {
                backgroundColor: theme.palette.custom.planBackground,
              },
            },
          },
        }}
      >
        {resource.status !== "DEACTIVATED" && (
          <MenuItem>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
              gap="5px"
              onClick={(e) => {
                onExclude();
                setAnchorEl(null);
              }}
            >
              <Typography variant="body2">Deactivate</Typography>
              <NavbarIcon
                variant="hide"
                selected
                sx={{
                  width: "18px",
                  height: "18px",
                  minHeight: "18px",
                  minWidth: "18px",
                }}
              />
            </Box>
          </MenuItem>
        )}
        {resource.status === "DEACTIVATED" && (
          <MenuItem>
            <Box
              display="flex"
              justifyContent="space-between"
              width="100%"
              alignItems="center"
              gap="5px"
              onClick={(e) => {
                onActivate();
                setAnchorEl(null);
              }}
            >
              <Typography variant="body2">Activate</Typography>
              <NavbarIcon
                variant="show"
                selected
                sx={{
                  width: "18px",
                  height: "18px",
                  minHeight: "18px",
                  minWidth: "18px",
                }}
              />
            </Box>
          </MenuItem>
        )}
        <MenuItem>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
            gap="5px"
            onClick={(e) => {
              onEvaluate();
              setAnchorEl(null);
            }}
          >
            <Typography variant="body2">Refresh</Typography>
            <NavbarIcon
              variant="re-launch"
              selected
              sx={{
                width: "18px",
                height: "18px",
                minHeight: "18px",
                minWidth: "18px",
              }}
            />
          </Box>
        </MenuItem>
        <MenuItem>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
            gap="5px"
            onClick={(e) => {
              onOpenHistory();
              setAnchorEl(null);
            }}
          >
            <Typography variant="body2">View Test History</Typography>
            <NavbarIcon
              variant="version-history"
              selected
              sx={{
                width: "18px",
                height: "18px",
                minHeight: "18px",
                minWidth: "18px",
              }}
            />
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};
