import { Box, Typography, useTheme } from "@mui/material";
import { INavbarIconVariant, NavbarIcon } from "../../atoms/navbar/Icon";

export const InternalMenuItemOption = ({
  name,
  onClick,
  selected,
  hasSubsections,
  isSubsection = false,
  expanded,
  variant,
  typographyPadding = "0px",
  typographyVariant = "h4",
}: {
  name: string;
  onClick: () => void;
  selected: boolean;
  hasSubsections: boolean;
  expanded: boolean;
  variant?: INavbarIconVariant;
  isSubsection?: boolean;
  typographyPadding?: string;
  typographyVariant?: "h4" | "navbar" | "body2";
}) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  return (
    <Box
      sx={{
        paddingY: "6px",
        paddingX: "6px",
        "&:hover": {
          backgroundColor: isDarkMode ? "background.paper" : "background.default",
          color: theme.palette.custom.primaryTypography,
          cursor: "pointer",
          borderRadius: "3px",
        },
        backgroundColor: selected ? (isDarkMode ? "background.paper" : "background.default") : "",
        color: selected ? theme.palette.custom.primaryTypography : "",
        borderRadius: selected ? "3px" : "",
      }}
      display="flex"
      flexDirection="row"
      gap="5px"
      alignItems="center"
      justifyContent="space-between"
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
    >
      <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
        {hasSubsections && (
          <NavbarIcon
            variant={expanded ? "chevron-up-sm" : "chevron-down-sm"}
            selected={selected}
            sx={{
              width: "10px",
              height: "10px",
              minHeight: "10px",
              minWidth: "10px",
            }}
          />
        )}
        {variant && (
          <NavbarIcon
            selected={selected}
            sx={{
              width: "22px",
              height: "22px",
              minHeight: "22px",
              minWidth: "22px",
            }}
            variant={variant}
          />
        )}
        <Typography
          variant={typographyVariant}
          fontWeight={400}
          padding={typographyPadding}
          paddingLeft={isSubsection ? "20px" : undefined}
          sx={{
            "&:hover": {
              cursor: "pointer",
            },
            color:
              selected || hasSubsections
                ? theme.palette.custom.primaryTypography
                : theme.palette.custom.primaryTypography,
          }}
        >
          {name}
        </Typography>
      </Box>
    </Box>
  );
};
