import { Box, Stack, useTheme } from "@mui/material";
import { useState } from "react";
import { NavbarIcon } from "../../../atoms/navbar/Icon";
import { CreatePolicyOption } from "../../../components/CreatePolicyOption";
import { SmallModal } from "../../../components/SmallModal";
import { openSnackbar } from "../../../context/SnackbarContext";
import { addFile } from "../../../services/FileService";
import { QueryKey } from "../../../state/QueryStore";
import { AddHyperlink } from "./AddHyperlink";
import { UploadFile } from "./UploadFile";

type AddHyperlinkOrFileProps = {
  open: boolean;
  onClose: () => void;
  onChange: (value: string) => void;
  relatedObjectId: string;
};

export const AddHyperlinkOrFileModal = (props: AddHyperlinkOrFileProps) => {
  const { open, onClose, onChange, relatedObjectId } = props;
  const [openAddHyperlink, setOpenAddHyperlink] = useState(false);
  const [openUploadFile, setOpenUploadFile] = useState(false);

  const onAddHyperlink = (url: string, name: string, description: string) => {
    onChange(JSON.stringify({ type: "hyperlink", url, name, description }));
    setOpenAddHyperlink(false);
    onClose();
  };

  const onUploadFile = async (data: { file: File; name: string; description?: string }) => {
    try {
      const file = await addFile(
        relatedObjectId,
        data.file,
        QueryKey.CustomFieldValue,
        data.name,
        data.description
      );
      onChange(
        JSON.stringify({
          type: "file",
          id: file.data.id,
          name: data.name,
          description: data.description,
        })
      );
    } catch {
      openSnackbar("Failed to upload file", "error");
    } finally {
      setOpenUploadFile(false);
      onClose();
    }
  };

  const theme = useTheme();
  return (
    <SmallModal open={open} onClose={onClose} size="small" title="Add File or Hyperlink">
      <Stack gap="10px">
        <AddHyperlink
          hideRecurrence
          open={openAddHyperlink}
          onClose={() => {
            setOpenAddHyperlink(false);
          }}
          onAddHyperlink={onAddHyperlink}
        />
        <UploadFile
          template_version_id={null}
          open={openUploadFile}
          onClose={() => {
            setOpenUploadFile(false);
          }}
          onUpload={onUploadFile}
          assetType="documentation"
          hideRecurrence
        />
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            rowGap: "20px",
            columnGap: "20px",
            flexWrap: "wrap",
          }}
        >
          <CreatePolicyOption
            name="Upload a file"
            description="Supported files: .pdf & .docx up to 10MB."
            icon={
              <NavbarIcon
                variant="upload"
                sx={{
                  width: "30px",
                  height: "30px",
                }}
                color={theme.palette.custom.hyperlink}
              />
            }
            onClick={() => setOpenUploadFile(true)}
          />
          <CreatePolicyOption
            name="Add Hyperlink"
            description="Add any reference to using a link and name."
            icon={
              <NavbarIcon
                variant="hyperlink"
                sx={{
                  width: "30px",
                  height: "30px",
                }}
                color={theme.palette.custom.hyperlink}
              />
            }
            onClick={() => setOpenAddHyperlink(true)}
          />
        </Box>
      </Stack>
    </SmallModal>
  );
};
