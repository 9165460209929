import { Button, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { SearchableDropdown } from "../../../components/SearchableDropdown";
import { SmallModal } from "../../../components/SmallModal";
import {
  useDatabricksCatalogs,
  useDatabricksSchemas,
  useDatabricksTables,
} from "../../../hooks/useDatabricks";
import { DatabricksCatalog, DatabricksSchema, DatabricksTable } from "../../../models/types";

type PluginInferenceTableCustomFieldModalProps = {
  open: boolean;
  onClose: () => void;
  onChange: (value: string) => void;
};

export const PluginInferenceTableCustomFieldModal = (
  props: PluginInferenceTableCustomFieldModalProps
) => {
  const { open, onClose, onChange } = props;
  const theme = useTheme();
  const [catalog, setCatalog] = useState<DatabricksCatalog | null>(null);
  const [schema, setSchema] = useState<DatabricksSchema | null>(null);
  const [table, setTable] = useState<DatabricksTable | null>(null);
  const textColor = theme.palette.custom.secondaryTypography;
  const { data: catalogs } = useDatabricksCatalogs();
  const { data: schemas } = useDatabricksSchemas({ catalog_name: catalog?.name ?? "" });
  const { data: tables } = useDatabricksTables({
    schema_name: schema?.name ?? "",
    catalog_name: catalog?.name ?? "",
  });

  useEffect(() => {
    setSchema(null);
    setTable(null);
  }, [catalog]);

  useEffect(() => {
    setTable(null);
  }, [schema]);

  useEffect(() => {
    setCatalog(null);
    setSchema(null);
    setTable(null);
  }, [open]);

  return (
    <SmallModal open={open} onClose={() => onClose()} title={`Select Table`} size="small">
      <Stack gap="10px">
        <Typography variant="h3" color={textColor}>
          Catalog
        </Typography>
        <SearchableDropdown<DatabricksCatalog>
          value={catalog}
          onChange={setCatalog}
          getOptions={(search: string) =>
            catalogs?.filter((option) => option.name.includes(search.toLowerCase())) ?? []
          }
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(a, b) => a === b}
        />
        <Typography variant="h3" color={textColor}>
          Schema
        </Typography>
        <SearchableDropdown<DatabricksSchema>
          value={schema}
          onChange={setSchema}
          disabled={!catalog}
          getOptions={(search: string) =>
            schemas?.filter((option) => option.name.includes(search.toLowerCase())) ?? []
          }
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(a, b) => a === b}
        />
        <Typography variant="h3" color={textColor}>
          Table
        </Typography>
        <SearchableDropdown<DatabricksTable>
          value={table}
          onChange={setTable}
          disabled={!catalog || !schema}
          getOptions={(search: string) =>
            tables?.filter((option) => option.name.includes(search.toLowerCase())) ?? []
          }
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(a, b) => a === b}
        />
        <Button
          disabled={!table}
          onClick={() => {
            if (table) {
              onChange(table.full_name);
              onClose();
            }
          }}
        >
          Add
        </Button>
      </Stack>
    </SmallModal>
  );
};
