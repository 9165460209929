import { Box, Typography } from "@mui/material";
import { useEffect } from "react";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { useTags } from "../../hooks/useTags";
import { FieldHandlerProps, Tag } from "../../models/types";
import { ColorBox } from "../../organisms/modals/tag/CreateTagModal";
import { Awaitable } from "../../utilities/common";
import { isJSON } from "../../utilities/UIHelper";
import { BadgeOption } from "../BadgeOption";
import { RectangularLoader } from "../skeleton-loader/RectangularLoader";

type TagListReadOnlyCustomFieldProps = {
  value: string[];
};

export const TagListReadOnlyCustomField = ({ value }: TagListReadOnlyCustomFieldProps) => {
  const { data: tags, isLoading } = useTags({ id: value, fairo_data: true });

  return isLoading ? (
    <RectangularLoader height="20px" width="100%" />
  ) : tags?.count && value.length > 0 ? (
    <Box display="flex" gap="5px" flexWrap="wrap">
      {tags.results.map((tag, index) => {
        return (
          <BadgeOption
            key={index}
            name={tag?.name}
            color={tag?.color}
            onClick={() => {}}
            maxWidth="325px"
          />
        );
      })}
    </Box>
  ) : (
    <Typography variant="body2">No tags</Typography>
  );
};

export const TagListCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<Tag[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const parsedValue = typeof value === "string" && isJSON(value) ? JSON.parse(value) : value;
  const { data: tags } = useTags({ id: parsedValue, fairo_data: true });
  useEffect(() => {
    if (tags && tags.results && tags.results.length && setObjectAttributes) {
      setObjectAttributes(tags.results[0] ?? {});
    }
  }, [tags]);
  return (
    <SearchableDropdown<Tag>
      label="Tag"
      required={required}
      error={error}
      multiple={true}
      value={parsedValue.length === 0 ? [] : tags?.results ?? []}
      onChange={(newValue) => newValue !== null && onChange(newValue.map((tag) => tag.id))}
      getOptionLabel={(obj) => obj.name ?? "-"}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(tag) => [
        tag.name,
        <Box display="flex" flexDirection="row" gap="5px" alignItems="center">
          <ColorBox size="14px" color={tag.color} onClick={() => {}} />
          <Typography variant="button">{tag.color}</Typography>
        </Box>,
      ]}
    />
  );
};
