import { Box, useTheme } from "@mui/material";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { PageDetailLayout } from "../../components/layout/PageDetailLayout";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { ChildrenBoxLoader } from "../../organisms/skeleton-loaders/ChildrenBoxLoader";
import { DetailsLoader } from "../../organisms/skeleton-loaders/DetailsLoader";
import { ListViewLoader } from "./ListViewLoader";
import { runHistoryColumnsVisibility } from "../../utilities/columns-visibility/RunHistoryListColumnsVisibility";
import { columnsVisibility } from "../../utilities/columns-visibility/RelatedControlsListColumnVisibility";

export const WorkflowDetailsLoader = () => {
  const theme = useTheme();

  const fillBlankSpace = true;

  return (
    <PageDetailLayout
      leftComponent={
        <Box display="flex" flexDirection="column" gap="15px" paddingBottom="80px">
          <SecondaryBox>
            <DetailsLoader />
          </SecondaryBox>
          <SecondaryBox>
            <RectangularLoader height="300px" width="100%" />
          </SecondaryBox>
          <ListViewLoader
            paddingBottom={false}
            columnsVisibility={runHistoryColumnsVisibility({
              onReRun: () => {},
            })}
            tableSize="small"
          />
          <ListViewLoader
            paddingBottom={false}
            columnsVisibility={columnsVisibility}
            tableSize="small"
          />
        </Box>
      }
    />
  );
};
