import { getIdFromUrl } from "./IObjectCommonFields";

interface IUser {
  id: string;
  username: string;
  name?: string;
  email?: string;
  phone_number_confirmed: boolean;
  type: string;
  primary_auth_method: string;
  require_2fa: boolean;
  two_factor_auth_method: string;
  phone_number: string;
  joinedOn?: Date;
  invitedOn?: Date;
  permissions?: string; //* For Mocking
  status?: string; //* For Mocking
}

interface ICreateUserRequestBody {
  username: string;
  password: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  joinedon?: string; //* For Mocking
  permissions?: string; //* For Mocking
  status?: string; //* For Mocking
}

const parseUserFromListResult = (data: any): IUser => {
  return {
    id: data.id,
    username: data.username,
    name: data.name,
    email: data.email,
    phone_number_confirmed: data.phone_number_confirmed,
    primary_auth_method: data.primary_auth_method,
    require_2fa: data.require_2fa,
    two_factor_auth_method: data.two_factor_auth_method,
    phone_number: data.phone_number,
    type: "Standard", // TODO: update after api implementation
    joinedOn: data.date_joined ? new Date(data.date_joined) : undefined,
    invitedOn: data.invited_on ? new Date(data.invited_on) : undefined,
    permissions: data.permissions,
    status: data.is_active ? "Active" : data.is_invited ? "Invited" : "Inactive",
  };
};

export type { IUser, ICreateUserRequestBody };
export { parseUserFromListResult };
