import { CircularProgress } from "@mui/material";
import { Navigate, Outlet } from "react-router-dom";
import { useOrganizationGet } from "../state/QueryStore";

type Props = { children: any };

/** Routes wrapped with [OrganizationProtectedRoutes] will open if user has organization */
const OrganizationProtectedRoutes = () => {
  const { isLoading, data } = useOrganizationGet();

  if (isLoading) {
    return <CircularProgress className="centered-element" />;
  }

  if (!data) {
    return <Navigate to="/organization" />;
  }

  return <Outlet />;
};

export { OrganizationProtectedRoutes };
