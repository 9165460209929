import { useEffect } from "react";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { useTestRun, useTestRuns } from "../../hooks/useTestRuns";
import { FieldHandlerProps, TestRun } from "../../models/types";
import { Awaitable } from "../../utilities/common";

export const TestRunCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<TestRun[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const { data: testRun } = useTestRun(value || "");
  useEffect(() => {
    if (testRun && setObjectAttributes) {
      setObjectAttributes(testRun ?? {});
    }
  }, [testRun]);
  return (
    <SearchableDropdown<TestRun>
      label="Test Run"
      required={required}
      error={error}
      value={testRun || null}
      onChange={(newValue) => (newValue != null ? onChange(newValue.id) : onChange(null))}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [obj.name, obj.description ?? ""]}
    />
  );
};

export const TestRunListCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<TestRun[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const { data: items } = useTestRuns({ id: value });
  useEffect(() => {
    if (items && items.results && items.results.length && setObjectAttributes) {
      setObjectAttributes(items.results[0] ?? {});
    }
  }, [items]);
  return (
    <SearchableDropdown<TestRun>
      label="Test Run"
      required={required}
      error={error}
      multiple={true}
      value={value.length === 0 ? [] : items?.results ?? []}
      onChange={(newValue) => newValue != null && onChange(newValue.map((item) => item.id))}
      getOptionLabel={(obj) => obj.name}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [obj.name, obj?.description ?? ""]}
    />
  );
};
