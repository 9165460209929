import { Box } from "@mui/material";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { DetailsLoader } from "../../organisms/skeleton-loaders/DetailsLoader";
import { TasksColumnsVisibility } from "../../organisms/tables/tasks/TasksColumnsVisibility";
import { AssetFilesLoader } from "../../organisms/skeleton-loaders/AssetFilesLoader";
import { ListViewLoader } from "./ListViewLoader";
import { columnsVisibility } from "../../utilities/columns-visibility/RelatedControlsListColumnVisibility";

export const AssetPolicyDetailsLoader = () => {
  return (
    <Box display="flex" flexDirection="column" gap="15px">
      <SecondaryBox>
        <AssetFilesLoader />
      </SecondaryBox>
      <SecondaryBox>
        <DetailsLoader />
      </SecondaryBox>
      <ListViewLoader
        paddingBottom={false}
        columnsVisibility={TasksColumnsVisibility({
          onEdit: (taskId: string) => {},
          showCompleted: false,
        })}
        tableSize="small"
      />
      <ListViewLoader
        paddingBottom={false}
        columnsVisibility={columnsVisibility}
        tableSize="small"
      />
    </Box>
  );
};
