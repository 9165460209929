import { LinkedItem } from "../models/IWorkflow";
import {
  EditJiraIssue,
  ExecutionTask,
  ExecutionTaskFetchOptions,
  ExternalTask,
  RegistryFieldType,
  TaskType,
  Workflow,
  WorkflowFetchOptions,
  WorkflowProcessGraph,
} from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const executionPlanEndpoints = {
  workflows: () => "/workflows",
  workflow: (workflowId: string) => `/workflows/${workflowId}`,
  executionTasks: () => "/tasks",
  executionTask: (taskId: string) => `/tasks/${taskId}`,
};

export const getWorkflows = (params: WorkflowFetchOptions) => {
  return httpGetAuthenticated<ListResponse<Workflow>>(executionPlanEndpoints.workflows(), {
    params,
  });
};

export const getWorkflow = (workflowId: string) => {
  return httpGetAuthenticated<Workflow>(executionPlanEndpoints.workflow(workflowId));
};

export const addWorkflow = async (workflowData: {
  summary: string;
  description?: string;
  template: string;
  process_graph: any;
}) => {
  const resp = await httpPostAuthenticated<Workflow>(
    executionPlanEndpoints.workflows(),
    workflowData
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.Workflow] });
  return resp;
};

export const editWorkflow = async (
  workflowId: string,
  workflowData: {
    summary?: string;
    description?: string;
    process_graph?: WorkflowProcessGraph;
  }
) => {
  const resp = await httpPatchAuthenticated(
    executionPlanEndpoints.workflow(workflowId),
    workflowData
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.Workflow] });
  return resp;
};

export const deleteWorkflow = async (workflowId: string) => {
  const resp = await httpDeleteAuthenticated(executionPlanEndpoints.workflow(workflowId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Workflow] });
  return resp;
};

export const getExecutionTasks = (params: ExecutionTaskFetchOptions) => {
  return httpGetAuthenticated<ListResponse<ExecutionTask>>(
    executionPlanEndpoints.executionTasks(),
    {
      params,
    }
  );
};

export const getExecutionTask = (taskId: string) => {
  return httpGetAuthenticated<ExecutionTask>(executionPlanEndpoints.executionTask(taskId));
};

export const editExecutionTask = async (
  taskId: string,
  data: {
    name?: string;
    task_type?: TaskType;
    comments?: string;
    assignee?: string | null;
    status?: "PENDING" | "COMPLETED" | "CANCELED";
    custom_fields?: { [key: string]: any };
    linked_items?: LinkedItem[];
    issue?: EditJiraIssue | null;
    task_external_ids?: ExternalTask[] | null;
    integration_type?: string | null;
  }
) => {
  const resp = await httpPatchAuthenticated(executionPlanEndpoints.executionTask(taskId), data);
  queryClient.invalidateQueries({ queryKey: [QueryKey.ExecutionTask] });
  return resp;
};

export const addExecutionTask = async (taskData: {
  name?: string;
  parent_object_id?: string | null;
  parent_type?: RegistryFieldType;
  task_type: TaskType | null;
  assignee?: string | null;
  comments?: string;
  metadata?: {
    question_ref?: string;
    impact_assessment: string;
    assignees?: string[] | string;
  };
  issue?: any;
  status?: "PENDING" | "COMPLETED" | "CANCELED";
}) => {
  const resp = await httpPostAuthenticated(executionPlanEndpoints.executionTasks(), taskData);
  queryClient.invalidateQueries({ queryKey: [QueryKey.ExecutionTask] });
  return resp;
};

export const deleteExecutionTask = async (taskId: string) => {
  const resp = await httpDeleteAuthenticated(executionPlanEndpoints.executionTask(taskId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.ExecutionTask] });
  return resp;
};
