import {
  FunctionComponent,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { DarkTheme } from "../themes/dark";
import { LightTheme } from "../themes/light";

import { ThemeProvider } from "@mui/material";
import { getCookie, setCookie } from "./CookiesContext";

interface IThemeContext {
  themeName: "light" | "dark";
  toggleTheme: () => void;
  navbarExpanded: boolean;
  setNavbarExpanded: (value: boolean) => void;
}

const ThemeContext = createContext({} as IThemeContext);

interface IAppThemeProviderProps {
  children: ReactNode;
}

export const AppThemeProvider: FunctionComponent<IAppThemeProviderProps> = ({ children }) => {
  const themeCookies = getCookie("theme") as "light" | "dark" | "";
  const [themeName, setThemeName] = useState<"light" | "dark">(themeCookies || "light");
  const [navbarExpanded, setNavbarExpanded] = useState<boolean>(false);

  const toggleTheme = useCallback(() => {
    setThemeName(themeName === "light" ? "dark" : "light");
  }, [themeName]);

  const theme = useMemo(() => {
    setCookie("theme", themeName);
    if (themeName === "light") return LightTheme;
    return DarkTheme;
  }, [themeName]);

  return (
    <ThemeContext.Provider value={{ themeName, toggleTheme, navbarExpanded, setNavbarExpanded }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useAppThemeContext = () => {
  return useContext(ThemeContext);
};
