import { Box, Chip, useTheme } from "@mui/material";
import { QueryFilter } from "../models/types";
import { breakRichTextDescriptionIn250Chars } from "../utilities/UIHelper";

type QueryFilterControlProps<T> = {
  queryFilters: QueryFilter<T>[];
  onRemoveQueryFilter: (queryFilter: QueryFilter<T>) => void;
};

export const QueryFilterControl = <T,>(props: QueryFilterControlProps<T>) => {
  const { queryFilters, onRemoveQueryFilter } = props;
  const theme = useTheme();

  // Hide the controls if there's no filters
  if (!queryFilters.length) return null;
  return (
    <Box display="flex" flexDirection="row" width="100%" gap="5px" flexWrap="wrap">
      <Chip
        sx={{
          border: "unset",
          color: theme.palette.custom.white,
          backgroundColor: theme.palette.custom.blue,
        }}
        onClick={() => queryFilters.forEach((qf) => onRemoveQueryFilter(qf))}
        label="Remove All"
      />
      {queryFilters.map((qf) => (
        <Chip
          key={`${qf.filterOption?.value}|${qf.value}`}
          label={`${qf.filterOption?.label ?? "General"}/${breakRichTextDescriptionIn250Chars(
            qf.value,
            60
          )}`}
          onDelete={() => onRemoveQueryFilter(qf)}
        />
      ))}
    </Box>
  );
};
