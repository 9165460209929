import { Asset, TestRun, TestRunStatus } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const testRunEndpoints = {
  tests: () => "/test_runs",
  test: (testId: string) => `/test_runs/${testId}`,
};

export const getTestRun = (testId: string) => {
  return httpGetAuthenticated<TestRun>(testRunEndpoints.test(testId));
};

export const getTestRuns = (params: any) => {
  return httpGetAuthenticated<ListResponse<TestRun>>(testRunEndpoints.tests(), {
    params,
  });
};

export const addTestRun = async (payload: {
  name: string;
  description?: string;
  required_test_cases: string[];
}) => {
  const { data } = await httpPostAuthenticated<TestRun>(testRunEndpoints.tests(), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.TestRun] });
  return data;
};

export const editTestRun = async (
  testId: string,
  params: { name: string; description?: string; required_test_cases: string[] }
) => {
  const { data } = await httpPatchAuthenticated(testRunEndpoints.test(testId), params);
  queryClient.invalidateQueries({ queryKey: [QueryKey.TestRun] });
  queryClient.invalidateQueries({ queryKey: [QueryKey.TestCase] });

  return data;
};

export const deleteTestRun = async (testCaseId: string) => {
  const resp = await httpDeleteAuthenticated(testRunEndpoints.test(testCaseId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.TestRun] });
  return resp;
};

export const editTestRunStatus = async (testId: string, params: { status: TestRunStatus }) => {
  const { data } = await httpPatchAuthenticated(testRunEndpoints.test(testId), params);
  queryClient.invalidateQueries({ queryKey: [QueryKey.TestRun] });
  return data;
};
