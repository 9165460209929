import { Box, useTheme } from "@mui/material";
import { NavbarIcon } from "../../atoms/navbar/Icon";
import { Question } from "../../models/types";
import { showPaddingBetweenQuestionAndNavigation } from "../../utilities/UIHelper";
import { QuestionAssignedUser } from "./QuestionAssignedUser";

type QuestionNavigationProps = {
  questionnaireId: string;
  question: Question;
  isSingleQuestion: boolean;
  handleNext: () => void;
  handlePrevious: () => void;
  questionsCount: number;
  currentQuestionIndex: number;
  impactAssessmentId: string;
  loading: boolean;
  isQuestionnaireFinalized: boolean;
  preview?: boolean;
  showAssignUser?: boolean;
};
export const QuestionNavigation = ({
  question,
  loading,
  impactAssessmentId,
  questionnaireId,
  isQuestionnaireFinalized,
  isSingleQuestion,
  handleNext,
  handlePrevious,
  questionsCount,
  currentQuestionIndex,
  preview = false,
  showAssignUser = true,
}: QuestionNavigationProps) => {
  const theme = useTheme();
  const disabledPreviousQuestion = isSingleQuestion || currentQuestionIndex === 0;
  const disabledNextQuestion = isSingleQuestion || currentQuestionIndex === questionsCount - 1;
  const isSubmitStep =
    disabledNextQuestion || (currentQuestionIndex !== 0 && disabledPreviousQuestion);
  return (
    <>
      {!showPaddingBetweenQuestionAndNavigation(question.type) && <Box height="78px"></Box>}
      <Box display="flex" gap="10px" justifyContent="space-between" width="100%">
        <Box display="flex" gap="10px">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "3px",
              padding: "6px",
              backgroundColor: theme.palette.custom.planBackground,
              cursor: disabledPreviousQuestion ? "default" : "pointer",
              opacity: disabledPreviousQuestion ? 0.3 : 1,
            }}
            onClick={() => !disabledPreviousQuestion && handlePrevious()}
          >
            <NavbarIcon
              variant="top-arrow"
              color={theme.palette.custom.hyperlink}
              sx={{
                width: "24px",
                height: "24px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              borderRadius: "3px",
              padding: "6px",
              cursor: disabledNextQuestion ? "default" : "pointer",
              backgroundColor: theme.palette.custom.planBackground,
              opacity: disabledNextQuestion ? 0.3 : 1,
            }}
            onClick={() => !disabledNextQuestion && handleNext()}
          >
            <NavbarIcon
              variant="down-arrow"
              color={theme.palette.custom.hyperlink}
              sx={{
                width: "24px",
                height: "24px",
              }}
            />
          </Box>
        </Box>
        <QuestionAssignedUser
          disabled={isQuestionnaireFinalized}
          handleNext={() => handleNext()}
          loading={loading}
          isSubmitStep={isSubmitStep}
          questionnaireId={questionnaireId}
          impactAssessmentId={impactAssessmentId}
          question={question}
          preview={preview}
          showAssignUser={showAssignUser}
        />
      </Box>
    </>
  );
};
