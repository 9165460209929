import { Skeleton } from "@mui/material";

type CircularLoaderProps = {
  width?: string;
  maxWidth?: string;
  height?: string;
};

export const CircularLoader = (props: CircularLoaderProps) => {
  const { width, maxWidth, height } = props;

  return (
    <Skeleton
      variant="circular"
      width={width}
      height={height}
      sx={{
        maxWidth: maxWidth,
      }}
    />
  );
};
