import { QueryFilter } from "../models/types";

export type Awaitable<T> = T | Promise<T>;
export const sleep = (ms: number) => new Promise((res) => setTimeout(res, ms));
// generates a 5 character random string
export const randomString = () => Math.random().toString(36).slice(2, 7);

export const stripHTMLTags = (html: any) => {
  const doc = new DOMParser().parseFromString(html, "text/html");
  return doc.body.textContent || "";
};

export const stripTrailingSpaces = (text: any) => {
  const str = text.replace(/\s*&nbsp;\s*/gi, " ");
  return str;
};

// Check if 2 query filters are identical
export const isSameQueryFilter = <A, B>(
  filterA: QueryFilter<A>,
  filterB: QueryFilter<B>
): boolean =>
  filterA.value === filterB.value && filterA.filterOption?.value === filterB.filterOption?.value;

export const listJSONSearch =
  <T extends object>(list: T[], keys?: string[]) =>
  (searchText: string) => {
    return list.filter((item) => {
      const x = JSON.stringify(item, keys).toLowerCase();
      return x.includes(searchText.toLowerCase());
    });
  };

export const queryDictFromQueryFilters = <F extends string>(queryFilters: QueryFilter<F>[]) =>
  queryFilters.reduce<Partial<Record<F, string[]>>>((filterParam, queryFilter) => {
    if (queryFilter.filterOption) {
      const field = queryFilter.filterOption.value;
      filterParam[field] = [...(filterParam[field] ?? []), queryFilter.value];
    }
    return filterParam;
  }, {});
