import { WorkflowIndicatorIcon } from "../components/AppIcons";
import { workflowHooks } from "../state/QueryStore";
import { IGetListQueryParams } from "../state/QueryStoreHelper";
import AppStrings from "../strings";
import {
  getIdFromUrl,
  IObjectCommonFields,
  ObjectDetailViewModel,
  ObjectListViewModel,
  parseObjectCommonFields,
} from "./IObjectCommonFields";
import { ObjectCustomField } from "./IObjectCustomField";

class WorkflowDetailViewModel extends ObjectDetailViewModel {
  workflow_num: number;
  created_by: any;
  constructor(rawData: any) {
    // const objectNumber = rawData.workflow_num;
    const objectNumber = rawData.workflow_num;
    super(rawData, objectNumber);
    this.workflow_num = rawData.workflow_num;
    this.created_by = rawData.created_by;
  }

  get objectIndicatorName() {
    return AppStrings.workflow.indicatorLabel;
  }

  get objectIndicatorIcon() {
    return <WorkflowIndicatorIcon />;
  }

  get objectName() {
    return AppStrings.workflow.name;
  }

  get objectHooks() {
    return workflowHooks as any;
  }
}

interface IWorkflow extends IObjectCommonFields {
  id: string;
  workflowNumber: number;
  summary: string;
  description: string;
}

interface IGetWorkflowListRequestBody extends IGetListQueryParams {}

interface ICreateWorkflowRequestBody {
  summary: string;
  description: string;
}

interface IUpdateWorkflowRequestBody {
  summary: string;
  description: string;
}

const parseWorkflowResult = (data: any): IWorkflow => {
  const id = getIdFromUrl(data.url);
  return {
    id,
    summary: data.summary ?? "",
    description: data.description ?? "",
    workflowNumber: Number.parseInt((data.workflow as string).slice(9)),
  };
};

const parseCreateWorkflowResult = (data: any): IWorkflow => {
  return parseGetWorkflowResult(data.id, data);
};

const parseGetWorkflowResult = (id: string, data: any): IWorkflow => {
  return {
    id: id,
    summary: data.summary ?? "",
    description: data.description ?? "",
    workflowNumber: data.workflow_num,
    ...parseObjectCommonFields(data),
  };
};

export type LinkedItem = {
  id: string;
  custom_fields: { [key: string]: any } | null;
  file_attachments: [];
  external_id: string;
  integration_type: "GITHUB" | "JIRA";
  status: string;
  external_created_at: string;
  external_updated_at: string;
  external_closed_at: string;
  created_on: string;
  updated_on: string;
  created_by_webhook: boolean;
  organization: string;
};

export type INewLinkedItem = {
  created_by_webhook: boolean;
  integration_type: string;
  external_id: string;
  custom_fields: {
    event?: string;
    summary: string;
    summaryText: string;
    key?: string;
    number?: number;
    url?: string;
  };
};

export {
  WorkflowDetailViewModel as WorkflowListViewModel,
  WorkflowDetailViewModel,
  parseWorkflowResult,
  parseCreateWorkflowResult,
  parseGetWorkflowResult,
};
export type {
  IWorkflow,
  ICreateWorkflowRequestBody,
  IUpdateWorkflowRequestBody,
  IGetWorkflowListRequestBody,
};
