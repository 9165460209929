import { Box, useTheme } from "@mui/material";
import { PageDetailLayout } from "../../components/layout/PageDetailLayout";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { DetailsLoader } from "../../organisms/skeleton-loaders/DetailsLoader";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { ChildrenBoxLoader } from "../../organisms/skeleton-loaders/ChildrenBoxLoader";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { Policy, User } from "../../models/types";
import { CustomFieldLoader } from "../../organisms/skeleton-loaders/CustomFieldLoader";

export const RoleDetailsLoader = () => {
  const theme = useTheme();

  const userColumnsVisibility: IColumnVisibility<Policy>[] = [
    {
      field: "policy_num",
      headerName: "Policy",
      visible: true,
      columnMaxWidth: 500,
      columnMinWidth: 150,
    },
    {
      field: "description",
      headerName: "Description",
      visible: true,
      columnMaxWidth: 600,
      columnMinWidth: 150,
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
      columnMaxWidth: 250,
      columnMinWidth: 200,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
      columnMaxWidth: 120,
      columnMinWidth: 120,
    },
  ];

  const policyColumnsVisibility: IColumnVisibility<User>[] = [
    {
      field: "name",
      headerName: "Name",
      visible: true,
      columnMinWidth: 300,
    },
    {
      field: "email",
      headerName: "Email",
      visible: true,
      columnMaxWidth: 500,
      columnMinWidth: 300,
    },
    {
      field: "date_joined",
      headerName: "Joined on",
      visible: true,
      columnMaxWidth: 140,
      columnMinWidth: 110,
    },
    {
      field: "permissions",
      headerName: "Permissions",
      visible: true,
      columnMinWidth: 110,
      columnMaxWidth: 150,
    },
    {
      field: "is_active",
      headerName: "Status",
      visible: true,
    },
  ];

  const fillBlankSpace = true;

  return (
    <PageDetailLayout
      leftComponent={
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "20px" }}>
          <SecondaryBox>
            <DetailsLoader />
          </SecondaryBox>
          <SecondaryBox>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <RectangularLoader width="200px" height="34px" />
              <RectangularLoader width="64px" height="34px" />
            </Box>
          </SecondaryBox>
          <Box
            sx={{
              maxWidth: "100%",
              width: "100%",
              height: fillBlankSpace ? "100%" : "unset",
              overflow: "visible",
            }}
          >
            <Box height={fillBlankSpace ? "100%" : "unset"}>
              <Box
                paddingBottom={"0px"}
                borderRadius="6px"
                border="1px solid"
                minHeight="100%"
                maxHeight="100%"
                overflow="auto"
                borderColor={theme.palette.custom.secondaryBorder}
                bgcolor={theme.palette.custom.secondaryBackground}
              >
                <APITableLoader
                  columnsVisibility={userColumnsVisibility}
                  tableSize="small"
                  secondComponent={true}
                  tableSearch={true}
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: "100%",
              width: "100%",
              height: fillBlankSpace ? "100%" : "unset",
              overflow: "visible",
            }}
          >
            <Box height={fillBlankSpace ? "100%" : "unset"}>
              <Box
                paddingBottom={"0px"}
                borderRadius="6px"
                border="1px solid"
                minHeight="100%"
                maxHeight="100%"
                overflow="auto"
                borderColor={theme.palette.custom.secondaryBorder}
                bgcolor={theme.palette.custom.secondaryBackground}
              >
                <APITableLoader
                  columnsVisibility={policyColumnsVisibility}
                  tableSize="small"
                  secondComponent={true}
                  tableSearch={true}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      }
      rightComponent={
        <Box width="100%" display="flex" flexDirection="column" gap="20px">
          <SecondaryBox>
            <Box display="flex" width="100%" flexDirection="column" gap="10px">
              <ChildrenBoxLoader />
            </Box>
          </SecondaryBox>
          <SecondaryBox>
            <Box display="flex" width="100%" flexDirection="column" gap="10px">
              <CustomFieldLoader showTitle={false} />
            </Box>
          </SecondaryBox>
          <SecondaryBox>
            <Box display="flex" width="100%" flexDirection="column" gap="10px">
              <ChildrenBoxLoader />
            </Box>
          </SecondaryBox>
          <SecondaryBox>
            <Box display="flex" width="100%" flexDirection="column" gap="10px">
              <ChildrenBoxLoader />
            </Box>
          </SecondaryBox>
        </Box>
      }
    />
  );
};
