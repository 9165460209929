import { useEffect, useState } from "react";
import { APIGetObjectHook, FileAttachment } from "../models/types";
import Dropzone from "react-dropzone";
import { openSnackbar } from "../context/SnackbarContext";
import { DropZoneIndicator, MAX_FILE_SIZE_MB } from "../components/FileAttachmentForm";
import { addFile } from "../services/FileService";
import { QueryKey } from "../state/QueryStore";
import { useAuth } from "../hooks/useAuth";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { TableTextWithEllipsis } from "../molecules/TableTextWithEllipsis";

type ImageCustomFieldProps = {
  useGetData: APIGetObjectHook<FileAttachment>;
  setObjectAttributes?: (obj: any) => void;
  params?: any;
  required?: boolean;
  value: string;
  onChange: (value: string | null, optionalParams?: any) => void;
  error?: boolean;
  errorMessage?: string;
};

export const ImageCustomField = (props: ImageCustomFieldProps) => {
  const { value, onChange, error = false, setObjectAttributes, useGetData, params } = props;
  const { data, isLoading } = useGetData(value || "", params);
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (data && setObjectAttributes) {
      setObjectAttributes(data ?? {});
    }
  }, [data]);

  const { organization } = useAuth();

  return organization ? (
    <Dropzone
      noClick
      multiple={false}
      onDrop={async (acceptedFiles, fileRejections) => {
        if (fileRejections.length) {
          const errorMessage = fileRejections[0].errors[0].message;
          if (/File is larger than/.test(errorMessage)) {
            // error doesn't display the size in human readable format. Show custom message
            openSnackbar(`File size limit is ${MAX_FILE_SIZE_MB}MB`, "error");
          } else {
            openSnackbar(errorMessage, "error");
          }
        } else if (acceptedFiles.length) {
          const fileToUpload = acceptedFiles[0];
          setLoading(true);
          try {
            const uploadedFile = await addFile(organization.id, fileToUpload, QueryKey.File);
            onChange(uploadedFile.data.id);
          } catch {
            openSnackbar("Failed to upload file", "error");
          } finally {
            setLoading(false);
          }
        }
      }}
      maxSize={MAX_FILE_SIZE_MB * 1024 * 1024}
    >
      {({ getRootProps, getInputProps, isDragAccept, open: openFilePicker }) => (
        <Box {...getRootProps()} position="relative">
          <input {...getInputProps()} />
          {isDragAccept && <DropZoneIndicator />}
          <Stack gap="10px">
            {!value && (
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Box display="flex" flexDirection="row" gap="5px" alignItems="center">
                  <NavbarIcon
                    variant="empty-image"
                    sx={{ width: "34px", height: "34px", minHeight: "34px", minWidth: "34px" }}
                  />
                  <Typography variant="body2">No image uploaded</Typography>
                </Box>
                <LoadingButton
                  variant="contained"
                  loading={loading}
                  onClick={openFilePicker}
                  disabled={loading || isLoading}
                  sx={{
                    width: "100px",
                  }}
                >
                  Upload
                </LoadingButton>
              </Box>
            )}
            {value && (
              <Box
                sx={{ cursor: "pointer" }}
                display="flex"
                flexDirection="row"
                alignItems="center"
                width="100%"
                justifyContent="space-between"
              >
                <Box width="80%" display="flex" flexDirection="row" gap="5px" alignItems="center">
                  <img
                    style={{ maxWidth: "34px", height: "auto", maxHeight: "34px" }}
                    src={data?.file_object}
                    alt="uploaded"
                  />
                  <TableTextWithEllipsis value={data?.filename ?? ""} />
                </Box>
                <Box display="flex" flexDirection="row" gap="10px" alignItems="center">
                  <Box onClick={() => openFilePicker()} sx={{ cursor: "pointer" }}>
                    <NavbarIcon
                      variant="edit-icon"
                      selected={true}
                      sx={{ width: "17px", height: "17px", minHeight: "17px", minWidth: "17px" }}
                    />
                  </Box>
                  <Box onClick={() => onChange(null)} sx={{ cursor: "pointer" }}>
                    <NavbarIcon
                      variant="trash-can"
                      color={theme.palette.custom.redTypography}
                      sx={{ width: "20px", height: "20px", minHeight: "20px", minWidth: "20px" }}
                    />
                  </Box>
                </Box>
              </Box>
            )}
          </Stack>
        </Box>
      )}
    </Dropzone>
  ) : null;
};
