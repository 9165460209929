import { useTheme } from "@mui/material";
import JsonView from "@uiw/react-json-view";
import { isJSON } from "../utilities/UIHelper";

type JsonViewProps = {
  value: string;
  indent?: number;
};

export const JSONView = ({ value, indent = 12 }: JsonViewProps) => {
  const theme = useTheme();
  const valueFormatted = isJSON(value) ? JSON.parse(value) : { error: "Invalid JSON" };
  return (
    <JsonView
      value={valueFormatted}
      indentWidth={indent}
      displayObjectSize={false}
      displayDataTypes={false}
      enableClipboard={false}
      collapsed={1}
      highlightUpdates={false}
      style={
        {
          "--w-rjv-color": theme.palette.custom.primaryTypography,
          "--w-rjv-key-number": theme.palette.custom.primaryTypography,
          "--w-rjv-key-string": theme.palette.custom.primaryTypography,
          "--w-rjv-line-color": theme.palette.custom.primaryTypography,
          "--w-rjv-arrow-color": theme.palette.custom.primaryTypography,
          "--w-rjv-edit-color": theme.palette.custom.primaryTypography,
          "--w-rjv-info-color": theme.palette.custom.primaryTypography,
          "--w-rjv-update-color": theme.palette.custom.primaryTypography,
          "--w-rjv-copied-color": theme.palette.custom.primaryTypography,
          "--w-rjv-copied-success-color": theme.palette.custom.primaryTypography,
          "--w-rjv-curlybraces-color": theme.palette.custom.primaryTypography,
          "--w-rjv-colon-color": theme.palette.custom.primaryTypography,
          "--w-rjv-brackets-color": theme.palette.custom.primaryTypography,
          "--w-rjv-ellipsis-color": theme.palette.custom.primaryTypography,
          "--w-rjv-quotes-color": theme.palette.custom.primaryTypography,
          "--w-rjv-quotes-string-color": theme.palette.custom.primaryTypography,
          "--w-rjv-type-string-color": theme.palette.custom.greenTypography,
          "--w-rjv-type-int-color": theme.palette.custom.greenTypography,
          "--w-rjv-type-float-color": theme.palette.custom.greenTypography,
          "--w-rjv-type-bigint-color": theme.palette.custom.greenTypography,
          "--w-rjv-type-boolean-color": theme.palette.custom.greenTypography,
          "--w-rjv-type-date-color": theme.palette.custom.greenTypography,
          "--w-rjv-type-url-color": theme.palette.custom.greenTypography,
          "--w-rjv-type-null-color": theme.palette.custom.greenTypography,
          "--w-rjv-type-nan-color": theme.palette.custom.greenTypography,
          "--w-rjv-type-undefined-color": theme.palette.custom.greenTypography,
        } as React.CSSProperties
      }
    />
  );
};
