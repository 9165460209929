import { Box, Button, Typography, useTheme } from "@mui/material";
import { StringCustomField, UserCustomField } from "../../components/CustomFieldForm";
import { Table } from "../../components/Table";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { useState } from "react";
// import { IAStakeHolderCustomFieldModal } from "../modals/custom-fields/IAStakeHolderCustomFieldModal";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { openSnackbar } from "../../context/SnackbarContext";
import { IAStakeholder } from "../../models/types";
import { IAStakeHolderCustomFieldModal } from "../modals/custom-fields/IAStakeHolderFieldModal";
import { useUsers } from "../../services/UserSettingsService";
import { searchUsers } from "../../hooks/useUsers";
import UserTag from "../../components/UserTag";

type IAStakeHolderListFieldProps = {
  value: string[] | null;
  onChange: (newValue: string[] | null) => void;
  required?: boolean;
  valueError: boolean;
  errorMessage: string;
  showAddButton: boolean;
  modalTitle: string;
};

/**
 *
 * @param IAStakeHolderListFieldProps
 * This for will handle the name and description fields outputing value as a stringified JSON object
 * with the keys name and description
 * This was designed to allow users create intended users that will be stored as question answers
 * But the component can be reused where ever it is needed
 * @returns
 */

type IAStakeHolderObj = {
  id: string;
} & IAStakeholder;

export const IAStakeHolderListField = (props: IAStakeHolderListFieldProps) => {
  const { value, onChange, showAddButton, modalTitle } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedForEdit, setSelectedForEdit] = useState<IAStakeHolderObj | null>(null);
  const theme = useTheme();
  const columnVisibility: IColumnVisibility<IAStakeHolderObj>[] = [
    {
      field: "user",
      headerName: "User",
      visible: true,
      renderer: (row) => (
        <Box>
          <UserTag
            onClick={() => {
              setSelectedForEdit(row);
              setModalOpen(true);
            }}
            userId={row?.user}
            name={row?.user}
          />
        </Box>
      ),
    },
    {
      field: "benefits",
      headerName: "Potential Benefits",
      visible: true,
      renderer: (row) => <TableTextWithEllipsis value={row?.benefits?.toString()} />,
    },
    {
      field: "harms",
      headerName: "Potential Harms",
      visible: true,
      renderer: (row) => <TableTextWithEllipsis value={row?.harms?.toString()} />,
    },
  ];

  const onAdd = (newValue: string) => {
    try {
      const isUpdate = selectedForEdit !== null;
      if (isUpdate) {
        const newValueJson = [...(value || [])];
        newValueJson[parseInt(selectedForEdit.id)] = newValue;
        onChange(newValueJson);
      } else {
        const newValueJson = [...(value || []), newValue];
        onChange(newValueJson);
      }
    } catch (error) {
      openSnackbar("Failed to set name and description", "error");
    } finally {
      setSelectedForEdit(null);
    }
  };

  const rows = value?.map((item, index) => {
    const parsedItem = JSON.parse(item);
    return {
      id: index.toString(),
      user: parsedItem?.user,
      benefits: parsedItem?.benefits,
      harms: parsedItem.harms,
    };
  });

  return (
    <Box display="flex" gap="10px" width="100%" maxWidth="100%" flexDirection="column">
      {showAddButton && (
        <Box width="100%" display="flex" justifyContent="flex-end">
          <Button onClick={() => setModalOpen(true)} variant="contained">
            Add
          </Button>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth="100%"
        borderRadius="6px"
        border="1px solid"
        borderTop={0}
        overflow="auto"
        borderColor={theme.palette.custom.secondaryBorder}
      >
        <Table
          borderTopRadius="6px"
          smallEmptyHeight={true}
          columnsVisibility={columnVisibility}
          isLoading={false}
          rows={rows || []}
          emptyTableComponent={<></>}
        />
      </Box>
      <IAStakeHolderCustomFieldModal
        selectedForEdit={selectedForEdit}
        open={modalOpen}
        onClose={() => {
          setSelectedForEdit(null);
          setModalOpen(false);
        }}
        onAdd={onAdd}
        title={modalTitle}
      />
    </Box>
  );
};

type IAStakeHolderFieldProps = {
  value: string | null;
  onChange: (newValue: string | null) => void;
  required?: boolean;
  valueError: boolean;
  errorMessage: string;
};

export const IAStakeHolderField = (props: IAStakeHolderFieldProps) => {
  const { value, onChange, required, valueError, errorMessage } = props;
  const theme = useTheme();

  const handleChangeUser = (newValue: string) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, user: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  const handleChangeBenefits = (newValue: string) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, benefits: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  const handleChangeHarms = (newValue: string) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, harms: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  const getValue = (key: string) => {
    const valueJson = JSON.parse(value || "{}");
    return valueJson[key];
  };

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        User
      </Typography>
      <UserCustomField
        useUser={useUsers}
        searchUsers={searchUsers}
        value={getValue("user")}
        onChange={handleChangeUser}
        error={false}
        errorMessage=""
      />
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        Benefits
      </Typography>
      <StringCustomField
        required={required}
        rows={4}
        value={getValue("benefits")}
        onChange={handleChangeBenefits}
        error={valueError}
        errorMessage={errorMessage}
      />
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        Harms
      </Typography>
      <StringCustomField
        rows={4}
        required={required}
        value={getValue("harms")}
        onChange={handleChangeHarms}
        error={valueError}
        errorMessage={errorMessage}
      />
    </Box>
  );
};
