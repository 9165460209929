import { StatusBadge } from "../../atoms/StatusBadge";
import UserTag from "../../components/UserTag";
import { BaseFilterOptions, BaseFilters, FilterOption, Workflow } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { defaultDateDisplay } from "../UIHelper";

export const workflowListFilterOptions: FilterOption<BaseFilters>[] = [
  { label: "Workflow", value: "workflow_num" },
  { label: "Summary", value: "summary" },
  { label: "Description", value: "description" },
  ...BaseFilterOptions,
];

export const workflowListcolumnsVisibility: IColumnVisibility<Workflow>[] = [
  {
    field: "summary",
    headerName: "Name",
    visible: true,
    columnMinWidth: 400,
    renderer: (workflow) => <TableTextWithEllipsis value={workflow.summary} />,
  },
  {
    field: "template_name",
    headerName: "Template Name",
    visible: true,
    columnMinWidth: 200,
    columnMaxWidth: 400,
    renderer: (workflow) => <TableTextWithEllipsis value={workflow.template_name?.name ?? ""} />,
  },
  {
    field: "last_run",
    headerName: "Last Executed",
    visible: true,
    columnMaxWidth: 120,
    columnMinWidth: 120,
    renderer: (workflow) => defaultDateDisplay(workflow.last_run?.updated_on ?? ""),
  },
  {
    field: "status",
    headerName: "Last Status",
    visible: true,
    columnMaxWidth: 150,
    columnMinWidth: 150,
    renderer: (workflow) => <StatusBadge status={workflow.last_run?.status as any} />,
  },
  {
    field: "updated_on",
    headerName: "Last Updated",
    visible: true,
    columnMaxWidth: 120,
    columnMinWidth: 120,
    renderer: (workflow) => defaultDateDisplay(workflow.updated_on),
  },
  {
    field: "assignee",
    headerName: "Owner",
    visible: true,
    columnMaxWidth: 150,
    columnMinWidth: 150,
    renderer: (workflow) => <UserTag userId={workflow.assignee} />,
  },
];
