import { Reference, ReferenceData, ReferenceType } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const referenceEndpoints = {
  references: () => "/references",
  reference: (referenceId: string) => `/references/${referenceId}`,
};

export const getReferences = (params: any) => {
  return httpGetAuthenticated<ListResponse<Reference>>(referenceEndpoints.references(), {
    params,
  });
};

export const getReference = (referenceId: string) => {
  return httpGetAuthenticated<Reference>(referenceEndpoints.reference(referenceId));
};

export const editReference = async (
  referenceId: string,
  payload: { title?: string; year?: number; reference_type?: ReferenceType; data?: ReferenceData }
) => {
  const { data } = await httpPatchAuthenticated(referenceEndpoints.reference(referenceId), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Reference, referenceId] });
  return data;
};

export const addReference = async (payload: {
  title?: string;
  year?: number;
  reference_type: ReferenceType;
  data: ReferenceData;
}) => {
  const { data } = await httpPostAuthenticated<Reference>(referenceEndpoints.references(), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Reference] });
  return data;
};

export const deleteReference = async (referenceId: string) => {
  const resp = await httpDeleteAuthenticated(referenceEndpoints.reference(referenceId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Reference, referenceId] });
  return resp;
};
