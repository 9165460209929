import {
  Typography,
  Button,
  Grid,
  Box,
  CircularProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";

interface IOrganizationContainer {
  content: React.ReactElement;
  fullWidthContent?: boolean;
  minHeight?: string;
}

export const OrganizationContainer = ({
  content,
  fullWidthContent,
  minHeight,
}: IOrganizationContainer) => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid
      container
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        xs={12}
        lg={fullWidthContent ? 12 : 6}
        xl={fullWidthContent ? 12 : 6}
        md={12}
        sx={{
          width: "100%",
          minHeight: minHeight ? "50vh" : isSmScreen ? "50vh" : "70vh",
          overflowY: "auto",
        }}
      >
        {content}
      </Grid>
    </Grid>
  );
};

export default OrganizationContainer;
