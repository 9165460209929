import { Box, Menu, MenuItem, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { IconButton } from "../atoms/IconButton";
import { NavbarIcon } from "../atoms/navbar/Icon";

type RunHistoryThreeDotsMenuProps = {
  onReRun: () => void;
};

export const RunHistoryThreeDotsMenu = (props: RunHistoryThreeDotsMenuProps) => {
  const { onReRun } = props;
  const theme = useTheme();
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setMenuVisible(!isMenuVisible);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuVisible(!isMenuVisible);
  };

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <IconButton
        variant="three-dots-vertical"
        width="4px"
        height="16px"
        color="none"
        onClick={(e) => {
          toggleMenu(e);
        }}
      />
      <Menu
        elevation={0}
        id="three-dots-menu"
        MenuListProps={{
          "aria-labelledby": "three-dots-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          gap: "5px",
          "& .MuiPaper-root": {
            "& .MuiMenu-list": {
              padding: "4px",
              width: "192px",
            },
            "& .MuiMenuItem-root": {
              padding: "4px",
              "&:hover": {
                backgroundColor: theme.palette.custom.planBackground,
              },
            },
          },
        }}
      >
        <MenuItem>
          <Box
            display="flex"
            justifyContent="space-between"
            width="100%"
            alignItems="center"
            gap="5px"
            onClick={(e) => {
              onReRun();
              setAnchorEl(null);
            }}
          >
            <Typography variant="body2">Deactivate</Typography>
            <NavbarIcon
              variant="hide"
              selected
              sx={{
                width: "18px",
                height: "18px",
                minHeight: "18px",
                minWidth: "18px",
              }}
            />
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};
