import { DefaultBadge, StatusBadge } from "../../atoms/StatusBadge";
import {
  BaseFilterOptions,
  BaseFilters,
  EvidenceTestInScopeResource,
  FilterOption,
} from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import {
  deactivateEivdenceTestInScopeResource,
  evaluateEvidenceTestInScopeResource,
} from "../../services/EvidenceTestInScopeResourceService";
import { defaultDateDisplay } from "../../utilities/UIHelper";
import { EvidenceInScopeResourcesThreeDotsMenu } from "../registry/details/compliance/EvidenceInScopeResourcesThreeDots";

export const evidenceTestInScopeResourcesListFilterOptions: FilterOption<BaseFilters>[] = [
  { label: "Name", value: "name" },
  ...BaseFilterOptions,
];

// Required fields: Owner, Teams, Name, Compliance Progress, Needs Attention, Overall risk, Potential Value, Lifecycle stage. (Use New table)

export const evidenceTestInScopeResourcesListColumnsVisibility = ({
  onSelectResource,
  onExclude,
}: {
  onSelectResource: (resource: EvidenceTestInScopeResource) => void;
  onExclude: (resource: EvidenceTestInScopeResource) => void;
}): IColumnVisibility<EvidenceTestInScopeResource>[] => [
  {
    field: "name",
    headerName: "Name",
    visible: true,
    columnMinWidth: 280,
    renderer: (evidence) => <TableTextWithEllipsis value={evidence.name} />,
  },
  {
    field: "resource_type",
    headerName: "Type",
    visible: true,
    columnMinWidth: 150,
    columnMaxWidth: 300,
    renderer: (evidence) => <DefaultBadge value={evidence.resource_type_name} />,
  },
  {
    field: "status",
    headerName: "Status",
    visible: true,
    columnMinWidth: 150,
    columnMaxWidth: 300,
    renderer: (evidence) => <StatusBadge status={evidence.status} />,
  },
  {
    field: "metadata",
    headerName: "SLA Date",
    visible: true,
    columnMinWidth: 150,
    columnMaxWidth: 300,
    renderer: (evidence) =>
      evidence.metadata?.sla_date ? defaultDateDisplay(evidence.metadata?.sla_date) : "",
  },
  {
    field: "updated_by",
    headerName: "",
    visible: true,
    columnMinWidth: 50,
    columnMaxWidth: 50,
    renderer: (evidence) => {
      return (
        <EvidenceInScopeResourcesThreeDotsMenu
          resource={evidence}
          onActivate={() =>
            deactivateEivdenceTestInScopeResource(evidence.id, {
              status: "PENDING",
            })
          }
          onEvaluate={() => evaluateEvidenceTestInScopeResource(evidence.id)}
          onOpenHistory={() => onSelectResource(evidence)}
          onExclude={() => onExclude(evidence)}
        />
      );
    },
  },
];
