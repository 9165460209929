import { Box, Button, Typography, useTheme } from "@mui/material";
import { StringCustomField } from "../../components/CustomFieldForm";
import { Table } from "../../components/Table";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { useState } from "react";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { openSnackbar } from "../../context/SnackbarContext";
import DeleteIconButton from "../../components/DeleteIconButton";
import { EmptyTable } from "../../molecules/EmptyTable";
import { ExternalLinkCustomFieldModal } from "../modals/custom-fields/ExternalLinkCustomFieldModal copy";

type ExternalLinkFieldProps = {
  value: string | null;
  onChange: (newValue: string | null) => void;
  required?: boolean;
  valueError: boolean;
  errorMessage: string;
};

type ExternalLinkListFieldProps = {
  value: string[] | null;
  onChange: (newValue: string[] | null) => void;
  required?: boolean;
  valueError: boolean;
  errorMessage: string;
  showAddButton: boolean;
  modalTitle: string;
  tableDescription?: React.ReactNode;
};

export const ExternalLinkField = (props: ExternalLinkFieldProps) => {
  const { value, onChange, required, valueError, errorMessage } = props;
  const theme = useTheme();

  const handleChangeLink = (newValue: string) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, link: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  const handleChangeDescription = (newValue: string) => {
    const oldValue = JSON.parse(value || "{}");
    const newValueJson = { ...oldValue, description: newValue };
    onChange(JSON.stringify(newValueJson));
  };

  const getValue = (key: string) => {
    const valueJson = JSON.parse(value || "{}");
    return valueJson[key];
  };

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        Description
      </Typography>
      <StringCustomField
        required={required}
        label="Description"
        value={getValue("description")}
        onChange={handleChangeDescription}
        error={valueError}
        errorMessage={errorMessage}
      />
      <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
        Link
      </Typography>
      <StringCustomField
        rows={1}
        label="Link"
        required={required}
        value={getValue("link")}
        onChange={handleChangeLink}
        error={valueError}
        errorMessage={errorMessage}
      />
    </Box>
  );
};

type ExternalLinkObj = {
  id: string;
  description: string;
  link: string;
};
export const ExternalLinkListField = (props: ExternalLinkListFieldProps) => {
  const { value, onChange, showAddButton, modalTitle, tableDescription } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedForEdit, setSelectedForEdit] = useState<ExternalLinkObj | null>(null);
  const theme = useTheme();
  const columnVisibility: IColumnVisibility<ExternalLinkObj>[] = [
    {
      field: "description",
      headerName: "Description",
      visible: true,
      renderer: (row) => (
        <TableTextWithEllipsis
          onClick={() => {
            setSelectedForEdit(row);
            setModalOpen(true);
          }}
          value={row.description}
        />
      ),
    },
    {
      field: "link",
      headerName: "Link",
      visible: true,
      renderer: (row) => (
        <TableTextWithEllipsis
          onClick={() => {
            window.open(row.link, "_blank");
          }}
          value={row.link}
        />
      ),
    },
    {
      field: "id",
      headerName: "",
      visible: true,
      renderer: (row) => (
        <Box display="flex" justifyContent="center">
          <DeleteIconButton
            deleteConfirmationDialogTitle="Remove option"
            deleteConfirmationButtonDescription="Remove"
            deleteConfirmationDialogDescription={`Are you sure you want to remove ${row.description}?`}
            onDeleteConfirm={() => {
              const newValueJson = [...(value || [])];
              newValueJson.splice(parseInt(row.id), 1);
              onChange(newValueJson);
            }}
            filledButton={false}
          />
        </Box>
      ),
    },
  ];

  const onAdd = (newValue: string) => {
    try {
      const isUpdate = selectedForEdit !== null;
      if (isUpdate) {
        const newValueJson = [...(value || [])];
        newValueJson[parseInt(selectedForEdit.id)] = newValue;
        onChange(newValueJson);
      } else {
        const newValueJson = [...(value || []), newValue];
        onChange(newValueJson);
      }
    } catch (error) {
      openSnackbar("Failed to set link and description", "error");
    } finally {
      setSelectedForEdit(null);
    }
  };

  const rows = value?.map((item, index) => {
    const parsedItem = JSON.parse(item);
    return {
      id: index.toString(),
      description: parsedItem?.description,
      link: parsedItem?.link,
    };
  });

  return (
    <Box display="flex" gap="10px" width="100%" maxWidth="100%" flexDirection="column">
      {showAddButton && (
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          gap="10px"
          justifyContent="flex-end"
        >
          {tableDescription}
          <Button onClick={() => setModalOpen(true)} variant="contained">
            Add
          </Button>
        </Box>
      )}
      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth="100%"
        borderRadius="6px"
        border="1px solid"
        borderTop={0}
        overflow="auto"
        borderColor={theme.palette.custom.secondaryBorder}
      >
        <Table
          borderTopRadius="6px"
          smallEmptyHeight={true}
          columnsVisibility={columnVisibility}
          isLoading={false}
          rows={rows || []}
          emptyTableComponent={
            <EmptyTable
              variant="empty-box"
              label="No Options"
              description="Add an option"
              action={() => {
                setModalOpen(true);
              }}
              actionLabel="Add"
            />
          }
        />
      </Box>
      <ExternalLinkCustomFieldModal
        selectedForEdit={selectedForEdit}
        open={modalOpen}
        onClose={() => {
          setSelectedForEdit(null);
          setModalOpen(false);
        }}
        onAdd={onAdd}
        title={modalTitle}
      />
    </Box>
  );
};
