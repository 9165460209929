import { useEffect, useState } from "react";

// Adapted from https://usehooks-ts.com/react-hook/use-debounce
// Returns the debounced value along with boolean indicating whether an update is pending
export const useDebounce = <T,>(value: T, delay?: number): [T, boolean] => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const [isPending, setIsPending] = useState<boolean>(false);

  useEffect(() => {
    setIsPending(true);
    const timer = setTimeout(() => {
      setDebouncedValue(value);
      setIsPending(false);
    }, delay ?? 500);

    return () => clearTimeout(timer);
  }, [value, delay]);

  return [debouncedValue, isPending];
};
