import { Box } from "@mui/material";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { DetailsLoader } from "../../organisms/skeleton-loaders/DetailsLoader";
import { ConceptMapping } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { ListViewLoader } from "./ListViewLoader";

export const ResourceDetailsLoader = () => {
  const conceptMappingColumnVisibility: IColumnVisibility<ConceptMapping>[] = [
    {
      field: "concept",
      headerName: "Concept",
      visible: true,
    },
    {
      field: "fairo_concept",
      headerName: "Fairo Concept",
      visible: true,
    },
    {
      field: "representation",
      headerName: "Representation",
      visible: true,
    },
    {
      field: "fairo_representation",
      headerName: "Fairo Representation",
      visible: true,
    },
    {
      field: "notes",
      headerName: "Notes",
      visible: true,
    },
  ];
  return (
    <Box display="flex" flexDirection="column" gap="15px">
      <SecondaryBox>
        <DetailsLoader />
      </SecondaryBox>
      <ListViewLoader
        columnsVisibility={conceptMappingColumnVisibility}
        tableSize="small"
        paddingBottom={false}
      />
    </Box>
  );
};
