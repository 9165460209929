import { useQuery } from "react-query";
import { RatingLevelOptionFetchOptions } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { getRatingLevelOption, getRatingLevelOptions } from "../services/RatingLevelOptionService";

export const useRatingLevelOptions = (params: RatingLevelOptionFetchOptions) => {
  return useQuery([QueryKey.RatingLevelOptions, params], async () => {
    const { data } = await getRatingLevelOptions(params);
    return data;
  });
};

export const useRatingLevelOption = (optionId: string) => {
  return useQuery([QueryKey.RatingLevelOptions, optionId], async () => {
    const { data } = await getRatingLevelOption(optionId);
    return data;
  });
};

export const fetchRatingLevelOptions = (params: RatingLevelOptionFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.RatingLevelOptions, params], async () => {
    const { data } = await getRatingLevelOptions(params);
    return data;
  });
};

export const searchRatingLevelOptions = async (searchText: string) => {
  const items = await fetchRatingLevelOptions({ search: searchText });
  return items.results;
};
