import { useQuery } from "react-query";
import {
  PublicQuestionnaireFetchOptions,
  QuestionnaireFetchOptions,
  QuestionnaireSectionFetchOptions,
} from "../models/types";
import { getPublicQuestionnaire } from "../services/PublicQuestionnaireService";
import {
  getQuestionnaire,
  getQuestionnaireResponses,
  getQuestionnaires,
  getQuestionnaireSection,
  getQuestionnaireSections,
  getTypeformForms,
} from "../services/QuestionnaireService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useQuestionnaires = (params: QuestionnaireFetchOptions) => {
  return useQuery([QueryKey.Questionnaire, params], async () => {
    const { data } = await getQuestionnaires(params);
    return data;
  });
};

export const fetchQuestionnaires = (params: any) => {
  return queryClient.fetchQuery([QueryKey.Questionnaire, params], async () => {
    const { data } = await getQuestionnaires(params);
    return data;
  });
};

export const searchQuestionnaires = async (searchText: string) => {
  const questionnaires = await fetchQuestionnaires({
    search: searchText,
  });
  return questionnaires.results;
};

export const searchQuestionnairesWithParams =
  (params: QuestionnaireFetchOptions) => async (searchText: string) => {
    const questionnaires = await fetchQuestionnaires({ search: searchText, ...params });
    return questionnaires.results;
  };

export const useQuestionnaire = (id: string) => {
  return useQuery([QueryKey.Questionnaire, id], async () => {
    try {
      if (!id) return;
      const { data } = await getQuestionnaire(id);
      return data;
    } catch (error) {
      return;
    }
  });
};

export const fetchQuestionnaire = (id: string) => {
  return queryClient.fetchQuery([QueryKey.Questionnaire, id], async () => {
    const { data } = await getQuestionnaire(id);
    return data;
  });
};

export const fetchTypeformForms = (params: any) => {
  return queryClient.fetchQuery([QueryKey.TypeformForm, params], async () => {
    const data = await getTypeformForms(params);
    return data;
  });
};

export const searchTypeformForms = async (searchText: string) => {
  const forms = await fetchTypeformForms({
    search: searchText,
  });
  return forms.items;
};

export const useQuestionnaireResponses = (id: string) => {
  return useQuery([QueryKey.QuestionnaireResponses, id], async () => {
    const { data } = await getQuestionnaireResponses(id);
    return data.responses;
  });
};

export const useQuestionnaireSections = (params: QuestionnaireSectionFetchOptions) => {
  return useQuery([QueryKey.QuestionnaireSection, params], async () => {
    const { data } = await getQuestionnaireSections(params);
    return data;
  });
};

export const useQuestionnaireSection = (id: string) => {
  return useQuery([QueryKey.QuestionnaireSection, id], async () => {
    const { data } = await getQuestionnaireSection(id);
    return data;
  });
};

export const usePublicQuestionnaire = (id: string, params: PublicQuestionnaireFetchOptions) => {
  return useQuery([QueryKey.PublicQuestionnaire, id], async () => {
    try {
      if (!id) return;
      const { data } = await getPublicQuestionnaire(id, params);
      if (data.session_id) {
        // Store session ID in local storage
        localStorage.setItem("questionnaire_session_id", data.session_id);
      }
      return data;
    } catch (error) {
      return;
    }
  });
};
