import { LoadingButton } from "@mui/lab";
import { IconButton, useTheme } from "@mui/material";
import { NavbarIcon } from "../../../atoms/navbar/Icon";

type DisconnectButtonProps = {
  onDisconnect: () => void;
};

const DisconnectButton = ({ onDisconnect }: DisconnectButtonProps) => {
  const theme = useTheme();
  return (
    <LoadingButton
      sx={{
        color: theme.palette.custom.redTypography,
      }}
      onClick={() => {
        onDisconnect();
      }}
    >
      Disconnect
    </LoadingButton>
  );
};

type EditButtonProps = {
  onEdit: () => void;
  size?: "small" | "large";
};

const EditButton = ({ onEdit, size = "large" }: EditButtonProps) => {
  const sizeMap = {
    small: "14px",
    large: "18px",
  };
  return (
    <IconButton onClick={() => onEdit()}>
      <NavbarIcon
        variant="edit-icon"
        selected={true}
        sx={{
          width: sizeMap[size],
          height: sizeMap[size],
          minHeight: sizeMap[size],
          minWidth: sizeMap[size],
        }}
      />
    </IconButton>
  );
};

export { DisconnectButton, EditButton };
