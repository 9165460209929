import { Box, useTheme } from "@mui/material";
import { DEFAULT_INTERNAL_BORDER_RADIUS } from "../utilities/UIHelper";
import FairoLogo from "../atoms/navbar/Logo";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { useNavigate } from "react-router-dom";

interface IMobileNavbar {
  expanded: boolean;
  onExpand: () => void;
}

const MobileNavbar = ({ expanded, onExpand }: IMobileNavbar) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  return (
    <Box
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        backgroundColor: "background.paper",
        zIndex: 9999,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow: "5px 0 5px -2px rgba(0,0,0,0.5)",
          borderTopRightRadius: DEFAULT_INTERNAL_BORDER_RADIUS,
          borderTopLeftRadius: DEFAULT_INTERNAL_BORDER_RADIUS,
          height: "60px",
          width: "100vw",
          padding: "6px 20px",
          zIndex: 9999,
        }}
      >
        <FairoLogo width="25%" labelVisible={true} />
        <Box
          sx={{
            display: "flex",
            height: "100%",
            alignItems: "center",
          }}
          gap="15px"
        >
          <Box
            sx={{ cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }}
            onClick={() => navigate("/user/settings")}
          >
            <NavbarIcon sx={{ width: "24px", height: "24px" }} color="none" variant="user" />
          </Box>
          <Box
            sx={{ cursor: "pointer", height: "100%", display: "flex", alignItems: "center" }}
            onClick={() => onExpand()}
          >
            <NavbarIcon variant="menu" sx={{ width: "26px", height: "26px" }} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MobileNavbar;
