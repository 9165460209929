import { LoadingButton } from "@mui/lab";
import { Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { Awaitable } from "../utilities/common";
import { SearchableDropdown } from "./SearchableDropdown";
import { SmallModal } from "./SmallModal";
import { CustomAxiosError } from "../utilities/ErrorResponseHelper";
import { GenericListCustomField } from "../organisms/GenericCustomField";
import { APIFetchOptions, APIGetListHook } from "../models/types";

type AddObjectModalProps<T extends { id: string }> = {
  open: boolean;
  onClose: () => void;
  onAdd: (objectId: string) => Awaitable<void>;
  searchObjects: (searchText: string) => Awaitable<T[]>;
  objectName: string;
  getSummaryDescription: (obj: T) => [string, string];
};
type AddObjectListModalProps<T extends { id: string }, P extends APIFetchOptions> = {
  open: boolean;
  onClose: () => void;
  onAdd: (objectId: string[]) => Awaitable<void>;
  searchObjects: (searchText: string) => Awaitable<T[]>;
  useGetData: APIGetListHook<T, P>;
  objectName: string;
  getOptionName?: (object: T) => string;
  getOptionDescription?: (object: T) => string;
  nameOptionKey: keyof T;
  descriptionOptionKey: keyof T;
};

export const AddObjectModal = <T extends { id: string }>(props: AddObjectModalProps<T>) => {
  const { open, onClose, onAdd, searchObjects, objectName, getSummaryDescription } = props;

  const [object, setObject] = useState<T | null>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();
  const innerTextColor = theme.palette.text.secondary;

  useEffect(() => {
    setObject(null);
  }, [open]);

  const handleCreate = async () => {
    if (object == null) {
      return;
    }

    setLoading(true);
    try {
      await onAdd(object.id);
      onClose();
    } catch (error) {
      if (error instanceof CustomAxiosError) {
        error.showAuditLogSnackbar(`Failed to add ${objectName}`);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <SmallModal open={open} onClose={onClose} title={`Add ${objectName}`}>
      <Stack gap="10px">
        <Typography variant="h3" color={innerTextColor}>
          {objectName}
        </Typography>

        <SearchableDropdown<T>
          value={object}
          onChange={setObject}
          getOptions={searchObjects}
          isOptionEqualToValue={(a, b) => a.id === b.id}
          getSummaryDescription={getSummaryDescription}
        />

        <LoadingButton
          variant="contained"
          onClick={handleCreate}
          disabled={object == null}
          loading={loading}
        >
          Add
        </LoadingButton>
      </Stack>
    </SmallModal>
  );
};

export const AddMultipleObjectModal = <T extends { id: string }, P extends APIFetchOptions>(
  props: AddObjectListModalProps<T, P>
) => {
  const {
    open,
    onClose,
    onAdd,
    searchObjects,
    objectName,
    getOptionDescription,
    getOptionName,
    nameOptionKey,
    descriptionOptionKey,
    useGetData,
  } = props;

  const [object, setObject] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();
  const innerTextColor = theme.palette.text.secondary;

  useEffect(() => {
    setObject([]);
  }, [open]);

  const handleCreate = async () => {
    if (object == null) {
      return;
    }
    setLoading(true);
    try {
      await onAdd(object);
      onClose();
    } catch (error) {
      if (error instanceof CustomAxiosError) {
        error.showAuditLogSnackbar(`Failed to add ${objectName}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleOnChange = (value: any | null, optionalParams?: any) => {
    setObject(value);
  };

  return (
    <SmallModal open={open} onClose={onClose} title={`Add ${objectName}`}>
      <Stack gap="10px">
        <Typography variant="h3" color={innerTextColor}>
          {objectName}
        </Typography>

        <GenericListCustomField
          useGetData={useGetData}
          descriptionKey={descriptionOptionKey}
          nameKey={nameOptionKey}
          getName={getOptionName}
          required={false}
          value={object}
          onChange={handleOnChange}
          error={false}
          errorMessage=""
          search={searchObjects}
          label={objectName}
          params={{ fairo_data: true } as unknown as P}
        />

        <LoadingButton
          variant="contained"
          onClick={handleCreate}
          disabled={object == null}
          loading={loading}
        >
          Add
        </LoadingButton>
      </Stack>
    </SmallModal>
  );
};
