import {
  AssignmentTurnedInRounded as TaskIcon,
  SchemaRounded as WorkflowIcon,
  CheckBox as ActionIcon,
  ManageAccounts as RoleIcon,
  ScienceRounded as TestIcon,
  CodeRounded as AlgorithmIcon,
  StorageRounded as DatasetIcon,
} from "@mui/icons-material";

type AppIconProps = {
  size?: number;
  color?: string;
  marginRight?: number;
  marginLeft?: number;
  marginBottom?: number;
  marginTop?: number;
};

type IconSvgProps = {
  iconProps: AppIconProps;
  src: string;
  alt: string;
};

type IconBackgroundProps = {
  size?: number;
  marginRight?: number;
  marginLeft?: number;
  marginBottom?: number;
  marginTop?: number;
  padding?: number;
  child: React.ReactNode;
};

const DEFAULT_ICON_SIZE = 25;
const DEFAULT_BACKGROUND_PADDING = 10;

const IconBackground = ({
  size,
  marginRight,
  marginLeft,
  marginBottom,
  marginTop,
  padding,
  child,
}: IconBackgroundProps) => {
  const dimension = (size ?? DEFAULT_ICON_SIZE) - 5;
  return (
    <div
      style={{
        height: dimension,
        width: dimension,
        padding: padding ? padding : DEFAULT_BACKGROUND_PADDING,
        background: "#0191FD",
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: marginRight,
        marginLeft: marginLeft,
        marginBottom: marginBottom,
        marginTop: marginTop,
      }}
    >
      {child}
    </div>
  );
};

const IconSvgWithBackground = (props: IconSvgProps) => {
  return (
    <img
      alt={props.alt}
      src={props.src}
      height={props.iconProps.size ?? DEFAULT_ICON_SIZE}
      style={{
        marginRight: props.iconProps.marginRight,
        marginLeft: props.iconProps.marginLeft,
        marginBottom: props.iconProps.marginBottom,
        marginTop: props.iconProps.marginTop,
        color: props.iconProps.color,
      }}
    />
  );
};

const WorkflowIndicatorIcon = (props: AppIconProps) => {
  return (
    <IconSvgWithBackground
      iconProps={props}
      alt="Role"
      src="/assets/images/icons/workflow-blue.svg"
    />
  );
};

const ProcessGraphIcon = (props: AppIconProps) => {
  return (
    <IconBackground
      size={props.size}
      marginBottom={props.marginBottom}
      marginRight={props.marginRight}
      marginLeft={props.marginLeft}
      marginTop={props.marginTop}
      child={<img alt="Process" height={14} src="/assets/images/process-white.svg" />}
    />
  );
};

const ActionIndicatorIcon = (props: AppIconProps) => {
  return (
    <IconSvgWithBackground
      iconProps={props}
      alt="Action"
      src="/assets/images/icons/action-blue.svg"
    />
  );
};

const RoleIndicatorIcon = (props: AppIconProps) => {
  return (
    <IconSvgWithBackground iconProps={props} alt="Role" src="/assets/images/icons/role-blue.svg" />
  );
};

const TestCaseIndicatorIcon = (props: AppIconProps) => {
  return (
    <IconSvgWithBackground iconProps={props} alt="Test" src="/assets/images/icons/test-blue.svg" />
  );
};

const AssetIndicatorIcon = (props: AppIconProps) => {
  return (
    <IconSvgWithBackground
      iconProps={props}
      alt="Asset"
      src="/assets/images/icons/asset-blue.svg"
    />
  );
};

const DeleteIcon = (props: AppIconProps) => {
  return (
    <IconSvgWithBackground iconProps={props} alt="Delete" src="/assets/images/TrashIcon.svg" />
  );
};

const DeleteIconFilled = (props: AppIconProps) => {
  return (
    <IconSvgWithBackground
      iconProps={props}
      alt="Delete"
      src="/assets/images/icons/trash-filled.svg"
    />
  );
};

const WorkflowDefaultIcon = (props: AppIconProps) => {
  return (
    <IconSvgWithBackground
      iconProps={props}
      alt="Workflows"
      src="/assets/images/workflows-default.svg"
    />
  );
};

const SearchDefaultIcon = (props: AppIconProps) => {
  return (
    <IconSvgWithBackground iconProps={props} alt="Search" src="/assets/images/search-default.svg" />
  );
};

const TestDefaultIcon = (props: AppIconProps) => {
  return (
    <IconSvgWithBackground iconProps={props} alt="Tests" src="/assets/images/tests-default.svg" />
  );
};
const AssetsDefaultIcon = (props: AppIconProps) => {
  return (
    <IconSvgWithBackground iconProps={props} alt="Assets" src="/assets/images/assets-default.svg" />
  );
};
const RolesDefaultIcon = (props: AppIconProps) => {
  return (
    <IconSvgWithBackground iconProps={props} alt="Roles" src="/assets/images/roles-default.svg" />
  );
};
const ActionsDefaultIcon = (props: AppIconProps) => {
  return (
    <IconSvgWithBackground iconProps={props} alt="Roles" src="/assets/images/actions-default.svg" />
  );
};
const ProcessDefaultIcon = (props: AppIconProps) => {
  return (
    <IconSvgWithBackground
      iconProps={props}
      alt="Process"
      src="/assets/images/process-default-gray.svg"
    />
  );
};

const _defaultIconProps = ({ size, color }: AppIconProps) => {
  const dimension = size ?? DEFAULT_ICON_SIZE;
  return {
    style: { height: dimension, width: dimension, color },
  };
};

export {
  ActionIndicatorIcon,
  ActionIcon,
  AlgorithmIcon,
  AssetIndicatorIcon,
  DeleteIcon,
  DatasetIcon,
  ProcessGraphIcon,
  RoleIndicatorIcon,
  RoleIcon,
  TestIcon,
  TestCaseIndicatorIcon,
  WorkflowIndicatorIcon,
  WorkflowIcon,
  WorkflowDefaultIcon,
  TestDefaultIcon,
  AssetsDefaultIcon,
  RolesDefaultIcon,
  ActionsDefaultIcon,
  ProcessDefaultIcon,
  DeleteIconFilled,
  SearchDefaultIcon,
};
