import { Box, Typography } from "@mui/material";
import { useAssets } from "../../hooks/useAssets";
import { BadgeOption } from "../BadgeOption";
import { RectangularLoader } from "../skeleton-loader/RectangularLoader";
import { navigateToAsset } from "../../components/AssetNavigator";
import { useNavigate } from "react-router-dom";

type AssetListReadOnlyCustomFieldProps = {
  value: string[];
};

export const AssetListReadOnlyCustomField = ({ value }: AssetListReadOnlyCustomFieldProps) => {
  const { data: assets, isLoading } = useAssets({ id: value });
  const navigate = useNavigate();

  return isLoading ? (
    <RectangularLoader height="20px" width="100%" />
  ) : assets?.count && value.length > 0 ? (
    <Box display="flex" gap="5px" flexWrap="wrap">
      {assets.results.map((asset, index) => {
        return (
          <BadgeOption
            key={index}
            name={asset?.name}
            onClick={() =>
              navigateToAsset({
                navigate,
                assetId: asset?.id,
                assetNumber: asset?.asset_num,
              })
            }
            maxWidth="325px"
          />
        );
      })}
    </Box>
  ) : (
    <Typography variant="body2">No models</Typography>
  );
};
