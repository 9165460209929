import { Box, Step, StepLabel, Stepper, useTheme } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from "../../../components/ConfirmationModal";
import { navigateToImpactAssessment } from "../../../components/ImpactAssessmentNavigator";
import { SmallModal } from "../../../components/SmallModal";
import { openSnackbar } from "../../../context/SnackbarContext";
import { UseCase, Vendor } from "../../../models/types";
import { editExecutionTask } from "../../../services/ExecutionPlanService";
import { createImpactAssessment } from "../../../services/ImpactAssessmentService";
import { setQuickLinkCompleted } from "../../../services/IntercomService";
import { addUseCase } from "../../../services/UseCaseService";
import { addVendor } from "../../../services/VendorService";
import { AssignUsers } from "./AssignUsers";
import { DefineRoles } from "./DefineRoles";
import { DefineUseCase } from "./DefineUseCase";
import { DefineVendor } from "./DefineVendor";
import { SelectAssessmentType } from "./SelectAssessmentType";

type CreateImpactAssessmentModalProps = {
  /** Should the modal be open? */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
  useCaseSetupTaskId?: string;
  selectedVendor?: Vendor;
  selectedUseCase?: UseCase;
  onAdded?: (assessmentId: string, assessmentType: string) => void;
  quick_link_event_id?: string;
};

export const CreateImpactAssessmentModal = (props: CreateImpactAssessmentModalProps) => {
  const {
    open,
    onClose,
    selectedVendor,
    onAdded,
    useCaseSetupTaskId,
    selectedUseCase,
    quick_link_event_id,
  } = props;
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const steps = [
    "Impact Assessment Type",
    ...(selectedUseCase ? [] : ["Define Use Case"]),
    ...(selectedVendor ? [] : ["Define Vendor"]),
    "Define Roles",
    "Assign Users",
  ];
  const [step, setStep] = useState(0);

  const onContinue = () => {
    if (selectedVendor && step === 1) {
      return setStep(step + 2);
    }
    if (selectedUseCase && step === 0) {
      return setStep(step + 2);
    }
    setStep(step + 1);
  };

  const onBack = () => {
    if (selectedVendor && step === 3) {
      return setStep(step - 2);
    }
    if (selectedUseCase && step === 2) {
      return setStep(step - 2);
    }
    setStep(step - 1);
  };

  const [impactAssessmentType, setImpactAssessmentType] = useState<string>("");
  const [impactAssessmentTypeName, setImpactAssessmentTypeName] = useState<string>("");
  const [impactAssessmentTypeQuestionnaires, setImpactAssessmentTypeQuestionnaires] =
    useState<any>();
  const [useCaseId, setUseCaseId] = useState<string>("");
  const [useCaseName, setUseCaseName] = useState<string>("");
  const [useCaseOwner, setUseCaseOwner] = useState<string>("");
  const [useCaseDescription, setUseCaseDescription] = useState<string>("");
  const [vendorId, setVendorId] = useState<string>("");
  const [vendorName, setVendorName] = useState<string>("");
  const [vendorDescription, setVendorDescription] = useState<string>("");
  const [vendorMainContactName, setVendorMainContactName] = useState<string>("");
  const [assessmentOwner, setAssessmentOwner] = useState<string>("");
  const [departments, setDepartments] = useState<string[]>([]);
  const [reviewers, setReviewers] = useState<string[]>([]);
  const [assignees, setAssignees] = useState<any[]>([]);

  const navigate = useNavigate();

  const handleCreateImpactAssessment = async () => {
    try {
      if (useCaseSetupTaskId) {
        await editExecutionTask(useCaseSetupTaskId, {
          status: "COMPLETED",
        });
      }
      var useCase = selectedUseCase ? selectedUseCase.id : useCaseId;
      var vendor = selectedVendor ? selectedVendor.id : vendorId;
      if (useCaseId === "" && useCaseName !== "") {
        const useCaseCreated = await addUseCase({
          name: useCaseName,
          description: useCaseDescription,
          assignee: useCaseOwner,
          lifecycle_stage: null,
          potential_value: null,
          status: "OK",
        });
        setUseCaseId(useCaseCreated.id);
        useCase = useCaseCreated.id;
      }
      if (vendorId === "" && vendorName !== "") {
        const vendorCreated = await addVendor({
          name: vendorName,
          website: "",
          address: "",
          description: vendorDescription,
          main_contact_name: vendorMainContactName,
          main_contact_email: "",
          main_contact_phone: "",
          business_owner: null,
          compliance_owner: null,
        });
        setVendorId(vendorCreated.id);
        vendor = vendorCreated.id;
      }
      const createdImpactAssessment = await createImpactAssessment({
        impact_assessment_type: impactAssessmentType,
        use_case: useCase,
        vendor: vendor,
        assessment_owner: assessmentOwner,
        related_teams: departments,
        reviewers: reviewers,
        assignees: assignees
          .filter((assignee) => assignee.assignee)
          .map((assignee) => {
            return {
              ref: assignee.ref,
              assignee: assignee.assignee.id,
            };
          }),
      });
      if (quick_link_event_id) {
        setQuickLinkCompleted(quick_link_event_id);
      }
      if (vendor && onAdded) {
        onAdded(createdImpactAssessment.id, "assessment");
        return;
      }
      navigateToImpactAssessment({
        navigate,
        impactAssessmentId: createdImpactAssessment.id,
        impactAssessmentNumber: createdImpactAssessment.impact_assessment_num,
      });
    } catch {
      openSnackbar("Failed to create impact assessment", "error");
    } finally {
      onClose();
    }
  };

  const onConfirmClose = () => {
    onClose();
    setStep(0);
  };

  return (
    <>
      <SmallModal
        open={open}
        onClose={() => {
          setOpenConfirmationModal(true);
        }}
        title="Create Impact Assessment"
        size="large"
        customTitle={
          <Box display="flex" flexGrow={1} justifyContent="center" alignItems="center">
            <Stepper activeStep={step}>
              {steps.map((label, index) => {
                return (
                  <Step active={index === step} key={label}>
                    <StepLabel color={theme.palette.custom.blueTypography}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Box>
        }
      >
        <ConfirmationModal
          open={openConfirmationModal}
          title={"Close Assessment Creation"}
          description={`Are you sure you want to close the assessment creation? Any unsaved progress will be lost once this window is closed.`}
          onAccept={async () => onConfirmClose()}
          onClose={() => setOpenConfirmationModal(false)}
          acceptText="Close"
          rejectText="Cancel"
        />
        {step === 0 ? (
          <SelectAssessmentType
            onAssessmentTypeSelect={(
              assessmentType: string,
              assessmentTypeName: string,
              assessmentTypeQuestionnaires: any
            ) => {
              setImpactAssessmentType(assessmentType);
              setImpactAssessmentTypeName(assessmentTypeName);
              setImpactAssessmentTypeQuestionnaires(assessmentTypeQuestionnaires);
              onContinue();
            }}
          />
        ) : step === 1 ? (
          <DefineUseCase
            selectOnly={!!selectedVendor}
            onUseCaseDefine={(data: {
              useCase?: string;
              useCaseName?: string;
              useCaseOwner?: string;
              useCaseDescription?: string;
            }) => {
              if (data.useCase) {
                setUseCaseId(data.useCase);
              }
              if (data.useCaseName) {
                setUseCaseName(data.useCaseName);
              }
              if (data.useCaseOwner) {
                setUseCaseOwner(data.useCaseOwner);
              }
              if (data.useCaseDescription) {
                setUseCaseDescription(data.useCaseDescription);
              }
              onContinue();
            }}
            onBack={() => onBack()}
            onContinue={() => onContinue()}
            selectedUseCase={selectedUseCase}
          />
        ) : step === 2 ? (
          <DefineVendor
            selectOnly={!!selectedUseCase}
            onVendorDefine={(data: {
              vendor?: string;
              vendorName?: string;
              vendorDescription?: string;
              vendorMainContactName?: string;
            }) => {
              if (data.vendor) {
                setVendorId(data.vendor);
              }
              if (data.vendorName) {
                setVendorName(data.vendorName);
              }
              if (data.vendorDescription) {
                setVendorDescription(data.vendorDescription);
              }
              if (data.vendorMainContactName) {
                setVendorMainContactName(data.vendorMainContactName);
              }
              onContinue();
            }}
            onBack={() => onBack()}
            onContinue={() => onContinue()}
            useCaseDefined={useCaseId !== "" || useCaseName !== "" || !!selectedUseCase}
            selectedVendor={selectedVendor}
          />
        ) : step === 3 ? (
          <DefineRoles
            onDefineRoles={(
              assessmentOwner: string,
              departments: string[],
              reviewers: string[]
            ) => {
              setAssessmentOwner(assessmentOwner);
              setDepartments(departments);
              setReviewers(reviewers);
              onContinue();
            }}
            onBack={() => onBack()}
          />
        ) : (
          <AssignUsers
            assignees={assignees}
            setAssignees={(assginees: any) => setAssignees(assginees)}
            onCreate={() => handleCreateImpactAssessment()}
            onBack={() => onBack()}
            impactAssessmentTypeName={impactAssessmentTypeName}
            impactAssessmentTypeQuestionnaires={impactAssessmentTypeQuestionnaires}
          />
        )}
      </SmallModal>
    </>
  );
};
