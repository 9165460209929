const AppStrings = {
  deleteConfirmationDescription: "Once you delete, it's gone for good.",
  workflow: {
    name: "workflow",
    indicatorLabel: "Workflow",
    createPromptButton: "Create workflow",
    createPrompt: "Workflows let you create AI governance processes",
  },
  testCases: {
    name: "test",
    indicatorLabel: "Test",
    createPromptButton: "Create test case",
    createPrompt: "Make your models reliable with test cases",
  },
  algorithms: {
    createPromptButton: "Create algorithm",
    createPrompt: "Add your algorithm",
  },
  datasets: {
    createPromptButton: "Create dataset",
    createPrompt: "Add your datasets",
  },
  processGraph: {
    name: "process",
    indicatorLabel: "Process",
    createPromptButton: "Create Process",
    createPrompt: "Add a new process to your workflow",
  },
  processExecutionPlan: {
    name: "process_execution_plan",
    indicatorLabel: "Process Execution",
    createPromptButton: "Create Execution",
    createPrompt: "Add a new process execution to your workflow",
  },
  processNode: {
    name: "node",
    indicatorLabel: "Node",
    createPromptButton: "Create node",
  },
  processEdge: {
    name: "edge",
    indicatorLabel: "Edge",
    createPromptButton: "Create edge",
    deleteConfirmationTitle: "Delete this edge?",
  },
  roles: {
    name: "role",
    indicatorLabel: "Role",
    createPromptButton: "Create role",
    createPrompt: "Add a new role",
  },
  actions: {
    name: "action",
    indicatorLabel: "Action",
    createPromptButton: "Create action",
    createPrompt: "Add a new action",
  },
  assets: {
    name: "asset",
    indicatorLabel: "Asset",
    createPromptButton: "Create asset",
    createPrompt: "Add a new asset",
  },
  customField: {
    updateConfirmationDescription:
      "Updating the central custom field will be applied everywhere this field is used.",
    deleteConfirmationDescription: "Not allowed",
    createPrompt: "Create a new custom field",
    typeChangeNotAllowed: "Note: type can not be changed",
  },
  customFieldValue: {
    deleteConfirmationTitle: "Delete this value?",
    deleteConfirmationDescription: "Once you delete, it's gone for good.",
  },
  fileAttachment: {
    deleteConfirmationTitle: "Delete this attachment?",
    deleteConfirmationDescription: "Once you delete, it's gone for good.",
  },
};

export default AppStrings;
