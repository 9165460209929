import { Policy } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const policyEndpoints = {
  policies: () => "/policies",
  policy: (policyId: string) => `/policies/${policyId}`,
};

export const getPolicies = (params: any) => {
  return httpGetAuthenticated<ListResponse<Policy>>(policyEndpoints.policies(), {
    params,
  });
};

export const getPolicy = (policyId: string) => {
  return httpGetAuthenticated<Policy>(policyEndpoints.policy(policyId));
};

export const editPolicy = async (
  policyId: string,
  payload: { name?: string; description?: string; value: any }
) => {
  const { data } = await httpPatchAuthenticated(policyEndpoints.policy(policyId), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Policy] });
  return data;
};

export const addPolicy = async (payload: { name: string; description: string; value: string }) => {
  const { data } = await httpPostAuthenticated<Policy>(policyEndpoints.policies(), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Policy] });
  return data;
};

export const deletePolicy = async (policyId: string) => {
  const resp = await httpDeleteAuthenticated(policyEndpoints.policy(policyId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Policy] });
  return resp;
};
