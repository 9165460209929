import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { SmallModal } from "../../../components/SmallModal";
import { openSnackbar } from "../../../context/SnackbarContext";
import { useQuestionnaire } from "../../../hooks/useQuestionnaires";
import { ExecutionTask, Question, Questionnaire, RegistryFieldType } from "../../../models/types";
import { QuestionDisplay } from "../../../molecules/impact-assessment/QuestionDisplay";
import { QuestionNavigation } from "../../../molecules/impact-assessment/QuestionNavigation";
import { QuestionnaireProgressBar } from "../../../molecules/impact-assessment/QuestionnaireProgressBar";
import { editExecutionTask, getExecutionTasks } from "../../../services/ExecutionPlanService";
import { addQuestionnaireAnswer } from "../../../services/QuestionnaireAnswerService";
import queryClient, { QueryKey } from "../../../state/QueryStore";
import { getDefaultCustomFieldStartValue, isListType } from "../../../utilities/UIHelper";
import { AISuggestion } from "../../impact-assessment/AISuggestion";
import { SemanticSimilarityModal } from "../compliances/SemanticSimilarityModal";

type QuestionnaireAnswerModalProps = {
  /** Should he modal be open? */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
  /** Questionnaire Id */
  questionnaireId?: string;
  /** Impact Assessment Id */
  impactAssessmentId?: string;
  questionId?: string | null;
  previewQuestionnaire: Questionnaire | null;
  previewQuestion: Question | null;
};

type Answer = {
  questionnaire: string;
  question: string;
  field_type: RegistryFieldType;
  answer: string | null | string[];
  status: string;
};

export const QuestionnaireAnswerModal = (props: QuestionnaireAnswerModalProps) => {
  const {
    open,
    onClose,
    questionnaireId,
    impactAssessmentId,
    questionId,
    previewQuestionnaire,
    previewQuestion,
  } = props;
  const { data: questionnaire, isLoading } = useQuestionnaire(questionnaireId ?? "");
  const [questionIndex, setQuestionIndex] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [relatedRegulationsOpen, setRelatedRegulationsOpen] = useState<boolean>(false);
  const isSingleQuestion = !!questionId || !!previewQuestion;
  const isQuestionnaireFinalized = questionnaire?.status === "FINALIZED";
  const [valueError, setValueError] = useState<boolean>(false);
  const preview = previewQuestionnaire !== null || previewQuestion !== null;
  useEffect(() => {
    if (questionnaire) {
      setAnswers(
        questionnaire.questions?.map((question: any) => ({
          questionnaire: questionnaire.id,
          question: question.ref,
          field_type: question.type,
          answer: getDefaultCustomFieldStartValue(question.type),
          status: "PENDING REVIEW",
        }))
      );
    }
  }, [questionnaire, open]);

  useEffect(() => {
    if (questionId) {
      const index = questionnaire?.questions.findIndex((question) => question.ref === questionId);
      if (index !== -1 && typeof index === "number") {
        setQuestionIndex(index);
      } else {
        setQuestionIndex(0);
      }
    } else {
      setQuestionIndex(0);
    }
  }, [open, questionId, questionnaire]);

  useEffect(() => {
    if (previewQuestionnaire && previewQuestion) {
      const index = previewQuestionnaire.questions.findIndex(
        (question) => question.title === previewQuestion.title
      );
      if (index !== -1 && typeof index === "number") {
        setQuestionIndex(index);
      } else {
        setQuestionIndex(0);
      }
    } else {
      setQuestionIndex(0);
    }
  }, [open, previewQuestionnaire, previewQuestion]);

  const handleNext = async () => {
    if (preview) {
      if (
        (questionnaireDetails?.questions &&
          questionIndex === questionnaireDetails?.questions.length - 1) ||
        isSingleQuestion
      ) {
        onClose();
      } else {
        setQuestionIndex(questionIndex + 1);
      }
      return;
    }
    if (questionnaire?.questions[questionIndex].required && !isQuestionnaireFinalized) {
      const isListTypeField = isListType(
        questionnaire?.questions[questionIndex].type as RegistryFieldType
      );
      if (
        !answers[questionIndex].answer ||
        (isListTypeField && (answers[questionIndex].answer?.length ?? 0) === 0)
      ) {
        setValueError(true);
        return;
      }
    }
    setValueError(false);
    try {
      if (
        (questionnaire?.questions && questionIndex === questionnaire?.questions.length - 1) ||
        isSingleQuestion
      ) {
        handleSubmit();
      } else {
        setQuestionIndex(questionIndex + 1);
      }
    } catch (error) {
      openSnackbar("Something went wrong, please try again", "error");
    }
  };

  const handleSubmit = async () => {
    if (isQuestionnaireFinalized) {
      return onClose();
    }
    setLoading(true);
    try {
      await Promise.all(
        answers.map(async (answer) => {
          // check if answer type is array and convert it to string
          if (Array.isArray(answer.answer)) {
            if (answer.answer.length !== 0) {
              await addQuestionnaireAnswer({ ...answer, answer: JSON.stringify(answer.answer) });
            }
          } else {
            if (answer.answer !== null && answer.answer !== "") {
              await addQuestionnaireAnswer(answer);
            }
          }
        })
      );

      // If there are pending tasks (except by the review) for this questionnaire, mark them as completed
      const tasks = await getExecutionTasks({
        "parent_object_id[]": [questionnaireId ?? ""],
        status: "PENDING",
      });
      tasks?.data?.results?.map(async (task: ExecutionTask) => {
        if (task?.task_type !== "Section Review") {
          await editExecutionTask(task.id, { status: "COMPLETED" });
        }
      });
      queryClient.invalidateQueries({ queryKey: [QueryKey.Questionnaire] });
      openSnackbar("Questionnaire submitted successfully", "success");
    } catch (error) {
      openSnackbar("Something went wrong, please try again", "error");
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const handleChange = (value: string | null) => {
    setValueError(false);
    const newAnswers = answers.map((answer, index) => {
      if (index === questionIndex) {
        return {
          ...answer,
          answer: value,
        };
      }
      return answer;
    });
    setAnswers(newAnswers);
  };

  const questionnaireDetails = preview ? previewQuestionnaire : questionnaire;

  return !questionnaireDetails ? null : (
    <>
      <SmallModal
        open={open}
        onClose={onClose}
        title={questionnaireDetails?.name}
        size="medium"
        isLoading={isLoading}
        customTitle={
          <QuestionnaireProgressBar
            questionIndex={questionIndex}
            questionnaireLength={questionnaireDetails?.questions.length}
          />
        }
      >
        <Stack gap="10px">
          <QuestionDisplay
            question={questionnaireDetails?.questions[questionIndex]}
            questionnaire={questionnaireDetails}
            questionIndex={questionIndex}
            value={preview ? null : answers[questionIndex]?.answer}
            onChange={handleChange}
            valueError={valueError}
          />
          {!isQuestionnaireFinalized && questionnaireId && impactAssessmentId && (
            <AISuggestion
              questionnaireId={questionnaireId}
              impactAssessmentId={impactAssessmentId}
              title={questionnaire?.questions[questionIndex]?.title ?? ""}
              setShowSemanticSearchResult={() => setRelatedRegulationsOpen(true)}
              answers={answers.map((answer) => {
                return answer.answer as string;
              })}
              showSemanticSearch={
                questionnaire?.name ===
                  "Microsoft RAI Impact Assessment: Section 1.8 - Intended Uses" &&
                (answers[questionIndex]?.answer?.length ?? 0) > 0
              }
            />
          )}
          <QuestionNavigation
            isQuestionnaireFinalized={isQuestionnaireFinalized}
            impactAssessmentId={impactAssessmentId ?? ""}
            loading={loading}
            questionnaireId={questionnaireId ?? ""}
            currentQuestionIndex={questionIndex}
            handleNext={() => handleNext()}
            handlePrevious={() => {
              setValueError(false);
              setQuestionIndex(questionIndex - 1);
            }}
            isSingleQuestion={isSingleQuestion}
            question={questionnaireDetails?.questions[questionIndex]}
            questionsCount={questionnaireDetails?.questions.length}
            preview={preview}
          />
        </Stack>
        <SemanticSimilarityModal
          open={relatedRegulationsOpen}
          onClose={() => {
            setRelatedRegulationsOpen(false);
          }}
          answers={answers.map((answer) => {
            return answer.answer as string;
          })}
          impactAssessmentId={impactAssessmentId ?? ""}
          showSemanticSearch={
            questionnaire?.name ===
              "Microsoft RAI Impact Assessment: Section 1.8 - Intended Uses" &&
            (answers[questionIndex]?.answer?.length ?? 0) > 0
          }
        />
      </SmallModal>
    </>
  );
};
