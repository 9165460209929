import { QuestionnaireAnswer, QuestionnaireAnswerFetchOptions } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { httpGetAuthenticated, httpPatchAuthenticated, httpPostAuthenticated } from "./ApiService";

const questionnaireAnswerEndpooints = {
  questionnaireAnswers: "/questionnaire_answers",
  questionnaireAnswer: (id: string) => `/questionnaire_answers/${id}`,
};

export const getAnswers = (params: QuestionnaireAnswerFetchOptions) => {
  return httpGetAuthenticated<ListResponse<QuestionnaireAnswer>>(
    questionnaireAnswerEndpooints.questionnaireAnswers,
    {
      params,
    }
  );
};

export const getAnswer = (answerId: string) => {
  return httpGetAuthenticated<QuestionnaireAnswer>(
    questionnaireAnswerEndpooints.questionnaireAnswer(answerId)
  );
};

export const addQuestionnaireAnswer = async (payload: any) => {
  const { data } = await httpPostAuthenticated<any>(
    questionnaireAnswerEndpooints.questionnaireAnswers,
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.QuestionnaireAnswer] });
  return data;
};

export const editQuestionnaireAnswer = async (id: string, payload: any) => {
  const { data } = await httpPatchAuthenticated(
    questionnaireAnswerEndpooints.questionnaireAnswer(id),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.QuestionnaireAnswer, id] });
  return data;
};
