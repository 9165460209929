import { LoadingButton } from "@mui/lab";
import { Box, Typography, useTheme } from "@mui/material";
import { TemplateDownloadButton } from "../molecules/TemplateDownloadButton";

type FairoPolicyTemplateProps = {
  title: string;
  description?: string;
  buttonText?: string;
  onClick?: () => void;
  hideButton?: boolean;
  template?: string;
  fileName?: string;
  buttonSize?: "small" | "large";
};

export const FairoPolicyTemplate = (props: FairoPolicyTemplateProps) => {
  const {
    title,
    description,
    buttonText = "Download",
    onClick,
    hideButton = false,
    template,
    fileName = "sample",
    buttonSize = "small",
  } = props;
  const theme = useTheme();
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        gap: "20px",
        padding: "10px",
        backgroundColor: theme.palette.custom.planBackground,
        border: `1px solid ${theme.palette.custom.secondaryBorder}`,
        borderRadius: "3px",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box display="flex" flexDirection="column" gap="10px">
        <Typography variant="h3">{title}</Typography>
        {description && <Typography variant="body2">{description}</Typography>}
      </Box>
      {onClick ? (
        <LoadingButton
          sx={{ minWidth: buttonSize === "small" ? "77px" : "115px" }}
          variant="contained"
          onClick={onClick}
        >
          {buttonText}
        </LoadingButton>
      ) : hideButton ? null : template ? (
        <TemplateDownloadButton fileName={fileName} template={template} />
      ) : null}
    </Box>
  );
};
