import { Typography } from "@mui/material";

interface INavbarLabel {
  label: string;
  selected: boolean;
}

export const NavbarLabel = ({ label, selected }: INavbarLabel) => {
  const typographyStyle = {
    alignItems: "center",
    width: "100%",
    maxWidth: "100%",
    justifyContent: "left",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <Typography variant={selected ? "navbarSelected" : "navbar"} sx={typographyStyle}>
      {label}
    </Typography>
  );
};
