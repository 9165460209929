import { useQuery } from "react-query";
import { CustomFieldFetchOptions } from "../models/types";
import {
  getCustomField,
  getCustomFieldValue,
  getCustomFieldValues,
  getCustomFields,
  getCustomFieldsTable,
} from "../services/CustomFieldService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useCustomFields = (params?: CustomFieldFetchOptions) => {
  return useQuery([QueryKey.CustomField, params], async () => {
    const { data } = await getCustomFields(params);
    return data.results;
  });
};

export const useCustomFieldsTable = (params: CustomFieldFetchOptions) => {
  return useQuery([QueryKey.CustomField, params], async () => {
    const { data } = await getCustomFieldsTable(params);
    return data;
  });
};

export const useCustomField = (objectId: string) => {
  return useQuery([QueryKey.CustomField, objectId], async () => {
    const { data } = await getCustomField(objectId);
    return data;
  });
};

export const fetchCustomField = async (objectId: string) => {
  return queryClient.fetchQuery([QueryKey.CustomField, objectId], async () => {
    const { data } = await getCustomField(objectId);
    return data;
  });
};

export const listCustomFields = async () => {
  const { data } = await getCustomFields();
  return data.results;
};

export const useCustomFieldValues = (parentObjectId: string, params?: CustomFieldFetchOptions) => {
  return useQuery([QueryKey.CustomFieldValue, parentObjectId, params], async () => {
    const { data } = await getCustomFieldValues({ parent_object_id: parentObjectId, ...params });
    return data.results;
  });
};

export const useCustomFieldValue = (objectId: string) => {
  return useQuery([QueryKey.CustomFieldValue, objectId], async () => {
    const { data } = await getCustomFieldValue(objectId);
    return data;
  });
};
