import { Box, CircularProgress, Typography, useTheme } from "@mui/material";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { getProgressFromControl } from "../utilities/ProgressHelper";

type ComplianceProgressProps = {
  progress: {
    completed: number;
    total: number;
  };
};

export const ComplianceProgress = ({ progress }: ComplianceProgressProps) => {
  const value = progress ? getProgressFromControl(progress) : 0;
  const theme = useTheme();
  return (
    <Box sx={{ gap: "5px", display: "flex", alignItems: "center" }}>
      {value === 100 ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            backgroundColor: theme.palette.custom.greenTypography,
          }}
        >
          <NavbarIcon
            variant="tick"
            sx={{
              width: "10px",
              height: "10px",
            }}
          />
        </Box>
      ) : (
        <CircularProgress variant="determinate" color="success" size={20} value={value} />
      )}
      <Typography variant="body2">
        {progress?.completed} of {progress?.total}
      </Typography>
    </Box>
  );
};
