import { Button, Stack, TextField } from "@mui/material";
import { SmallModal } from "../../components/SmallModal";
import { FieldHandlerProps } from "../../models/types";
import { useState } from "react";

export const UpdateJSONModal = (
  props: FieldHandlerProps & { open: boolean; onClose: () => void }
) => {
  const { open, onClose, value, onChange, error, errorMessage, required, label } = props;
  const [json, setJson] = useState<any>(value);

  const showValueParsed = (value: string) => {
    try {
      const jsonObj = JSON.parse(value);
      return JSON.stringify(jsonObj, null, 2);
    } catch (error) {
      return value;
    }
  };

  const onSave = () => {
    onChange(json);
    onClose();
  };

  return (
    <SmallModal open={open} onClose={onClose} title={`Update ${label ?? "JSON"}`} size="medium">
      <Stack gap="10px">
        <TextField
          label={label ?? "JSON"}
          InputProps={{
            style: { fontFamily: "'Fira Code', monospace", fontSize: "14px", fontWeight: 400 },
          }}
          required={required}
          multiline={true}
          maxRows={10}
          error={error}
          helperText={error ? errorMessage : null}
          value={showValueParsed(json)}
          onChange={(e) => setJson(e.target.value)}
        />
        <Button variant="contained" onClick={onSave}>
          Save
        </Button>
      </Stack>
    </SmallModal>
  );
};
