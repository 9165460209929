import { PublicQuestionnaireFetchOptions, Questionnaire } from "../models/types";
import { httpGetAuthenticated, httpGetPublic, httpPostPublic } from "./ApiService";

const endpoints = {
  retrieve: (objectId: string) => `/public_questionnaires/${objectId}`,
};

export const getPublicQuestionnaire = (
  questionnaireId: string,
  params: PublicQuestionnaireFetchOptions
) => {
  return httpGetPublic<Questionnaire>(endpoints.retrieve(questionnaireId), {
    params,
  });
};

export const addPublicQuestionnaireAnswer = async (questionnaireId: string, payload: any) => {
  const { data } = await httpPostPublic(`${endpoints.retrieve(questionnaireId)}/`, payload);
  return data;
};

export const finishPublicQuestionnaireAnswer = async (params: any) => {
  const { data } = await httpGetAuthenticated(`/finish_public_questionnaires/`, {
    params,
  });
  return data;
};

export const submitPublicQuestionnaire = async (params: {
  public_link_id: string;
  session_id: string;
}) => {
  const { data } = await httpGetPublic(`/submit_public_questionnaire/`, {
    params,
  });
  return data;
};
