import { IconButton } from "../../atoms/IconButton";
import { NameIconList } from "../../atoms/NameIcon";
import { INavbarIconVariant } from "../../atoms/navbar/Icon";
import { DefaultBadge, StatusBadge } from "../../atoms/StatusBadge";
import UserTag from "../../components/UserTag";
import { BaseFilterOptions, BaseFilters, FilterOption, ImpactAssessment } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { ComplianceProgress } from "../../molecules/ComplianceProgress";
import { getWebsiteLogo } from "../../utilities/WebsiteLogoHelper";

export const impactAssessmentListFilterOptions: FilterOption<BaseFilters>[] = [
  { label: "Name", value: "name" },
  ...BaseFilterOptions,
];

type ImpactAssesssmentTableProps = {
  useCaseId?: string;
  onUnlink?: (ia: ImpactAssessment) => void;
};

const getIcon = (status: string): INavbarIconVariant => {
  switch (status) {
    case "Microsoft RAI Impact Assessment":
      return "microsoft-rai";
    case "U.S. CIO Council Algorithmic Impact Assessment":
      return "us-cio-coucil-algorithmic";
    case "OCED Algorithmic Impact Assessment":
      return "oced-algorithmic";
    case "Canadian Algorithmic Impact Assessment Tool":
      return "canadian-algorithmic";
    case "EQUAL AI Algorithmic Impact Assesment":
      return "equal-ai-algorithmic";
    case "Fairo RMF PAIS 1.0 Risk Assessment":
      return "fairo-logo";
    default:
      return "questionnaire";
  }
};

export const impactAssessmentListColumnsVisibility = ({
  onUnlink,
  useCaseId,
}: ImpactAssesssmentTableProps): IColumnVisibility<ImpactAssessment>[] => [
  {
    field: "use_case",
    headerName: "Use Case",
    visible: true,
    columnMinWidth: 280,
    renderer: (ia) => <DefaultBadge value={ia.use_case_name?.name ?? ""} />,
  },
  {
    field: "vendor",
    headerName: "Vendor",
    visible: true,
    columnMinWidth: 280,
    renderer: (ia) => (
      <DefaultBadge
        renderIcon={() =>
          ia?.vendor_name?.website ? (
            <img
              src={getWebsiteLogo(ia?.vendor_name?.website) ?? ""}
              alt={`${ia?.vendor_name?.name} logo`}
            />
          ) : null
        }
        value={ia.vendor_name?.name ?? ""}
      />
    ),
  },
  {
    field: "impact_assessment_type",
    headerName: "Type",
    visible: true,
    columnMinWidth: 280,
    columnMaxWidth: 400,
    renderer: (ia) => (
      <DefaultBadge
        icon={getIcon(ia.impact_assessment_type_name.name)}
        value={ia.impact_assessment_type_name.name}
      />
    ),
  },
  {
    field: "id",
    headerName: "Related Teams",
    visible: true,
    columnMinWidth: 150,
    columnMaxWidth: 300,
    renderer: (ia) => (
      <NameIconList
        options={ia.related_teams_names?.map((team) => team.name) ?? []}
        maxDisplay={3}
        size="small"
        twoLetters={false}
      />
    ),
  },
  {
    field: "status",
    headerName: "Status",
    visible: true,
    columnMinWidth: 150,
    columnMaxWidth: 150,
    renderer: (ia) => <StatusBadge status={ia.status} />,
  },
  {
    field: "owner",
    headerName: "Owner",
    visible: true,
    columnMinWidth: 200,
    columnMaxWidth: 200,
    renderer: (ia) => <UserTag name={ia.assessment_owner_name?.name} />,
  },
  {
    field: "progress",
    headerName: "Progress",
    visible: true,
    columnMinWidth: 140,
    columnMaxWidth: 140,
    renderer: (ia) => <ComplianceProgress progress={ia.progress} />,
  },
  ...(onUnlink
    ? [
        {
          field: "updated_by",
          headerName: "",
          visible: true,
          columnMinWidth: 60,
          columnMaxWidth: 60,
          renderer: (ia) => {
            const isDirectRelationship = ia.use_case === useCaseId;
            return isDirectRelationship ? null : (
              <IconButton
                variant="unlink"
                height="30px"
                width="30px"
                onClick={(e) => {
                  e.stopPropagation();
                  onUnlink(ia);
                }}
              />
            );
          },
        } as IColumnVisibility<ImpactAssessment>,
      ]
    : []),
];
