import { useQuery } from "react-query";
import { getEvidenceMappings } from "../services/EvidenceMappingService";
import queryClient, { QueryKey } from "../state/QueryStore";
import { EvidenceMappingFetchOptions } from "../models/types";

export const useEvidenceMappings = (params: EvidenceMappingFetchOptions) => {
  return useQuery([QueryKey.EvidenceMapping, params], async () => {
    const { data } = await getEvidenceMappings(params);
    return data;
  });
};

export const fetchEvidenceMappings = (params: EvidenceMappingFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.EvidenceMapping, params], async () => {
    const { data } = await getEvidenceMappings({ ...params, fairo_data: true });
    return data;
  });
};

export const searchEvidenceMappings = async (searchText: string) => {
  const evidenceMappings = await fetchEvidenceMappings({ search: searchText });
  return evidenceMappings.results;
};
