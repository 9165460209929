import { Box, TextField, Typography, useTheme } from "@mui/material";
import { ColorBox, ColorList } from "./CreateTagModal";
import { useState } from "react";

type ColorPaletteProps = {
  color: string;
  onChange: (color: string) => void;
  colors?: string[];
};

const ColorPalette = (props: ColorPaletteProps) => {
  const theme = useTheme();
  const {
    color,
    onChange,
    colors = [
      theme.palette.custom.redTypography,
      "#FA945B",
      theme.palette.custom.yellowTypography,
      theme.palette.custom.greenTypography,
      "#5FEBB8",
      "#5FD2EB",
      "#5FA8EB",
      "#5F75EB",
      "#835FEB",
      "#D75FEB",
    ],
  } = props;
  const innerTextColor = theme.palette.text.secondary;
  const [colorError, setColorError] = useState<string | null>(null);

  const cleanInvalidCharsForHexColor = (value: string): string => {
    // also check the limit of hex color length
    if (value.length > 7) {
      return value.slice(0, 7);
    }
    return `#${value.replace(/[^0-9A-F]/gi, "")}`;
  };

  const isValidHexColorRegex = (value: string): boolean => {
    // accept between 1 and 6 characters
    return /^#[0-9A-Fa-f]{6}$/i.test(value);
  };

  const handleChange = (color: string) => {
    if (!isValidHexColorRegex(color)) {
      setColorError("Invalid hex color");
    }
    setColorError("");
    onChange(color);
  };

  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <Typography color={innerTextColor} variant="h3">
        Color
      </Typography>
      <ColorList
        onChangeColor={(selectedColor: string) => handleChange(selectedColor)}
        colors={colors}
      />
      <Box display="flex" flexDirection="row" alignItems="center" gap="8px">
        <ColorBox size="35px" selected={true} onClick={() => {}} color={color} />
        <TextField
          value={color}
          onChange={(e) => handleChange(cleanInvalidCharsForHexColor(e.target.value))}
          label="Color"
        />
      </Box>
      {colorError && typeof colorError === "string" && (
        <Typography fontSize="12px" variant="body2" color="error">
          {colorError}
        </Typography>
      )}
    </Box>
  );
};

export { ColorPalette };
