import { Box, Typography, useTheme } from "@mui/material";
import { forwardRef, useImperativeHandle, useState } from "react";
import { StringCustomField } from "../../components/CustomFieldForm";
import { validateEmail } from "../../utilities/UIHelper";
type PersonalInformationCustomFieldProps = {
  value: string | null;
  onChange: (newValue: string | null) => void;
  required?: boolean;
  valueError: boolean;
  errorMessage: string;
};
/**
 * @param PersonalInformationCustomFieldProps
 * @returns
 */

type PersonalInformation = {
  name: string;
  email: string;
  company_name: string;
  job_title: string;
  department: string;
};

export const PersonalInformationCustomField = forwardRef(
  (props: PersonalInformationCustomFieldProps, ref) => {
    const { value, onChange, required, valueError, errorMessage } = props;
    const theme = useTheme();

    const [validationErrors, setValidationErrors] = useState({
      name: false,
      email: false,
      company_name: false,
      job_title: false,
    });

    const handleKeyChange = (newValue: string, key: string) => {
      const oldValue = JSON.parse(value || "{}");
      const newValueJson = { ...oldValue, [key]: newValue };
      onChange(JSON.stringify(newValueJson));
    };

    const getValue = (key: string) => {
      const valueJson = JSON.parse(value || "{}");
      return valueJson[key];
    };

    useImperativeHandle(ref, () => ({
      validate: () => {
        const errors = {
          name:
            !getValue("name") || getValue("name") === "" || getValue("name").split(" ").length < 2,
          email:
            !getValue("email") || getValue("email") === "" || !validateEmail(getValue("email")),
          company_name: !getValue("company_name") || getValue("company_name") === "",
          job_title: !getValue("job_title") || getValue("job_title") === "",
        };

        setValidationErrors(errors);

        // Return true if all fields are valid (no errors)
        return !Object.values(errors).some((hasError) => hasError);
      },
    }));

    return (
      <Box display="flex" flexDirection="column" gap="10px">
        <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
          Full Name
        </Typography>
        <StringCustomField
          required={required}
          label="Full Name"
          value={getValue("name")}
          onChange={(e) => handleKeyChange(e, "name")}
          error={validationErrors.name}
          errorMessage={"Please enter your full name"}
        />
        <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
          Email
        </Typography>
        <StringCustomField
          rows={3}
          label="Email"
          required={required}
          value={getValue("email")}
          onChange={(e) => handleKeyChange(e, "email")}
          error={validationErrors.email}
          errorMessage={"Please enter a valid email address"}
        />
        <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
          Company Name
        </Typography>
        <StringCustomField
          rows={3}
          label="Company Name"
          required={required}
          value={getValue("company_name")}
          onChange={(e) => handleKeyChange(e, "company_name")}
          error={validationErrors.company_name}
          errorMessage={"Please enter your company name"}
        />
        <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
          Job Title
        </Typography>
        <StringCustomField
          rows={3}
          label="Job Title"
          required={required}
          value={getValue("job_title")}
          onChange={(e) => handleKeyChange(e, "job_title")}
          error={validationErrors.job_title}
          errorMessage={"Please enter your job title"}
        />
        <Typography variant="h3" color={theme.palette.custom.secondaryTypography}>
          Department
        </Typography>
        <StringCustomField
          rows={3}
          label="Department"
          required={false}
          value={getValue("department")}
          onChange={(e) => handleKeyChange(e, "department")}
          error={false}
          errorMessage={""}
        />
      </Box>
    );
  }
);
