import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import BreadcrumbsNavigator from "../../molecules/BreadcrumbsNavigator";
import Header from "../../organisms/HeaderNavigation";

interface INavigationProps {
  showOnlyFirst?: boolean;
  rightComponent?: React.ReactNode;
  showFirstLast?: boolean;
  hideFirst?: boolean;
}

const Navigation = ({
  showOnlyFirst,
  rightComponent,
  showFirstLast,
  hideFirst,
}: INavigationProps) => {
  const { pathname } = useLocation();
  const invalidLabels = ["execution"];
  const { organization } = useAuth();

  const getLabel = (path: string) => {
    const match = path.match(/[^\/]+$/);
    return match ? match[0] : "";
  };

  const getCrumbs = (path: string) => {
    const matches = path.match(/\/[^\/]+/g);
    if (!matches) return [];

    let accumulated = "";
    return matches
      .map((match) => {
        accumulated += match;
        return {
          label: getLabel(match),
          routeTo: accumulated,
        };
      })
      .filter((crumb) => !invalidLabels.includes(crumb.label))
      .filter((crumb) => crumb.label.toLowerCase() !== "organization");
  };

  const getCrumbsForShowFirstLast = (path: string) => {
    const matches = path.match(/\/[^\/]+/g);
    if (!matches) return [];

    let accumulated = "";
    const crumbs = [];
    for (let i = 1; i < matches.length; i++) {
      if (matches[1] === "/tests" && matches[i] === "/fairo") {
        continue;
      }
      accumulated += matches[i];
      crumbs.push({
        label: getLabel(matches[i]),
        routeTo: accumulated,
      });
    }
    accumulated = matches[0] + accumulated;
    crumbs.push({
      label: getLabel(matches[0]),
      routeTo: accumulated,
    });
    return crumbs.filter((crumb) => !invalidLabels.includes(crumb.label));
  };

  const crumbs = getCrumbs(pathname);
  const crumbsForShowFirstLast = getCrumbsForShowFirstLast(pathname);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Header
        leftComponent={
          <BreadcrumbsNavigator
            crumbs={
              showOnlyFirst && crumbs.length > 0
                ? [{ label: organization?.name ?? "", routeTo: "organization" }, crumbs[0]]
                : showFirstLast
                ? crumbsForShowFirstLast
                : hideFirst
                ? [{ label: organization?.name ?? "", routeTo: "organization" }, ...crumbs.slice(1)]
                : [{ label: organization?.name ?? "", routeTo: "organization" }, ...crumbs]
            }
          />
        }
        rightComponent={rightComponent ?? <></>}
      />
    </Box>
  );
};

export default Navigation;
