import { useQuery } from "react-query";
import { VersionHistoryFetchOptions, VersionTagFetchOptions } from "../models/types";
import { getVersion, getVersionHistory, getVersionTag } from "../services/VersionHistoryService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useVersionHistory = (params: VersionHistoryFetchOptions) => {
  return useQuery([QueryKey.VersionHistory, params], async () => {
    const { data } = await getVersionHistory(params);
    return data;
  });
};

export const useVersion = (versionId: string) => {
  return useQuery([QueryKey.VersionHistory, versionId], async () => {
    if (versionId === "") return null;
    const { data } = await getVersion(versionId);
    return data;
  });
};

export const fetchVersionHistory = (params: VersionHistoryFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.VersionHistory, params], async () => {
    const { data } = await getVersionHistory(params);
    return data;
  });
};

export const fetchVersionTag = (params: VersionTagFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.VersionTag, params], async () => {
    const { data } = await getVersionTag(params);
    return data;
  });
};
