import { Box, useTheme } from "@mui/material";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

export const AuditDetailsFormLoader = () => {
  const theme = useTheme();
  const isSmallScreen = ScreenSmallerThen();
  return (
    <SecondaryBox padding="0px">
      <Box display="flex" flexDirection={isSmallScreen ? "column" : "row"}>
        <Box
          width={isSmallScreen ? "100%" : "50%"}
          padding="10px"
          display="flex"
          flexDirection="column"
          gap="10px"
          justifyContent="space-between"
        >
          <Box display="flex" flexDirection="column" gap="10px">
            <RectangularLoader width="320px" height="24px" />
            <RectangularLoader width="320px" height="24px" />
            <RectangularLoader width="320px" height="24px" />
            <RectangularLoader width="320px" height="24px" />
            <RectangularLoader width="320px" height="24px" />
            <RectangularLoader width="160px" height="24px" />
            <RectangularLoader width="100%" height="100px" />
          </Box>
          <RectangularLoader width="100%" height="24px" />
        </Box>
        <Box
          width={isSmallScreen ? "100%" : "50%"}
          padding="10px"
          display="flex"
          flexDirection="column"
          gap="20px"
          borderLeft={isSmallScreen ? "0" : `1px solid ${theme.palette.custom.secondaryBorder}`}
          borderTop={!isSmallScreen ? "0" : `1px solid ${theme.palette.custom.secondaryBorder}`}
        >
          <RectangularLoader width="320px" height="24px" />
          <RectangularLoader width="100%" height="24px" />
          <RectangularLoader width="100%" height="24px" />
          <RectangularLoader width="100%" height="24px" />
          <RectangularLoader width="100%" height="24px" />
          <RectangularLoader width="160px" height="24px" />
          <RectangularLoader width="100%" height="100px" />
        </Box>
      </Box>
    </SecondaryBox>
  );
};
