import { Box, useMediaQuery, useTheme } from "@mui/material";
import OrganizationContainer from "../../components/OrganizationContainer";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { ApiKeysLoader } from "../../organisms/skeleton-loaders/ApiKeysLoader";

export const OrganizationSettingsApiKeyLoader = () => {
  const theme = useTheme();
  const isSmScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <OrganizationContainer
      content={
        <Box
          display="flex"
          flexDirection="row"
          width="100%"
          justifyContent="center"
          gap="15px"
          flexWrap="wrap"
          alignItems={isSmScreen ? "center" : "stretch"}
        >
          <ApiKeysLoader title="API Keys" />
        </Box>
      }
    />
  );
};
