import { LoadingButton } from "@mui/lab";
import { Box, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Awaitable } from "../utilities/common";
import { SmallModal } from "./SmallModal";

type SubscriptionModalProps = {
  /** Whether the subscription modal is open */
  open: boolean;
  /** Title of the modal */
  title: string;
  /** Description text for the modal */
  description: string;
  /** Text to display on the accept button. */
  acceptText?: string;
  /** Callback when subscription is accepted */
  onAccept: () => Awaitable<void>;
  /** Callback to close the modal */
  onClose: () => void;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
};

export const SubscriptionModal = (props: SubscriptionModalProps) => {
  const { open, title, description, acceptText, onAccept, onClose, Icon } = props;
  const [isAccepting, setIsAccepting] = useState<boolean>(false);
  const onAcceptClick = async () => {
    setIsAccepting(true);
    try {
      await onAccept();
      onClose();
    } finally {
      setIsAccepting(false);
    }
  };

  return (
    <SmallModal open={open} onClose={onClose} title="">
      <Stack gap="10px" textAlign="center">
        <Box>
          <Icon width="236px" height="120px" />
        </Box>
        <Typography variant="h2" fontWeight={600}>
          {title}
        </Typography>
        <Typography variant="subtitle1" whiteSpace="pre-wrap">
          {description}
        </Typography>

        {acceptText ? (
          <Box>
            <LoadingButton
              variant="contained"
              fullWidth
              onClick={onAcceptClick}
              loading={isAccepting}
            >
              {acceptText}
            </LoadingButton>
          </Box>
        ) : null}
      </Stack>
    </SmallModal>
  );
};
