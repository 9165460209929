import { Box } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import OrganizationContainer from "../../components/OrganizationContainer";

export const OrganizationSettingsGeneralLoader = () => {
  return (
    <OrganizationContainer
      content={
        <Box
          sx={{
            paddingTop: "50px",
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            gap: "15px",
          }}
        >
          <RectangularLoader height="35px" width="550px" />
          <RectangularLoader height="35px" width="550px" />
          <RectangularLoader height="65px" width="550px" />
        </Box>
      }
    />
  );
};
