import { useEffect } from "react";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { useTestCase, useTestCases } from "../../hooks/useTestCases";
import { FieldHandlerProps, TestCase } from "../../models/types";
import { Awaitable } from "../../utilities/common";

export const TestCaseCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<TestCase[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const { data: testCase } = useTestCase(value || "");
  useEffect(() => {
    if (testCase && setObjectAttributes) {
      setObjectAttributes(testCase ?? {});
    }
  }, [testCase]);
  return (
    <SearchableDropdown<TestCase>
      label="Test Case"
      required={required}
      error={error}
      value={testCase || null}
      onChange={(newValue) => (newValue != null ? onChange(newValue.id) : onChange(null))}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [obj.name, obj.description ?? ""]}
    />
  );
};

export const TestCaseListCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<TestCase[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required, disabled } = props;
  const { data: items } = useTestCases({ id: value });
  useEffect(() => {
    if (items && items.results && items.results.length && setObjectAttributes) {
      setObjectAttributes(items.results[0] ?? {});
    }
  }, [items]);
  return (
    <SearchableDropdown<TestCase>
      label="Test Case"
      required={required}
      error={error}
      disabled={disabled}
      multiple={true}
      value={value.length === 0 ? [] : items?.results ?? []}
      onChange={(newValue) => newValue != null && onChange(newValue.map((item) => item.id))}
      getOptionLabel={(obj) => obj.name}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [obj.name, obj?.description ?? ""]}
    />
  );
};
