import { Box, Stack, Typography, useTheme } from "@mui/material";
import { SmallModal } from "../../components/SmallModal";
import { usePaywallContext } from "../../context/PaywallContext";
import { LoadingButton } from "@mui/lab";
import { NavbarIcon } from "../../atoms/navbar/Icon";
import { openIntercom } from "../../services/IntercomService";

const PaywallModal = () => {
  const { open, close, title, learnMoreCallback } = usePaywallContext();
  const theme = useTheme();
  return (
    <SmallModal
      open={open}
      onClose={close}
      size="medium"
      sx={{
        backdropFilter: "blur(2px)",
      }}
    >
      <Stack gap="10px" alignItems="center">
        <NavbarIcon
          variant="welcome-to-fairo"
          sx={{
            width: "100%",
            height: "100%",
          }}
        />
        <Box display="flex" flexDirection="column" gap="8px" paddingY="10px">
          <Box display="flex" flexDirection="column" gap="10px" alignItems="center">
            <Box gap="5px" display="flex" alignItems="center" flexDirection="row">
              <NavbarIcon
                variant="lock"
                color={theme.palette.custom.secondaryTypography}
                sx={{ width: "24px", height: "24px" }}
              />
              <Typography variant="h1" textAlign="center">
                {title}
              </Typography>
            </Box>
          </Box>
          <Typography
            variant="body1"
            textAlign="center"
            color={theme.palette.custom.secondaryTypography}
          >
            Thank you for taking the first step towards responsible and successful AI adoption.
            Contact us to upgrade your account and unlock this feature.
          </Typography>
        </Box>
        <LoadingButton
          variant="contained"
          sx={{
            width: "232px",
          }}
          onClick={() => {
            window.open("https://calendly.com/george-chamoun/fairo-upgrade-discussion", "_blank");
          }}
        >
          Contact Us
        </LoadingButton>
      </Stack>
    </SmallModal>
  );
};

export { PaywallModal };
