import { MenuItem, Select, Typography, useTheme } from "@mui/material";
import { QueryFilter } from "../models/types";
import { useState } from "react";
import ShowAll from "./ShowAll";
import { CustomDateSelectionModal } from "../components/CustomDateSelection";

type DateFilterProps = {
  dateFilters: IDateFilter[];
  onUpdate: (date: QueryFilter<any>[]) => void;
  setDateFilters: (date: IDateFilter[]) => void;
};

export interface IDateFilter {
  name: string;
  created_on_from: string;
  created_on_to: string;
  visible: boolean;
}

export const DateFilter = (props: DateFilterProps) => {
  const { dateFilters, onUpdate, setDateFilters } = props;

  const theme = useTheme();
  const [currentDescription, setCurrentDescription] = useState<string>(
    dateFilters.filter((f) => f.visible)[0].name
  );
  const [openCustomModal, setOpenCustomModal] = useState<boolean>(false);

  const description = (
    <Typography variant="h4" color={theme.palette.custom.secondaryTypography}>
      &nbsp;{currentDescription}
    </Typography>
  );

  const handleChange = (dateFilter: IDateFilter) => {
    const newArray = dateFilters.map((col) =>
      col.name === dateFilter.name ? { ...col, visible: true } : { ...col, visible: false }
    );
    setDateFilters(newArray);
    setCurrentDescription(dateFilter.name);
    onUpdate(
      dateFilter.name === "All"
        ? []
        : [
            {
              filterOption: { label: "created_on_from", value: "created_on_from" },
              value: dateFilter.created_on_from,
            },
            {
              filterOption: { label: "created_on_to", value: "created_on_to" },
              value: dateFilter.created_on_to,
            },
          ]
    );
  };

  return (
    <>
      <CustomDateSelectionModal
        open={openCustomModal}
        onClose={() => setOpenCustomModal(false)}
        onUpdate={handleChange}
      />
      <Select
        sx={{
          height: "35px",
          width: 200,
          borderRadius: "3px",
          borderColor: theme.palette.custom.gray,
        }}
        inputProps={{ style: { width: 100 } }}
        MenuProps={{
          MenuListProps: {
            sx: {
              padding: "4px",
              "& .MuiMenuItem-root": {
                padding: "1px 4px",
                gap: "5px",
                "&:hover": {
                  backgroundColor: theme.palette.custom.planBackground,
                },
              },
            },
          },
        }}
        value={1}
        // renderValue={() => description}
        renderValue={() => (
          <ShowAll
            justifyContent="flex-start"
            showIcon={false}
            isEmpty={false}
            rightComponent={description}
            title="Time:"
          />
        )}
      >
        {dateFilters.map((dateFilter, i) => (
          <MenuItem
            key={i}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              height: 30,
              ".MuiCheckbox-root": {
                color: `${theme.palette.custom.blueTypography}!important`,
              },
            }}
            onClick={() => {
              dateFilter.name === "Custom" ? setOpenCustomModal(true) : handleChange(dateFilter);
            }}
          >
            <Typography variant="body2">{dateFilter.name}</Typography>
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
