import { useQuery } from "react-query";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { fetchVendors } from "../hooks/useVendors";
import { getVendor } from "../services/VendorService";
import { getFinalNumberFromPath, getLastPathByRegex } from "../utilities/UIHelper";

const VENDOR_NAVIGATOR_KEY = "vendor-navigator";

export const useVendorPathState = (locationState: any, path: string) => {
  const vendorId = locationState?.vendorId;
  const edit = locationState?.edit;
  const lastPath = getLastPathByRegex(path);
  const number = getFinalNumberFromPath(lastPath);
  const navigate = useNavigate();
  return useQuery<{
    vendorId: string;
    edit: boolean;
  }>(
    [VENDOR_NAVIGATOR_KEY, path],
    async () => {
      if (vendorId != null) {
        return { vendorId, edit };
      }

      const list = await fetchVendors({ vendor_num: [number] });
      const obj = list.results.find((o) => o.vendor_num === Number(number));
      if (!obj) {
        navigate("/vendors");
        return { vendorId: null, edit };
      }

      return { vendorId: obj.id, edit };
    },
    { suspense: true }
  );
};

const navigateToVendor = async (params: {
  navigate: NavigateFunction;
  vendorId: string;
  vendorNumber?: number;
  edit?: boolean;
  returnLink?: boolean;
}) => {
  const { vendorId, vendorNumber, navigate, edit, returnLink = false } = params;
  let vendorNum = vendorNumber;
  if (!vendorNum) {
    const { data: vendor } = await getVendor(vendorId);
    vendorNum = vendor.vendor_num;
  }
  if (returnLink) {
    return `/oversight/vendors/vendor-${vendorNum}`;
  }
  navigate(`/oversight/vendors/vendor-${vendorNum}`, {
    state: {
      vendorId,
      edit,
    },
  });
};

export { navigateToVendor };
