import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { LinkedItem } from "../models/IWorkflow";
import { APIFetchOptions, APIGetListHook, ExecutionTask } from "../models/types";
import { IColumnVisibility } from "../molecules/ColumnVisibility";
import { EmptyTable } from "../molecules/EmptyTable";
import { editExecutionTask } from "../services/ExecutionPlanService";
import { stripHTMLTags } from "../utilities/common";
import { APITable } from "./APITable";
import { BaseTaskModal } from "./modals/tasks/BaseTaskModal";

export const GithubTaskSummary = (props: {
  task: ExecutionTask;
  isUpdate: boolean;
  setIsUpdate: (value: boolean) => void;
  disabled?: boolean;
}) => {
  const { task, isUpdate, setIsUpdate, disabled } = props;
  const [values, setValues] = useState<LinkedItem[]>([]);
  const items = task.linked_items;

  const columnsVisibility: IColumnVisibility<LinkedItem>[] = [
    {
      field: "custom_fields",
      headerName: "Type",
      visible: true,
      renderer: (item) => (
        <Button
          variant="text"
          onClick={() => window.open(item.custom_fields?.url, "_blank")}
          disabled={disabled}
        >
          {item.custom_fields?.event === "pull_request" ? "PR" : "ISSUE"}-
          {item.custom_fields?.number}
        </Button>
      ),
    },
    {
      field: "custom_fields",
      headerName: "Summary",
      visible: true,
      renderer: (item) => stripHTMLTags(item.custom_fields?.summary),
    },
    {
      field: "custom_fields",
      headerName: "Number",
      visible: true,
      renderer: (item) => item.custom_fields?.number,
    },
    {
      field: "status",
      headerName: "Status",
      visible: true,
    },
  ];

  useEffect(() => {
    setValues(items);
  }, []);

  // HACK: the table component is hard-coded to make a query to fetch items.
  // Create a fake query to return data that's already available
  const useGithubItems: APIGetListHook<LinkedItem, APIFetchOptions> = (params) => {
    const { page = 1, page_size: _pageSize = 5 } = params;

    const pageData = items.slice((page - 1) * _pageSize, page * _pageSize);
    return {
      isLoading: false,
      data: {
        count: items.length,
        next: null,
        previous: null,
        results: pageData,
      },
    };
  };

  const saveChanges = async () => {
    await editExecutionTask(task.id, {
      custom_fields: {
        ...task.custom_fields,
        github_issues: values,
      },
      linked_items: values,
    });
    setIsUpdate(false);
  };

  return (
    <>
      <BaseTaskModal
        isUpdate={isUpdate}
        setIsUpdate={setIsUpdate}
        onClose={() => {}}
        taskId={task.id}
      />
      {!isUpdate && (
        <APITable
          useGetData={useGithubItems}
          queryParams={{}}
          columnsVisibility={columnsVisibility}
          tableSize="small"
          smallEmptyHeight={true}
          emptyTableComponent={
            <EmptyTable
              variant="empty-box"
              label="External Tasks"
              description="Currently there are any external tasks connected"
            />
          }
        />
      )}
    </>
  );
};
