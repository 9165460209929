import { Box } from "@mui/material";
import ParameterOption from "./ParameterOption";
import { useState } from "react";
import { Asset, RegistryFieldType, User } from "../models/types";

interface IParametersList {
  parameters: {
    id: string;
    name: string;
    type?: string;
    parameterId?: string;
    parameterValue?: string;
    parameterValueName?: string;
  }[];
  displayAll?: boolean;
  padding?: string | number;
  onParameterValueClick?: (
    parameterId: string,
    parameterValue: string,
    parameterType: string
  ) => void;
  fetchUserDetails?: (userId: string | null) => { data?: User; isLoading: boolean };
  fetchAssetDetails?: (assetId: string) => { data?: Asset; isLoading: boolean };
  maxCount?: number;
  width?: string;
}

const ParametersList = ({
  parameters,
  displayAll,
  padding,
  onParameterValueClick,
  fetchUserDetails,
  fetchAssetDetails,
  maxCount = 2,
  width = "188px",
}: IParametersList) => {
  const moreThanTwoParams = parameters?.length! > 2;
  return (
    <Box display="flex" alignItems="center" width="100%">
      <Box
        display="flex"
        alignItems="flex-start"
        alignContent="flex-start"
        flex="1 0 0"
        flexWrap="wrap"
        padding={padding ?? "5px"}
        gap="5px"
        width={width}
      >
        {displayAll
          ? parameters?.map((parameter) => (
              <ParameterOption
                key={parameter.id}
                parameter={{ ...parameter, type: parameter.type }}
                parameterId={parameter.parameterId}
                parameterValue={parameter.parameterValue}
                onParameterValueClick={onParameterValueClick}
                parameterValueName={parameter.parameterValueName}
              />
            ))
          : parameters
              ?.slice(0, maxCount)
              .map((parameter) => (
                <ParameterOption
                  key={parameter.id}
                  parameter={{ ...parameter, type: parameter.type ?? "" }}
                  parameterId={parameter.parameterId}
                  parameterValue={parameter.parameterValue}
                  parameterValueName={parameter.parameterValueName}
                />
              ))}
        {!displayAll && moreThanTwoParams && (
          <ParameterOption count={parameters?.length! - maxCount} />
        )}
      </Box>
    </Box>
  );
};

export default ParametersList;
