import { Box, Grid, Stack, Typography, useTheme } from "@mui/material";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
import { ApiKeysLoader } from "../../organisms/skeleton-loaders/ApiKeysLoader";

export const UserAccessKeysLoader = () => {
  const theme = useTheme();
  const isSmallScreen = ScreenSmallerThen();
  const pageSize = isSmallScreen ? "unset" : `calc(80vh - 56px)`;
  return (
    <Box sx={{ maxWidth: "100%", height: pageSize, overflow: "visible" }}>
      <Box height="100%">
        <Box
          paddingBottom={"0px"}
          borderRadius="6px"
          minHeight="100%"
          maxHeight="100%"
          overflow="auto"
          sx={{ borderTopRightRadius: "0px", borderTopLeftRadius: "0px" }}
        >
          <Grid container columns={12} padding={2} display="flex" justifyContent="center">
            <Grid
              item
              xs={12}
              md={8}
              lg={6}
              bgcolor={theme.palette.background.paper}
              padding="15px"
              border={`1px solid ${theme.palette.custom.secondaryBorder}`}
              borderRadius="6px"
            >
              <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                <ApiKeysLoader title="Access Keys" />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
