import { useState } from "react";

// Helper hook to manage the content and error state of input fields
// Set the error value during validation. The error value unsets when the input value changes
export const useInputState = <T>(
  initialValue: T
): [T, (val: T) => void, boolean, (val: boolean) => void] => {
  const [value, _setValue] = useState<T>(initialValue);
  const [isError, setIsError] = useState<boolean>(false);
  const setValue = (val: T) => {
    _setValue(val);
    setIsError(false);
  };
  return [value, setValue, isError, setIsError];
};
