import moment from "moment";
import { ReactElement } from "react";
import { ObjectHooks } from "../state/QueryStoreHelper";
import { ObjectCustomField, parseObjectCustomFields } from "./IObjectCustomField";
import { parseObjectFileAttachments } from "./IObjectFileAttachment";
import { CustomFieldValue, FileAttachment } from "./types";

// Every app-object (workflow, node, etc) has a list model and a detail model.
// A lot of properties are common across objects and displayed in the UI in similar way.
// this file help us with this common functionality

/** Properties common to all objects */
interface IObjectCommonFields {
  customFields?: CustomFieldValue[];
  fileAttachments?: FileAttachment[];
}

const parseObjectCommonFields = (data: any) => {
  return {
    customFields: parseObjectCustomFields(data.custom_fields),
    fileAttachments: parseObjectFileAttachments(data.file_attachments),
  };
};

interface IBy {
  id: string;
  name: string;
}

abstract class ObjectListViewModel {
  id: string;
  objectNumber: number;
  summary: string;

  constructor(rawModelData: any, objectNumber: number) {
    this.id = rawModelData.id ?? getIdFromUrl(rawModelData.url);
    this.objectNumber = objectNumber;

    this.summary = rawModelData.summary ?? "";
  }

  abstract get objectName(): string;

  /** this will be prefixed to object number in UI. ex: `Workflow-7` */
  abstract get objectIndicatorName(): string;
  abstract get objectIndicatorIcon(): ReactElement;

  abstract get objectHooks(): ObjectHooks;
}

abstract class ObjectDetailViewModel extends ObjectListViewModel {
  description: string;
  createdBy?: IBy;
  createdTime?: Date;
  updatedBy?: IBy;
  updatedTime?: Date;
  customFields?: CustomFieldValue[];
  fileAttachments?: FileAttachment[];

  constructor(rawModelData: any, objectNumber: number) {
    super(rawModelData, objectNumber);

    this.description = rawModelData.description ?? "";
    this.createdBy = rawModelData.created_by;
    this.createdTime = parseDate(rawModelData.created_on);
    this.updatedBy = rawModelData.updated_by;
    this.updatedTime = parseDate(rawModelData.updated_on);

    if (rawModelData.custom_fields) {
      this.customFields = parseObjectCustomFields(rawModelData.custom_fields);
    }

    if (rawModelData.file_attachments) {
      this.fileAttachments = parseObjectFileAttachments(rawModelData.file_attachments);
    }
  }

  get createdByName(): string | undefined {
    return this.createdBy?.name ?? "Unknown User";
  }

  get createdTimeString(): string | undefined {
    if (!this.createdTime) return undefined;

    return moment(this.createdTime).format("MMM DD, YYYY");
  }

  get updatedByName(): string | undefined {
    return this.updatedBy?.name ?? "Unknown User";
  }

  get updatedTimeString(): string | undefined {
    if (!this.updatedTime) return undefined;

    return moment(this.updatedTime).format("MMM DD, YYYY");
  }
}

const getIdFromUrl = (url: string): string => {
  const segments = url.split("/");
  return segments[segments.length - 1];
};

const parseDate = (raw: string | undefined | null): Date | undefined => {
  if (!raw) {
    return undefined;
  }

  return new Date(raw);
};

export type { IObjectCommonFields };
export { ObjectDetailViewModel, ObjectListViewModel, parseObjectCommonFields, getIdFromUrl };
