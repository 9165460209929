import { Theme } from "@mui/material";
import { NameIconList } from "../../atoms/NameIcon";
import { getPotentialValueScoreColor, RiskScoreBadge, StatusBadge } from "../../atoms/StatusBadge";
import UserTag from "../../components/UserTag";
import { BaseFilterOptions, BaseFilters, FilterOption, UseCase } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";

export const useCaseListFilterOptions: FilterOption<BaseFilters>[] = [
  { label: "Name", value: "name" },
  ...BaseFilterOptions,
];

// Required fields: Owner, Teams, Name, Compliance Progress, Needs Attention, Overall risk, Potential Value, Lifecycle stage. (Use New table)

export const useCaseListColumnsVisibility = ({
  theme,
}: {
  theme: Theme;
}): IColumnVisibility<UseCase>[] => [
  {
    field: "name",
    headerName: "Name",
    visible: true,
    columnMinWidth: 280,
    renderer: (useCase) => <TableTextWithEllipsis value={useCase.name} />,
  },
  {
    field: "lifecycle_stage",
    headerName: "Lifecycle Stage",
    visible: true,
    columnMinWidth: 200,
    columnMaxWidth: 200,
    renderer: (useCase) => useCase.lifecycle_stage,
  },
  {
    field: "id",
    headerName: "Related Teams",
    visible: true,
    columnMinWidth: 150,
    columnMaxWidth: 150,
    renderer: (useCase) => (
      <NameIconList
        options={useCase.related_teams_names?.map((team) => team.name) ?? []}
        maxDisplay={3}
        size="small"
        twoLetters={false}
      />
    ),
  },
  {
    field: "assignee",
    headerName: "Overall Risk",
    visible: true,
    columnMinWidth: 100,
    columnMaxWidth: 100,
    renderer: (useCase) => <RiskScoreBadge size="medium" score={useCase.overall_risk} />,
  },
  {
    field: "potential_value",
    headerName: "Potential Value",
    visible: true,
    columnMinWidth: 125,
    columnMaxWidth: 125,
    renderer: (useCase) =>
      useCase?.potential_value ? (
        <RiskScoreBadge
          bgcolor={getPotentialValueScoreColor(useCase?.potential_value, theme)}
          size="medium"
          score={useCase.potential_value}
        />
      ) : (
        ""
      ),
  },
  {
    field: "assignee",
    headerName: "Owner",
    visible: true,
    columnMinWidth: 80,
    columnMaxWidth: 80,
    renderer: (useCase) =>
      useCase.assignee ? <UserTag showNameInitials hideName name={useCase?.assignee_name} /> : "",
  },
  {
    field: "status",
    headerName: "Status",
    visible: true,
    columnMinWidth: 150,
    columnMaxWidth: 150,
    renderer: (useCase) => <StatusBadge status={useCase.status ?? "NEEDS REMEDIATION"} />,
  },
];
