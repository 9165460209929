import { Box, Typography, useTheme } from "@mui/material";
import { NavbarIcon } from "../atoms/navbar/Icon";
import { PartialUser } from "../models/types";
import { defaultDateDisplay } from "../utilities/UIHelper";

interface IFormInterface {
  createdOn: string;
  createdBy: PartialUser;
  updatedOn: string;
  updatedBy: PartialUser;
  padding?: string | number | undefined | null;
  direction?: "row" | "column";
  showUpdated?: boolean;
  showCreated?: boolean;
}

const FormTimestamp = ({
  updatedBy,
  updatedOn,
  createdBy,
  createdOn,
  padding = "10px 0",
  direction = "column",
  showUpdated = true,
  showCreated = true,
}: IFormInterface) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexDirection={direction}
      alignItems={direction === "row" ? "center" : "flex-start"}
      gap={direction === "column" ? "10px" : "0px"}
    >
      {showUpdated && (
        <Typography variant="body2" sx={{ padding, display: "flex", gap: "3px", flexWrap: "wrap" }}>
          <span style={{ color: `${theme.palette.custom.timestamp}` }}>{`Updated by `}</span>
          <Typography
            variant="body2"
            display="flex"
            alignItems="center"
            gap="3px"
            color={theme.palette.custom.timestamp}
          >
            {updatedBy.name === "Fairo" && (
              <NavbarIcon
                variant="created-by-fairo"
                color="none"
                sx={{ width: "20px", height: "20px", minHeight: "20px", minWidth: "20px" }}
              />
            )}
            {updatedBy.name}
          </Typography>
          <span style={{ color: `${theme.palette.custom.timestamp}` }}>{` on ${defaultDateDisplay(
            updatedOn
          )}${direction === "row" && showCreated ? "," : ""}`}</span>
          {direction === "row" && showCreated && (
            <span style={{ color: `${theme.palette.custom.timestamp}` }}>&nbsp;</span>
          )}
        </Typography>
      )}
      {showCreated && (
        <Typography variant="body2" sx={{ display: "flex", gap: "3px", flexWrap: "wrap" }}>
          <span style={{ color: `${theme.palette.custom.timestamp}` }}>{`Created by `}</span>
          <Typography
            variant="body2"
            display="flex"
            gap="3px"
            alignItems="center"
            color={theme.palette.custom.timestamp}
          >
            {createdBy.name === "Fairo" && (
              <NavbarIcon
                variant="created-by-fairo"
                color="none"
                sx={{ width: "20px", height: "20px", minHeight: "20px", minWidth: "20px" }}
              />
            )}
            {createdBy.name}
          </Typography>
          <span style={{ color: `${theme.palette.custom.timestamp}` }}>{` on ${defaultDateDisplay(
            createdOn
          )}`}</span>
        </Typography>
      )}
    </Box>
  );
};
export default FormTimestamp;
