import { Box, Typography, TypographyProps, useTheme } from "@mui/material";
import { useState } from "react";
import { APIFetchOptions, APIGetListHook } from "../models/types";
import { TablePagination } from "../molecules/TablePagination";
import { ScreenSmallerThen } from "../utilities/UIHelper";
import { CardTableLoader } from "./skeleton-loaders/CardTableLoader";
import { StatusBadge, StatusOption } from "../atoms/StatusBadge";

type CardTableProps<T extends { id: string }, P extends APIFetchOptions> = {
  /** Hook to fetch the API data */
  useGetData: APIGetListHook<T, P>;
  params: P;
  hasBorderRadiusTop?: boolean;
  hasPaddingTop?: boolean;
  hasPaddingBottom?: boolean;
  renderItem: (item: T) => React.ReactNode;
  title?: string;
  titleVariant?: TypographyProps["variant"];
  status?: StatusOption;
  rightComponent?: React.ReactNode;
  topComponent?: React.ReactNode;
  secondComponent?: React.ReactNode;
  filters?: React.ReactNode;
  rowsPerPage?: number;
  showRowsMenu?: boolean;
  height?: string;
  padding?: string;
  showBorder?: boolean;
  rowsMenu?: number[];
  loader?: React.ReactNode;
};

export const CardTable = <T extends { id: string }, P extends APIFetchOptions>({
  useGetData,
  params,
  hasBorderRadiusTop = true,
  hasPaddingTop = true,
  hasPaddingBottom = true,
  renderItem,
  title,
  titleVariant = "h1",
  status,
  rightComponent,
  topComponent,
  secondComponent,
  filters,
  rowsPerPage: _rowsPerPage = 10,
  showRowsMenu = true,
  height = "80vh",
  padding = "20px",
  showBorder = true,
  rowsMenu = [5, 10, 20],
  loader,
}: CardTableProps<T, P>) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<number>(_rowsPerPage);
  const { data, isLoading } = useGetData({ ...params, page, page_size: rowsPerPage });
  const totalRows = data ? data.count : 0;
  const isSmallScreen = ScreenSmallerThen("900px");
  const showSingleContainerLayout = !!filters;
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: showSingleContainerLayout && !isSmallScreen ? "row" : "column",
        padding: hasPaddingTop ? "15px 0 60px 0" : hasPaddingBottom ? "0px 0 60px 0" : "0px",
        overflow: "hidden",
        gap: showSingleContainerLayout ? "0px" : "15px",
        alignItems: "flex-end",
        height: isSmallScreen ? "unset" : height,
      }}
    >
      {showSingleContainerLayout && (
        <Box
          display="flex"
          flexDirection="column"
          maxWidth={isSmallScreen ? "100%" : "235px"}
          minWidth={isSmallScreen ? "100%" : "235px"}
          width="100%"
          height="100%"
          overflow="auto"
          bgcolor={
            isDarkMode
              ? theme.palette.custom.planBackground
              : theme.palette.custom.secondaryBackground
          }
          border={`1px solid ${theme.palette.custom.secondaryBorder}`}
          borderRight={isSmallScreen ? `1px solid ${theme.palette.custom.secondaryBorder}` : 0}
          borderBottom={isSmallScreen ? 0 : `1px solid ${theme.palette.custom.secondaryBorder}`}
          sx={{
            borderBottomLeftRadius: isSmallScreen ? "0px" : "6px",
          }}
        >
          {filters}
        </Box>
      )}
      <Box
        height="100%"
        display="flex"
        width="100%"
        flexGrow={1}
        flexDirection="column"
        alignItems="flex-end"
        gap="15px"
      >
        <Box
          display="flex"
          minHeight="100%"
          maxHeight="100%"
          flexDirection="row"
          flexWrap="wrap"
          width="100%"
          overflow="auto"
          bgcolor={
            isDarkMode
              ? theme.palette.custom.planBackground
              : theme.palette.custom.secondaryBackground
          }
          border={showBorder ? `1px solid ${theme.palette.custom.secondaryBorder}` : "none"}
          borderRadius={showBorder ? "6px" : "0px"}
          sx={{
            borderTopRightRadius: hasBorderRadiusTop ? "6px" : "0",
            borderTopLeftRadius: hasBorderRadiusTop ? "6px" : "0",
            borderBottomLeftRadius: showSingleContainerLayout ? "0px" : showBorder ? "6px" : "0px",
          }}
          padding={padding}
          gap="10px"
          alignContent="flex-start"
        >
          {topComponent}
          {!!title && (
            <Box display="flex" width="100%" justifyContent="space-between" alignItems="center">
              <Box display="flex" gap="8px" alignItems="center">
                <Typography variant={titleVariant} minWidth="140px">
                  {title}
                </Typography>
                {status && <StatusBadge status={status} />}
              </Box>
              {rightComponent && rightComponent}
            </Box>
          )}
          {secondComponent && secondComponent}
          {isLoading && (loader || <CardTableLoader />)}
          {!isLoading && data?.results?.map((item) => renderItem(item))}
        </Box>
        <TablePagination
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          totalCount={totalRows}
          setRowsPerPage={setRowsPerPage}
          showRowsMenu={showRowsMenu}
          rowsMenu={rowsMenu}
          rowsTitle="Items"
          previousCursor={data?.previous}
          nextCursor={data?.next}
        />
      </Box>
    </Box>
  );
};
