import { Box, useTheme } from "@mui/material";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";

export const PluginLoader = () => {
  const theme = useTheme();
  const isSmallScreen = ScreenSmallerThen();
  const pageSize = isSmallScreen ? "unset" : "calc(80vh - 56px)";

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "flex-start",
        borderWidth: "1px",
        borderRadius: "6px",
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderColor: theme.palette.divider,
        borderStyle: "solid",
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Box sx={{ width: "100%", display: "flex", minHeight: pageSize, overflowY: "auto" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            paddingX: "20px",
            width: "100%",
            maxWidth: "235px",
            justifyContent: "flex-start",
            overflowY: "auto",
            overflowX: "hidden",
            paddingY: "15px",
            gap: "15px",
            borderRight: "1px solid",
            borderRightColor: theme.palette.custom.secondaryBorder,
          }}
        >
          <Box display="flex" gap="10px" flexWrap="wrap">
            <RectangularLoader
              maxWidth={isSmallScreen ? "100%" : "280px"}
              width="100%"
              height="35px"
            />
          </Box>
          <RectangularLoader
            maxWidth={isSmallScreen ? "100%" : "280px"}
            width="100%"
            height="35px"
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            {[...Array(8)].map((_, idx) => (
              <RectangularLoader key={idx} width="100%" height="27px" />
            ))}
          </Box>
        </Box>
        <Box
          width="100%"
          height="100%"
          padding="15px"
          gap="15px"
          display="flex"
          flexDirection="column"
          sx={{ msOverflowStyle: "auto" }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <RectangularLoader width="200px" height="35px" />
            <RectangularLoader width="200px" height="35px" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "row",
              justifyContent: "flex-start",
              rowGap: "15px",
              columnGap: "15px",
            }}
          >
            {[...Array(8)].map((_, idx) => (
              <RectangularLoader key={idx} width="calc(50% - 10px)" height="150px" />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
