import { Parameter } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const parameterEndpoints = {
  parameters: () => "/parameters",
  parameter: (parameterId: string) => `/parameters/${parameterId}`,
};

export const getParameter = (parameterId: string, params: { fairo_data: true }) => {
  return httpGetAuthenticated<Parameter>(parameterEndpoints.parameter(parameterId), {
    params,
  });
};

export const getParameters = (params: any) => {
  return httpGetAuthenticated<ListResponse<Parameter>>(parameterEndpoints.parameters(), {
    params,
  });
};

export const addParameter = async (payload: {
  field_type: string[];
  name: string;
  description?: string;
}) => {
  const { data } = await httpPostAuthenticated<Parameter>(parameterEndpoints.parameters(), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Parameter] });
  return data;
};

export const editParameter = async (
  parameterId: string,
  params: { field_type: string[]; name: string; description?: string }
) => {
  const { data } = await httpPatchAuthenticated(parameterEndpoints.parameter(parameterId), params);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Parameter] });

  return data;
};

export const deleteParameter = async (parameterId: string) => {
  const resp = await httpDeleteAuthenticated(parameterEndpoints.parameter(parameterId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Parameter] });
  return resp;
};
