import { Box, useTheme } from "@mui/material";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { ListViewLoader } from "./ListViewLoader";
import { VendorProcurementRequirement } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { AuditDetailsFormLoader } from "../../organisms/skeleton-loaders/AuditDetailsFormLoader";

export const VendorDetailsLoader = () => {
  const theme = useTheme();

  const columnsVisibility: IColumnVisibility<VendorProcurementRequirement>[] = [
    {
      field: "name",
      headerName: "Name",
      visible: true,
      columnMinWidth: 280,
    },
    {
      field: "assignee",
      headerName: "Owner",
      visible: true,
      columnMinWidth: 150,
      columnMaxWidth: 300,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
      columnMinWidth: 150,
      columnMaxWidth: 300,
    },
    {
      field: "status",
      headerName: "Status",
      visible: true,
      columnMinWidth: 150,
      columnMaxWidth: 300,
    },
    {
      field: "updated_by",
      headerName: "",
      visible: true,
      columnMinWidth: 50,
      columnMaxWidth: 50,
    },
  ];

  return (
    <Box display="flex" flexDirection="column" gap="15px" paddingBottom="80px">
      <SecondaryBox>
        <AuditDetailsFormLoader />
      </SecondaryBox>
      <SecondaryBox>
        <RectangularLoader height="200px" />
      </SecondaryBox>
      <SecondaryBox gap="10px">
        <RectangularLoader height="50px" />
        <RectangularLoader height="50px" />
        <RectangularLoader height="50px" />
      </SecondaryBox>
      <SecondaryBox>
        <RectangularLoader height="200px" />
      </SecondaryBox>
      <SecondaryBox>
        <RectangularLoader height="200px" />
      </SecondaryBox>
      <ListViewLoader
        paddingBottom={false}
        columnsVisibility={columnsVisibility}
        tableSize="small"
      />
    </Box>
  );
};
