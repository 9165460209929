import { Box, Button, Typography, useTheme } from "@mui/material";
import { INavbarIconVariant, NavbarIcon } from "../atoms/navbar/Icon";

interface ITableIconButtonProps {
  variant: INavbarIconVariant;
  backgroundColor?: string;
  fontColor?: string;
  iconColor?: string;
  light?: boolean;
  textDecoration?: string;
  label: string;
  onClick: () => void;
  disabled?: boolean;
}
export const TableIconButton = (props: ITableIconButtonProps) => {
  const {
    backgroundColor,
    fontColor,
    iconColor,
    textDecoration,
    variant,
    label,
    onClick,
    light = false,
    disabled,
  } = props;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  return (
    <Button
      variant="text"
      color="primary"
      sx={{
        textDecoration,
        color: fontColor,
        width: "100%",
        height: "auto",
        fontWeight: light ? 400 : null,
      }}
      disabled={disabled}
    >
      <Box
        onClick={() => onClick()}
        display="flex"
        flexDirection="row"
        gap="8px"
        alignItems="center"
        width="100%"
        sx={{
          cursor: "pointer",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          "&:hover": {
            whiteSpace: "pre-wrap",
            transition: "max-height 2s",
            textOverflow: "unset",
            maxHeight: "100%",
          },
        }}
      >
        <NavbarIcon
          variant={variant}
          backgroundColor={backgroundColor ?? theme.palette.custom.blueTypography}
          color={iconColor ?? "#FFFFFF"}
          sx={{ width: "25px", height: "25px", minHeight: "25px", minWidth: "25px" }}
        />
        {label}
      </Box>
    </Button>
  );
};
