import { Box } from "@mui/material";
import { StringCustomField } from "../../components/CustomFieldForm";
import { TableTextWithEllipsis } from "../TableTextWithEllipsis";

type NumberWithUnitCustomFieldProps = {
  value: string | null;
  onChange: (newValue: string | null) => void;
  required?: boolean;
  valueError?: boolean;
  errorMessage?: string;
};
export const NumberWithUnitCustomField = ({
  value,
  onChange,
  required,
  valueError,
  errorMessage,
}: NumberWithUnitCustomFieldProps) => {
  type NumberWithUnit = {
    value: string;
    unit: string;
  };
  const parsedValue: NumberWithUnit =
    value && value !== "" ? JSON.parse(value) : { number: "", unit: "" };
  return (
    <Box display="flex" alignItems="center" gap="10px">
      <StringCustomField
        required={required}
        label="Number"
        value={`${parsedValue?.value || ""}`}
        onChange={(e) => {
          onChange(JSON.stringify({ value: e.replace(/\D/g, ""), unit: parsedValue?.unit ?? "" }));
        }}
        error={valueError ?? false}
        fullWidth={false}
        errorMessage={errorMessage ?? ""}
      />
      <StringCustomField
        label="Unit"
        required={required}
        value={parsedValue?.unit}
        fullWidth={false}
        onChange={(e) => {
          onChange(JSON.stringify({ value: parsedValue?.value ?? "", unit: e }));
        }}
        error={valueError ?? false}
        errorMessage={errorMessage ?? ""}
      />
    </Box>
  );
};

export const NumberWithUnitReadOnly = ({ value }: { value: string | null | string[] | number }) => {
  try {
    if (typeof value !== "string") {
      return <TableTextWithEllipsis value="Failed to parse value" />;
    }
    const parsedValue = JSON.parse(value);
    return <TableTextWithEllipsis value={`${parsedValue.value} ${parsedValue.unit}`} />;
  } catch (e) {
    return <TableTextWithEllipsis value="Failed to parse value" />;
  }
};
