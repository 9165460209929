import { format, parseISO } from "date-fns";
import { StatusBadge } from "../../atoms/StatusBadge";
import { BaseFilterOptions, BaseFilters, FilterOption, WorkflowRun } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { RunHistoryThreeDotsMenu } from "../../organisms/RunHistoryThreeDotsMenu";
import { defaultDateDisplay } from "../UIHelper";

export const runHistoryFilterOptions: FilterOption<BaseFilters>[] = [...BaseFilterOptions];

export const runHistoryColumnsVisibility = ({
  onReRun,
}: {
  onReRun: () => void;
}): IColumnVisibility<WorkflowRun>[] => [
  {
    field: "id",
    headerName: "Run ID",
    visible: true,
    columnMinWidth: 150,
    renderer: (row) => <TableTextWithEllipsis value={`Run-${row.workflow_run_num}`} />,
  },
  {
    field: "id",
    headerName: "Triggered At",
    visible: true,
    columnMinWidth: 180,
    columnMaxWidth: 180,
    renderer: (row) => (
      <TableTextWithEllipsis
        value={`${defaultDateDisplay(row.created_on)} ${format(parseISO(row.created_on), "HH:mm")}`}
      />
    ),
  },
  {
    field: "id",
    headerName: "Finished At",
    visible: true,
    columnMaxWidth: 180,
    columnMinWidth: 180,
    renderer: (row) => (
      <TableTextWithEllipsis
        value={`${defaultDateDisplay(row.updated_on)} ${format(parseISO(row.updated_on), "HH:mm")}`}
      />
    ),
  },
  {
    field: "id",
    headerName: "Status",
    visible: true,
    columnMaxWidth: 120,
    columnMinWidth: 120,
    renderer: (element) => <StatusBadge status={element.status as any} />,
  },
  {
    field: "id",
    headerName: "",
    visible: true,
    columnMinWidth: 50,
    columnMaxWidth: 50,
    renderer: (runHistory) => {
      return <RunHistoryThreeDotsMenu onReRun={onReRun} />;
    },
  },
];
