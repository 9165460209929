import LoadingButton from "@mui/lab/LoadingButton";
import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import useTheme from "@mui/material/styles/useTheme";
import { useEffect, useState } from "react";
import DeleteIconButton from "../../../components/DeleteIconButton";
import { SmallModal } from "../../../components/SmallModal";
import { openSnackbar } from "../../../context/SnackbarContext";
import { Tag } from "../../../models/types";
import { addTag, deleteObjectTag, deleteTag, editTag, getTag } from "../../../services/TagService";
import { CustomAxiosError } from "../../../utilities/ErrorResponseHelper";

type CreateTagModalProps = {
  /** Should the modal be open? */
  open: boolean;
  /** Callback to close the modal */
  onClose: () => void;
  /** Tag ID */
  tagId: string | null;
  objectTagId?: string | null;
  objectName?: string;
};

export const CreateTagModal = (props: CreateTagModalProps) => {
  const { open, onClose, tagId, objectTagId, objectName } = props;
  const theme = useTheme();
  const [name, setName] = useState<string>("");
  const [nameError, setNameError] = useState<string>("");
  const [colorError, setColorError] = useState<string>("");
  const [tag, setTag] = useState<Tag | null>(null);
  const [color, setColor] = useState<string>(theme.palette.custom.redTypography);
  const [loading, setLoading] = useState<boolean>(false);
  const [isTagLoading, setIsTagLoading] = useState<boolean>(false);
  const innerTextColor = theme.palette.text.secondary;

  const fetchTag = async () => {
    try {
      if (tagId) {
        const { data } = await getTag(tagId);
        setTag(data);
        setName(data.name);
        setColor(data.color);
        setIsTagLoading(false);
      }
    } catch (error) {
      if (error instanceof CustomAxiosError) {
        error.showAuditLogSnackbar("Failed to fetch tag");
      }
    }
  };

  const isValidHexColorRegex = (value: string): boolean => {
    // accept between 1 and 6 characters
    return /^#[0-9A-Fa-f]{6}$/i.test(value);
  };

  const onSave = async () => {
    try {
      setLoading(true);
      if (name.trim() === "") {
        setNameError("Name is required");
        return;
      }
      setNameError("");
      if (!isValidHexColorRegex(color)) {
        setColorError("Invalid hex color");
        return;
      }
      setColorError("");
      if (tag) {
        // update tag
        await editTag(tag.id, { name, color });
      } else {
        await addTag({ name, color });
      }
      openSnackbar(`Tag successfully ${tag ? "updated" : "created"}`, "success");
      onClose();
    } catch (error: any) {
      if (error?.response?.data?.includes("Tag with this name already exists")) {
        setNameError("Tag with this name already exists");
      } else {
        if (error instanceof CustomAxiosError) {
          error.showAuditLogSnackbar(`Failed to ${tag ? "update" : "create"} tag`);
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const cleanInvalidCharsForHexColor = (value: string): string => {
    // also check the limit of hex color length
    if (value.length > 7) {
      return value.slice(0, 7);
    }
    return `#${value.replace(/[^0-9A-F]/gi, "")}`;
  };

  useEffect(() => {
    setIsTagLoading(true);
    if (tagId) {
      fetchTag();
    } else {
      setTag(null);
      setColor(theme.palette.custom.redTypography);
      setName("");
      setIsTagLoading(false);
    }
  }, [tagId]);

  return (
    <SmallModal
      open={open}
      onClose={() => onClose()}
      title={tagId ? "Edit Tag" : "Create Tag"}
      rightComponent={
        tag &&
        objectTagId && (
          <Box display="flex" flexDirection="row" gap="5px">
            <DeleteIconButton
              deleteConfirmationDialogTitle="Remove Tag"
              deleteConfirmationButtonDescription="Remove"
              deleteConfirmationDialogDescription={`Are you sure you want to remove Tag ${
                tag?.name
              }${objectTagId ? ` from ${objectName}` : ""}?`}
              onDeleteConfirm={async () => {
                if (objectTagId) {
                  await deleteObjectTag(objectTagId!);
                } else {
                  await deleteTag(tag.id);
                }
                onClose();
              }}
              filledButton
            />
          </Box>
        )
      }
      isLoading={isTagLoading}
    >
      <Box display="flex" flexDirection="column" gap="10px">
        <Typography color={innerTextColor} variant="h3">
          Name
        </Typography>
        <TextField
          error={nameError !== ""}
          helperText={nameError}
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
          label="Enter Name"
        />
        <Typography color={innerTextColor} variant="h3">
          Color
        </Typography>
        <ColorList
          onChangeColor={(selectedColor: string) => setColor(selectedColor)}
          colors={[
            theme.palette.custom.redTypography,
            "#FA945B",
            theme.palette.custom.yellowTypography,
            theme.palette.custom.greenTypography,
            "#5FEBB8",
            "#5FD2EB",
            "#5FA8EB",
            "#5F75EB",
            "#835FEB",
            "#D75FEB",
          ]}
        />
        <Box display="flex" flexDirection="row" alignItems="center" gap="8px">
          <ColorBox size="35px" selected={true} onClick={() => {}} color={color} />
          <TextField
            value={color}
            onChange={(e) => setColor(cleanInvalidCharsForHexColor(e.target.value))}
            label="Color"
          />
        </Box>
        {colorError && (
          <Typography fontSize="12px" variant="body2" color="error">
            {colorError}
          </Typography>
        )}
        <LoadingButton
          variant="contained"
          onClick={() => onSave()}
          disabled={name === ""}
          loading={loading}
        >
          {tag ? "Save" : "Create"}
        </LoadingButton>
      </Box>
    </SmallModal>
  );
};

export const ColorList = (props: { colors: string[]; onChangeColor: (color: string) => void }) => {
  const { colors, onChangeColor } = props;
  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="row"
      justifyContent="space-around"
      flexWrap="wrap"
      gap="8px"
    >
      {colors.map((color, index) => (
        <ColorBox onClick={onChangeColor} key={index} color={color} />
      ))}
    </Box>
  );
};

export const ColorBox = (props: {
  size?: string;
  color: string;
  selected?: boolean;
  onClick: (color: string) => void;
}) => {
  const { color, onClick, selected, size = "30px" } = props;
  const theme = useTheme();
  return (
    <Box
      border={selected ? `1px solid ${theme.palette.custom.secondaryBorder}` : "none"}
      onClick={() => onClick(color)}
      width={size}
      height={size}
      minHeight={size}
      minWidth={size}
      sx={{ backgroundColor: color, cursor: "pointer" }}
      borderRadius="3px"
    />
  );
};
