import { Box, Typography, useTheme } from "@mui/material";
import { transform } from "lodash";
import { forwardRef, useState } from "react";
import { Question, Questionnaire } from "../../models/types";
import { CustomInputValidation } from "../../organisms/CustomInputValidation";
import { isListType } from "../../utilities/UIHelper";
import { DisplayFinalAnswer } from "./DisplayFinalAnswer";

type QuestionDisplayProps = {
  questionnaire: Questionnaire;
  questionIndex: number;
  question: Question;
  value: string | null | string[];
  onChange: (value: string | null) => void;
  valueError?: boolean;
};

export const QuestionDisplay = forwardRef(
  (
    {
      question,
      questionnaire,
      questionIndex,
      value,
      onChange,
      valueError = false,
    }: QuestionDisplayProps,
    ref
  ) => {
    const [opacity, setOpacity] = useState(1);
    const theme = useTheme();
    const textColor = theme.palette.custom.secondaryTypography;
    const isListTypeField = isListType(question.type);

    const isQuestionnaireFinalized = questionnaire.status === "FINALIZED";

    return (
      <Box display="flex" flexDirection="column" gap="10px">
        <Typography variant="h4">{questionnaire.name ?? ""}</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            transition: "transform .2s ease-out, opacity 0s ease-out",
            transform: `translateY(${transform}%)`,
            opacity: opacity,
          }}
        >
          {(!isListTypeField || isQuestionnaireFinalized) && (
            <>
              <Typography variant="h2" color={textColor}>
                {questionIndex + 1}- {question?.title ?? ""}
              </Typography>
              <Typography variant="body2" color={theme.palette.custom.secondaryTypography}>
                {question?.description ?? ""}
              </Typography>
            </>
          )}
          {!isQuestionnaireFinalized && (
            <>
              <CustomInputValidation
                ref={ref}
                choices={questionnaire?.questions[questionIndex]?.choices ?? undefined}
                customExtraComponent={
                  isListTypeField ? (
                    <Box display="flex" flexDirection="column" gap="10px">
                      <Typography variant="h2" color={textColor}>
                        {questionIndex + 1}- {questionnaire?.questions[questionIndex]?.title ?? ""}
                      </Typography>
                      <Typography variant="body2" color={textColor}>
                        {questionnaire?.questions[questionIndex]?.description ?? ""}
                      </Typography>
                    </Box>
                  ) : (
                    <></>
                  )
                }
                value={value}
                valueError={false}
                errorMessage={""}
                required={question.required}
                field_type={question.type}
                onChange={onChange}
                customModalTitle={questionnaire?.questions[questionIndex]?.title ?? ""}
              />
              {valueError && (
                <Typography variant="body2" color={theme.palette.custom.redTypography}>
                  *Required Field
                </Typography>
              )}
            </>
          )}
          {isQuestionnaireFinalized && (
            <DisplayFinalAnswer
              isFinalized={isQuestionnaireFinalized}
              question={question}
              questionnaireId={questionnaire.id}
            />
          )}
        </Box>
      </Box>
    );
  }
);
