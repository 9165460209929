import { useEffect } from "react";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { useWorkflow } from "../../hooks/useWorkflow";
import { FieldHandlerProps, Workflow } from "../../models/types";
import { Awaitable } from "../../utilities/common";
import { useWorkflows } from "../../hooks/useWorkflows";

export const WorkflowCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<Workflow[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const { data: workflow } = useWorkflow(value || "");
  useEffect(() => {
    if (workflow && setObjectAttributes) {
      setObjectAttributes(workflow ?? {});
    }
  }, [workflow]);
  return (
    <SearchableDropdown<Workflow>
      label="Workflow"
      required={required}
      error={error}
      value={workflow || null}
      onChange={(newValue) => (newValue != null ? onChange(newValue.id) : onChange(null))}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [obj.summary, obj.description ?? ""]}
    />
  );
};

export const WorkflowListCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<Workflow[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const { data: items } = useWorkflows({ id: value });
  useEffect(() => {
    if (items && items.results && items.results.length && setObjectAttributes) {
      setObjectAttributes(items.results[0] ?? {});
    }
  }, [items]);
  return (
    <SearchableDropdown<Workflow>
      label="Workflow"
      required={required}
      error={error}
      multiple={true}
      value={value.length === 0 ? [] : items?.results ?? []}
      onChange={(newValue) => newValue != null && onChange(newValue.map((item) => item.id))}
      getOptionLabel={(obj) => obj.summary}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [obj.summary, obj?.description ?? ""]}
    />
  );
};
