import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { openSnackbar } from "../context/SnackbarContext";

export class CustomAxiosError extends AxiosError {
  constructor(
    message: string,
    config?: InternalAxiosRequestConfig,
    code?: string,
    request?: any,
    response?: AxiosResponse
  ) {
    super(message, code, config, request, response);
  }

  showAuditLogSnackbar(message: string, callback: () => void = () => {}): void {
    try {
      const requestId = this.response?.headers["audit-id"];
      if (requestId) {
        openSnackbar(message, "error", callback, requestId);
      } else {
        openSnackbar(message, "error", callback);
      }
    } catch (error) {
      openSnackbar(message, "error");
    }
  }
}
