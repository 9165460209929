import config from "../config/config";

export const setCookie = (cname: string, cvalue: string) => {
  document.cookie = cname + "=" + cvalue + `;domain=${config.appHostname};path=/;`;
};

export const getCookie = (cname: string) => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  // iterate in reverse order to get latest value
  for (let i = ca.length - 1; i >= 0; i--) {
    let c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const deleteCookie = (cname: string) => {
  document.cookie =
    cname + `=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${config.appHostname};path=/;`;
};
