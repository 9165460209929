import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { NavbarIcon } from "../../atoms/navbar/Icon";

type YesOrNoSelectFieldProps = {
  value: string;
  onChange: (newValue: string) => void;
  required?: boolean;
  yesLabel?: string;
  noLabel?: string;
};

const Option = (props: { onClick: () => void; label: string; selected: boolean }) => {
  const theme = useTheme();
  return (
    <Box
      border={`1px solid ${theme.palette.custom.primaryBorder}`}
      borderRadius="3px"
      onClick={props.onClick}
      sx={{
        cursor: "pointer",
        "&:hover": {
          backgroundColor: theme.palette.custom.selectedButton,
        },
        backgroundColor: props.selected
          ? theme.palette.custom.selectedButton
          : theme.palette.custom.secondaryBackground,
      }}
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <IconButton>
        <NavbarIcon
          variant={props.selected ? "radio-checked" : "radio-unchecked"}
          sx={{
            height: "18px",
            width: "18px",
            minHeight: "18px",
            minWidth: "18px",
          }}
        />
      </IconButton>
      <Typography
        fontWeight={props.selected ? 600 : 400}
        variant="h4"
        color={theme.palette.custom.secondaryTypography}
      >
        {props.label}
      </Typography>
    </Box>
  );
};

export const YesOrNoSelectField = (props: YesOrNoSelectFieldProps) => {
  return (
    <Box display="flex" flexDirection="column" width="100%" gap="10px">
      <Option
        onClick={() => props.onChange("Yes")}
        label={props.yesLabel ?? "Yes"}
        selected={props.value === "Yes"}
      />
      <Option
        onClick={() => props.onChange("No")}
        label={props.noLabel ?? "No"}
        selected={props.value === "No"}
      />
    </Box>
  );
};
