import { ImpactAssessmentType } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { httpGetAuthenticated, httpPatchAuthenticated } from "./ApiService";

const impactAssessmentTypeEndpoints = {
  impactAssessmentTypes: () => "/impact_assessment_types",
  impactAssessmentType: (impactAssessmentTypeId: string) =>
    `/impact_assessment_types/${impactAssessmentTypeId}`,
};

export const getImpactAssessmentTypes = (params: any) => {
  return httpGetAuthenticated<ListResponse<ImpactAssessmentType>>(
    impactAssessmentTypeEndpoints.impactAssessmentTypes(),
    {
      params: {
        ...params,
        fairo_data: true,
      },
    }
  );
};

export const getImpactAssessmentType = (impactAssessmentTypeId: string) => {
  return httpGetAuthenticated<ImpactAssessmentType>(
    impactAssessmentTypeEndpoints.impactAssessmentType(impactAssessmentTypeId),
    {
      params: {
        fairo_data: true,
      },
    }
  );
};

export const editImpactAssessmentType = async (
  impactAssessmentTypeId: string,
  payload: {
    name?: string;
    description?: string;
    questionnaire?: string;
    status?: string;
  }
) => {
  const { data } = await httpPatchAuthenticated(
    impactAssessmentTypeEndpoints.impactAssessmentType(impactAssessmentTypeId),
    payload
  );
  queryClient.invalidateQueries({
    queryKey: [QueryKey.ImpactAssessmentType, impactAssessmentTypeId],
  });
  return data;
};
