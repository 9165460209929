import { useQuery } from "react-query";
import queryClient, { QueryKey } from "../state/QueryStore";
import { PolicyFetchOptions } from "../models/types";
import { getPolicies, getPolicy } from "../services/PolicyService";

export const usePolicies = (params: PolicyFetchOptions) => {
  return useQuery([QueryKey.Policy, params], async () => {
    try {
      const { data } = await getPolicies(params);
      return data;
    } catch (error) {
      return { count: 0, next: null, previous: null, results: [] };
    }
  });
};

export const usePolicy = (policyId: string) => {
  return useQuery([QueryKey.Policy, policyId], async () => {
    const { data } = await getPolicy(policyId);
    return data;
  });
};

export const fetchPolicies = (params: PolicyFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.Policy, params], async () => {
    const { data } = await getPolicies(params);
    return data;
  });
};

export const fetchPolicy = (policyId: string) => {
  return queryClient.fetchQuery([QueryKey.Policy, policyId], async () => {
    const { data } = await getPolicy(policyId);
    return data;
  });
};

export const searchPolicies = async (searchText: string) => {
  const parameters = await fetchPolicies({ search: searchText });
  return parameters.results;
};
