import { Box, Stack, Typography, useTheme } from "@mui/material";
import { useImpactAssessmentTypes } from "../../../hooks/useImpactAssessmentType";
import { RectangularLoader } from "../../../molecules/skeleton-loader/RectangularLoader";
import { INavbarIconVariant, NavbarIcon } from "../../../atoms/navbar/Icon";
import { useSubscription } from "../../../context/SubscriptionContext";
import { FeaturePaywallModal } from "../FeaturePaywallModal";
import { useState } from "react";
import { getPlanHierarchy } from "../../../utilities/PlanHierarchy";
import { stripHTMLTags } from "../../../utilities/common";

type SelectAssessmentTypeProps = {
  onAssessmentTypeSelect: (
    assessmentType: string,
    assessmentTypeName: string,
    assessmentTypeQuestionnaires: any
  ) => void;
};

export const SelectAssessmentType = (props: SelectAssessmentTypeProps) => {
  const theme = useTheme();
  const { currentPlan } = useSubscription();

  const { data: impactAssessmentTypes, isLoading: isLoadingImpactAssessmentTypes } =
    useImpactAssessmentTypes({});

  const getIcon = (status: string): INavbarIconVariant => {
    switch (status) {
      case "Microsoft RAI Impact Assessment":
        return "microsoft-rai";
      case "U.S. CIO Council Algorithmic Impact Assessment":
        return "us-cio-coucil-algorithmic";
      case "OCED Algorithmic Impact Assessment":
        return "oced-algorithmic";
      case "Canadian Algorithmic Impact Assessment Tool":
        return "canadian-algorithmic";
      case "EQUAL AI Algorithmic Impact Assesment":
        return "equal-ai-algorithmic";
      default:
        return "questionnaire";
    }
  };

  const isEnterprise = currentPlan?.name === "Fairo Enterprise";
  const [openFeaturePaywallModal, setOpenFeaturePaywallModal] = useState(false);

  return (
    <>
      <FeaturePaywallModal
        open={openFeaturePaywallModal}
        onClose={() => setOpenFeaturePaywallModal(false)}
        route="impact-assessment-types"
      />
      <Stack gap="10px" alignItems="center">
        <Typography variant="h1">Select Impact Assessment Type</Typography>
        <Typography variant="body2">Let’s start by selecting the desired impact type.</Typography>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "flex-start",
            rowGap: "10px",
            columnGap: "10px",
            width: "100%",
          }}
        >
          {isLoadingImpactAssessmentTypes
            ? [...Array(6)].map((_, idx) => (
                <RectangularLoader width="calc(50% - 5px)" height="85px" />
              ))
            : impactAssessmentTypes?.results
                .sort((a, b) => {
                  return a.impact_assessment_type_num - b.impact_assessment_type_num;
                })
                .map((impactAssessmentType) => (
                  <Box
                    display="flex"
                    padding="5px"
                    width="calc(50% - 5px)"
                    borderRadius="3px"
                    border={`1px solid ${theme.palette.custom.secondaryBorder}`}
                    onClick={() => {
                      if (
                        getPlanHierarchy(currentPlan?.name ?? "") <
                        getPlanHierarchy(impactAssessmentType.minimum_plan)
                      ) {
                        setOpenFeaturePaywallModal(true);
                      } else {
                        props.onAssessmentTypeSelect(
                          impactAssessmentType.id,
                          impactAssessmentType.name,
                          impactAssessmentType.questionnaire ?? ""
                        );
                      }
                    }}
                    alignItems="center"
                    sx={{
                      ":hover": {
                        cursor: "pointer",
                      },
                    }}
                  >
                    <NavbarIcon
                      variant={getIcon(impactAssessmentType.name)}
                      sx={{
                        width: "80px",
                        height: "80px",
                      }}
                    />
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="5px"
                      justifyContent="center"
                      width="100%"
                    >
                      <Box
                        display="flex"
                        gap="10px"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Typography variant="h3">{impactAssessmentType.name}</Typography>
                        {getPlanHierarchy(currentPlan?.name ?? "") <
                          getPlanHierarchy(impactAssessmentType.minimum_plan) && (
                          <NavbarIcon
                            variant="lock"
                            sx={{
                              width: "24px",
                              height: "24px",
                            }}
                          />
                        )}
                      </Box>
                      <Typography variant="body2">
                        {stripHTMLTags(impactAssessmentType.description ?? "")}
                      </Typography>
                    </Box>
                  </Box>
                ))}
        </Box>
      </Stack>
    </>
  );
};
