import { Box, Button, useTheme } from "@mui/material";
import { INavbarIconVariant, NavbarIcon } from "../atoms/navbar/Icon";

interface IButtonIconProps {
  label: string;
  action: (data: any) => void;
  variant: INavbarIconVariant;
  emptyBackground?: boolean;
  iconColor?: string;
  iconSize?: string;
  disabled?: boolean;
}

const ButtonIcon = (props: IButtonIconProps) => {
  const theme = useTheme();
  const { label, action, variant, emptyBackground = false, iconColor, iconSize, disabled } = props;
  const width = iconSize ? iconSize : "15px";
  const height = iconSize ? iconSize : "15px";
  const color = disabled ? theme.palette.custom.grayTypography : iconColor ? iconColor : "white";
  const BoxWithIcon = () => (
    <Box
      onClick={disabled ? () => {} : action}
      display="flex"
      flexDirection="row"
      gap="5px"
      alignItems="center"
      sx={{ cursor: disabled ? "auto" : "pointer" }}
    >
      <NavbarIcon
        variant={variant}
        color={color}
        sx={{ width, height, minWidth: width, minHeight: height }}
      />
      {label}
    </Box>
  );

  return emptyBackground ? (
    BoxWithIcon()
  ) : (
    <Button onClick={action} fullWidth={true} disabled={disabled} variant={"contained"}>
      {BoxWithIcon()}
    </Button>
  );
};

export { ButtonIcon };
