import { Box, useTheme } from "@mui/material";
import { NavbarIcon } from "./Icon";

type NavbarDirections = "left" | "right";
interface INavbarArrowProps {
  direction: NavbarDirections;
  onClick: () => void;
  marginLeft?: string;
  disabled?: boolean;
}

const NavbarArrow = ({ onClick, marginLeft, direction, disabled }: INavbarArrowProps) => {
  const theme = useTheme();
  return (
    <Box
      onClick={() => (disabled ? {} : onClick())}
      sx={{
        borderRadius: "25px",
        color: "#FFFFFF",
        cursor: "pointer",
        backgroundColor: disabled
          ? theme.palette.custom.disabledButton
          : theme.palette.custom.navArrowBackground,
        width: "24px",
        height: "24px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: marginLeft ?? 0,
      }}
    >
      <NavbarIcon
        variant={direction === "left" ? "left-arrow" : "right-arrow"}
        color="#FFFFFF"
        sx={{ width: "12.8px", height: "12.8px", minHeight: "12.8px", minWidth: "12.8px" }}
      />
    </Box>
  );
};

export default NavbarArrow;
