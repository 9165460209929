import { useQuery } from "react-query";
import { RoleFetchOptions } from "../models/types";
import { getRoles } from "../services/DataService";
import queryClient, { QueryKey } from "../state/QueryStore";
import { getRole } from "../services/RoleService";
import { getVersionHistory } from "../services/VersionHistoryService";

export const useRoles = (params: RoleFetchOptions) => {
  return useQuery([QueryKey.Role, params], async () => {
    const { data } = await getRoles(params);
    return data;
  });
};

export const fetchRoles = (params: RoleFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.Role, params], async () => {
    const { data } = await getRoles(params);
    return data;
  });
};

export const searchRoles = async (searchText: string) => {
  const { results } = await fetchRoles({ search: searchText });
  return results;
};

export const useRole = (roleId: string, version_num?: number) => {
  const searchForVersionNum = !!version_num;
  const queryKey = searchForVersionNum
    ? [QueryKey.Role, roleId, version_num]
    : [QueryKey.Role, roleId];
  const queryFn = searchForVersionNum
    ? async () => {
        const { data } = await getVersionHistory({
          object_id: [roleId],
          version_num: [version_num],
        });
        return JSON.parse(data.results[0].object);
      }
    : async () => {
        const { data } = await getRole(roleId);
        return data;
      };
  return useQuery(queryKey, queryFn);
};

export const fetchRole = (roleId: string) => {
  return queryClient.fetchQuery([QueryKey.Role, roleId], async () => {
    const { data } = await getRole(roleId);
    return data;
  });
};
