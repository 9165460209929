import { useNavigate } from "react-router-dom";
import { navigateToAsset } from "../components/AssetNavigator";
import { useAsset } from "../hooks/useAssets";
import { useUsers } from "../services/UserSettingsService";
import { BadgeOption } from "./BadgeOption";

interface IParametersList {
  parameter?: { id: string; name: string; type?: string };
  count?: number;
  parameterId?: string;
  parameterValue?: string;
  parameterValueName?: string;
  onParameterValueClick?: (
    parameterId: string,
    parameterValue: string,
    parameterType: string
  ) => void;
}

const ParameterOption = ({
  parameter,
  count,
  parameterId,
  parameterValue,
  onParameterValueClick,
  parameterValueName,
}: IParametersList) => {
  const navigate = useNavigate();
  const isCount = !!count;
  const isUserType = parameter?.type === "User";
  const isAssetType = parameter?.type === "Asset";
  const { data: user } = useUsers(isUserType ? parameterValue ?? "" : "");
  const { data: asset } = useAsset(isAssetType ? parameterValue ?? "" : "");

  const getParameterValue = () => {
    if (isCount) return ` +${count}`;
    if (isUserType && user) return `${parameter?.name}: ${parameterValueName}`;
    if (isAssetType && asset) return `${parameter.name}: `;
    if (parameterValue && parameterValue !== "") return `${parameter?.name}: ${parameterValueName}`;
    return parameter?.name ?? "";
  };

  return isAssetType ? (
    <BadgeOption
      onClick={() => {
        if (onParameterValueClick) {
          onParameterValueClick(parameterId!, parameterValue!, parameter?.type!);
        }
      }}
      hyperlinkOnClick={() => {
        asset &&
          navigateToAsset({
            navigate,
            assetId: asset?.id,
            assetNumber: asset?.asset_num,
            asset,
          });
      }}
      name={getParameterValue()}
      hyperlink={parameterValueName}
    />
  ) : (
    <BadgeOption
      onClick={() => {
        if (onParameterValueClick) {
          onParameterValueClick(parameterId!, parameterValue!, parameter?.type!);
        }
      }}
      name={getParameterValue()}
    />
  );
};

export default ParameterOption;
