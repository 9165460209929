import { Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  Audit,
  AuditLog,
  ComplianceElement,
  Metric,
  Policy,
  Report,
  Role,
  TestCase,
  Vendor,
  VersionHistory,
} from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { riskScenarioListColumnsVisibility } from "../../organisms/compliance/tables/RiskScenarioListTableColumns";
import { evidenceTestListColumnsVisibility } from "../../organisms/impact-assessment/EvidenceTestListColumnsVisibility";
import { impactAssessmentListColumnsVisibility } from "../../organisms/impact-assessment/ImpactAssessmentListColumnsVisibility";
import { useCaseListColumnsVisibility } from "../../organisms/impact-assessment/UseCaseListColumnsVisibility";
import { APITableLoader } from "../../organisms/skeleton-loaders/APITableLoader";
import { externalResourceColumnsVisibility } from "../../organisms/tables/assets/ExternalResourceTableColumnsList";
import { ScreenSmallerThen } from "../../utilities/UIHelper";
import { actionListColumnsVisibility } from "../../utilities/columns-visibility/ActionListColumnsVisibility";
import {
  assetListColumnsVisibility,
  datasetListColumnsVisibility,
  modelListColumnsVisibility,
} from "../../utilities/columns-visibility/AssetListColumnsVisibility";
import { workflowListcolumnsVisibility } from "../../utilities/columns-visibility/WorkflowListColumnsVisibility";

type ListViewLoaderProps = {
  type?: string;
  paddingTop?: boolean;
  paddingBottom?: boolean;
  bigFixedHeight?: string;
  columnsVisibility?: IColumnVisibility<any>[];
  tableSize?: "big" | "small";
};

export const ListViewLoader = (props: ListViewLoaderProps) => {
  const {
    type,
    paddingTop = true,
    paddingBottom = true,
    bigFixedHeight = "80vh",
    columnsVisibility,
    tableSize = "big",
  } = props;
  const theme = useTheme();
  const navigate = useNavigate();

  const assetColumnsVisibility = assetListColumnsVisibility;

  const datasetColumnsVisibility = datasetListColumnsVisibility;

  const modelsColumnsVisibility = modelListColumnsVisibility;

  const workflowColumnsVisibility = workflowListcolumnsVisibility;

  const actionColumnsVisibility = actionListColumnsVisibility;

  const roleColumnsVisibility: IColumnVisibility<Role>[] = [
    {
      field: "role_num",
      headerName: "Role",
      visible: true,
      columnMaxWidth: 100,
      columnMinWidth: 100,
    },
    {
      field: "name",
      headerName: "Name",
      visible: true,
      columnMinWidth: 400,
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
      columnMaxWidth: 250,
      columnMinWidth: 200,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
      columnMaxWidth: 120,
      columnMinWidth: 120,
    },
  ];

  const policyColumnsVisibility: IColumnVisibility<Policy>[] = [
    {
      field: "policy_num",
      headerName: "Policy",
      visible: true,
      columnMaxWidth: 600,
      columnMinWidth: 150,
    },
    {
      field: "description",
      headerName: "Description",
      visible: true,
      columnMaxWidth: 600,
      columnMinWidth: 150,
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
      columnMaxWidth: 250,
      columnMinWidth: 200,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
      columnMaxWidth: 120,
      columnMinWidth: 120,
    },
    {
      field: "id",
      headerName: "",
      visible: true,
      columnMaxWidth: 80,
      columnMinWidth: 80,
    },
  ];

  const libraryColumnsVisibility: IColumnVisibility<ComplianceElement>[] = [
    {
      field: "external_id",
      headerName: "ID",
      visible: true,
      columnMinWidth: 180,
      columnMaxWidth: 180,
    },
    {
      field: "name",
      headerName: "Name",
      visible: true,
      columnMinWidth: 400,
    },
    {
      field: "element_type",
      headerName: "Type",
      visible: true,
      columnMinWidth: 200,
      columnMaxWidth: 200,
    },
    {
      field: "framework",
      headerName: "Frameworks",
      visible: true,
      columnMinWidth: 180,
      columnMaxWidth: 180,
    },
    {
      field: "status",
      headerName: "Status",
      visible: true,
      columnMaxWidth: 120,
      columnMinWidth: 120,
    },
    {
      field: "assignee",
      headerName: "Owner",
      visible: true,
      columnMaxWidth: 250,
      columnMinWidth: 200,
    },
  ];

  const testCaseColumnsVisibility: IColumnVisibility<TestCase>[] = [
    {
      field: "test_case_num",
      headerName: "Test Case",
      visible: true,
      columnMaxWidth: 100,
      columnMinWidth: 100,
    },
    {
      field: "name",
      headerName: "Name",
      visible: true,
      columnMinWidth: 400,
    },
    {
      field: "required_parameters",
      headerName: "Required Paramters",
      visible: true,
      columnMinWidth: 200,
      columnMaxWidth: 300,
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
      columnMaxWidth: 250,
      columnMinWidth: 200,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
      columnMaxWidth: 120,
      columnMinWidth: 120,
    },
  ];

  const metricColumnsVisibility: IColumnVisibility<Metric>[] = [
    {
      field: "name",
      headerName: "Metric",
      visible: true,
      columnMinWidth: 400,
    },
    {
      field: "category",
      headerName: "Category",
      visible: true,
      columnMinWidth: 200,
      columnMaxWidth: 300,
    },
    {
      field: "required_parameters",
      headerName: "Required Paramters",
      visible: true,
      columnMinWidth: 300,
      columnMaxWidth: 300,
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
      columnMaxWidth: 250,
      columnMinWidth: 200,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
      columnMaxWidth: 120,
      columnMinWidth: 120,
    },
  ];

  const auditLogColumnsVisibility: IColumnVisibility<AuditLog>[] = [
    {
      field: "updated_on",
      headerName: "Timestamp (UTC)",
      visible: true,
    },
    {
      field: "action",
      headerName: "Action",
      visible: true,
    },
    {
      field: "method",
      headerName: "Method",
      visible: true,
    },
    {
      field: "request_id",
      headerName: "Request ID",
      visible: true,
    },
    {
      field: "updated_by",
      headerName: "User",
      visible: true,
    },
    {
      field: "status",
      headerName: "Status",
      visible: true,
    },
    {
      field: "status_code",
      headerName: "Status Code",
      visible: true,
    },
  ];

  const versionHistoryColumnsVisibility: IColumnVisibility<VersionHistory>[] = [
    {
      field: "version_num",
      headerName: "Version ID",
      visible: true,
    },
    {
      field: "tag",
      headerName: "Version Name",
      visible: true,
    },
    {
      field: "tag",
      headerName: "Tag ID",
      visible: true,
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
    },
    {
      field: "id",
      headerName: "Version Tag",
      visible: true,
    },
  ];

  const auditColumnsVisibility: IColumnVisibility<Audit>[] = [
    {
      field: "name",
      headerName: "Name",
      visible: true,
      columnMinWidth: 200,
    },
    {
      field: "audit_firm",
      headerName: "Audit Firm",
      visible: true,
      columnMinWidth: 200,
    },
    {
      field: "auditors",
      headerName: "Auditors",
      visible: true,
    },
    {
      field: "status",
      headerName: "Status",
      visible: true,
      columnMaxWidth: 150,
      columnMinWidth: 150,
    },
    {
      field: "score",
      headerName: "Overall Score",
      visible: true,
      columnMaxWidth: 150,
    },
    {
      field: "start_date",
      headerName: "Start Date",
      visible: true,
    },
    {
      field: "end_date",
      headerName: "End Date",
      visible: true,
    },
    {
      field: "progress",
      headerName: "Progress",
      visible: true,
    },
  ];

  const reportColumnsVisibility: IColumnVisibility<Report>[] = [
    {
      field: "report_num",
      headerName: "ID",
      visible: true,
      columnMinWidth: 100,
      columnMaxWidth: 120,
    },
    {
      field: "name",
      headerName: "Name",
      visible: true,
      columnMinWidth: 150,
    },
    {
      field: "report_type",
      headerName: "Report Type",
      visible: true,
      columnMinWidth: 150,
    },
    {
      field: "created_on",
      headerName: "Generated On",
      visible: true,
      columnMaxWidth: 140,
      columnMinWidth: 110,
    },
    {
      field: "created_by",
      headerName: "Generated By",
      visible: true,
      columnMaxWidth: 200,
      columnMinWidth: 150,
    },
    {
      field: "id",
      headerName: "",
      visible: true,
      columnMinWidth: 50,
      columnMaxWidth: 50,
    },
  ];

  const riskScenarioColumnsVisibility = riskScenarioListColumnsVisibility({ navigate });

  const evidenceTestColumnVisibility = evidenceTestListColumnsVisibility({});

  const useCaseColumnVisibility = useCaseListColumnsVisibility({ theme });

  const impactAssessmentColumnsVisibility = impactAssessmentListColumnsVisibility({});

  const vendorColumnsVisibility: IColumnVisibility<Vendor>[] = [
    {
      field: "name",
      headerName: "Name",
      visible: true,
    },
    {
      field: "status",
      headerName: "Status",
      visible: true,
    },
    {
      field: "overall_risk",
      headerName: "Overall Risk",
      visible: true,
    },
    {
      field: "potential_value",
      headerName: "Expected Value",
      visible: true,
    },
    {
      field: "business_owner",
      headerName: "Business Owner",
      visible: true,
    },
    {
      field: "compliance_owner",
      headerName: "Compliance Owner",
      visible: true,
    },
    {
      field: "last_assessed_date",
      headerName: "Last Assessed Date",
      visible: true,
    },
  ];

  const resourceColumnsVisibility = externalResourceColumnsVisibility({});

  const fillBlankSpace = tableSize === "big";
  const isSmallScreen = ScreenSmallerThen("1100px");
  const bigFixedHeightSize = isSmallScreen
    ? "100%"
    : tableSize === "big"
    ? bigFixedHeight
    : "unset";

  return (
    <Box paddingBottom={paddingBottom ? "80px" : "0px"}>
      <Box
        sx={{
          width: "100%",
          height: fillBlankSpace ? "100%" : "unset",
          overflow: "visible",
        }}
      >
        <Box height={bigFixedHeight ? bigFixedHeightSize : fillBlankSpace ? "100%" : "unset"}>
          <Box
            paddingBottom={"0px"}
            borderRadius="6px"
            border="1px solid"
            minHeight="100%"
            maxHeight="100%"
            overflow="auto"
            borderColor={theme.palette.custom.secondaryBorder}
            bgcolor={theme.palette.custom.secondaryBackground}
            sx={
              !columnsVisibility
                ? { borderTopRightRadius: "6px", borderTopLeftRadius: "6px" }
                : { borderTopRightRadius: "6px", borderTopLeftRadius: "6px" }
            }
          >
            <APITableLoader
              columnsVisibility={
                type === "asset"
                  ? assetColumnsVisibility
                  : type === "dataset"
                  ? datasetColumnsVisibility
                  : type === "model"
                  ? modelsColumnsVisibility
                  : type === "workflow"
                  ? workflowColumnsVisibility
                  : type === "action"
                  ? actionColumnsVisibility
                  : type === "role"
                  ? roleColumnsVisibility
                  : type === "policy"
                  ? policyColumnsVisibility
                  : type === "library"
                  ? libraryColumnsVisibility
                  : type === "testcase"
                  ? testCaseColumnsVisibility
                  : type === "metric"
                  ? metricColumnsVisibility
                  : type === "auditlog"
                  ? auditLogColumnsVisibility
                  : type === "version"
                  ? versionHistoryColumnsVisibility
                  : type === "audit"
                  ? auditColumnsVisibility
                  : type === "report"
                  ? reportColumnsVisibility
                  : type === "risk-scenario"
                  ? riskScenarioColumnsVisibility
                  : type === "evidence-test"
                  ? evidenceTestColumnVisibility
                  : type === "use-case"
                  ? useCaseColumnVisibility
                  : type === "impact-assessment"
                  ? impactAssessmentColumnsVisibility
                  : type === "vendor"
                  ? vendorColumnsVisibility
                  : type === "resource"
                  ? resourceColumnsVisibility
                  : columnsVisibility
                  ? columnsVisibility
                  : []
              }
              tableSize={tableSize}
              secondComponent={false}
              tableSearch={true}
              filtersVisibility={false}
              dateFilters={false}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
