import {
  DatabricksCatalog,
  DatabricksQualityMonitor,
  DatabricksSchema,
  DatabricksTable,
  DatabricksUnityCatalogModel,
} from "../models/types";
import { httpGetAuthenticated, httpPostAuthenticated } from "./ApiService";

const endpoints = {
  base: () => "/integration/databricks",
};

export const getDatabricksModels = (params: { catalog_name?: string; schema_name?: string }) => {
  return httpGetAuthenticated<DatabricksUnityCatalogModel[]>(`${endpoints.base()}/get_models`, {
    params,
  });
};

export const searchDatabricksModels = async (searchText: string) => {
  const { data } = await getDatabricksModels({});
  return data;
};

export const getProcessModel = async (modelId: string) => {
  const { data } = await httpGetAuthenticated(
    `${endpoints.base()}/process_model?asset_id=${modelId}`
  );
  return data;
};

export const getDatabricksTable = async (tableName: string) => {
  const { data } = await httpGetAuthenticated<DatabricksTable>(
    `${endpoints.base()}/get_table?table_name=${tableName}`
  );
  return data;
};

export const getDatabricksTables = async (params: {
  catalog_name: string;
  schema_name: string;
}) => {
  return httpGetAuthenticated<DatabricksTable[]>(`${endpoints.base()}/list_tables`, {
    params,
  });
};

export const getDatabricksCatalogs = async () => {
  return httpGetAuthenticated<DatabricksCatalog[]>(`${endpoints.base()}/list_catalogs`);
};

export const getDatabricksSchemas = async (params: { catalog_name: string }) => {
  return httpGetAuthenticated<DatabricksSchema[]>(`${endpoints.base()}/list_schemas`, {
    params,
  });
};

type DatabricksQualityMonitorResponse = {
  quality_monitor: DatabricksQualityMonitor | null;
};

export const getDatabricksQualityMonitor = async (params: { table_name: string }) => {
  return httpGetAuthenticated<DatabricksQualityMonitorResponse>(`${endpoints.base()}/get_monitor`, {
    params,
  });
};

export const processDatabricksDataset = async (payload: { table_name: string }) => {
  return httpPostAuthenticated(`${endpoints.base()}/process_dataset`, payload);
};
