import { useQuery } from "react-query";
import queryClient, { QueryKey } from "../state/QueryStore";
import { getTestRun, getTestRuns } from "../services/TestRunService";
import { TestRunFetchOptions } from "../models/types";
import { getVersionHistory } from "../services/VersionHistoryService";

export const useTestRuns = (params: TestRunFetchOptions) => {
  return useQuery([QueryKey.TestRun, params], async () => {
    const { data } = await getTestRuns(params);
    return data;
  });
};

export const useTestRun = (testId: string, version_num?: number) => {
  const searchForVersionNum = !!version_num;
  const queryKey = searchForVersionNum
    ? [QueryKey.TestRun, testId, version_num]
    : [QueryKey.TestRun, testId];
  const queryFn = searchForVersionNum
    ? async () => {
        const { data } = await getVersionHistory({
          object_id: [testId],
          version_num: [version_num],
        });
        return JSON.parse(data.results[0].object);
      }
    : async () => {
        const { data } = await getTestRun(testId);
        return data;
      };
  return useQuery(queryKey, queryFn);
};

export const fetchTestRuns = (params: TestRunFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.TestRun, params], async () => {
    const { data } = await getTestRuns(params);
    return data;
  });
};

export const fetchTestRun = (testId: string) => {
  return queryClient.fetchQuery([QueryKey.TestRun, testId], async () => {
    const { data } = await getTestRun(testId);
    return data;
  });
};

export const searchTestRun = async (searchText: string) => {
  const tests = await fetchTestRuns({ search: searchText });
  return tests.results;
};
