import { Metric } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import {
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
} from "./ApiService";

const metricEndpoints = {
  metrics: () => "/metrics",
  metric: (metricId: string) => `/metrics/${metricId}`,
};

export const getMetric = (metricId: string, params: { fairo_data: boolean }) => {
  return httpGetAuthenticated<Metric>(metricEndpoints.metric(metricId), {
    params,
  });
};

export const getMetrics = (params: any) => {
  return httpGetAuthenticated<ListResponse<Metric>>(metricEndpoints.metrics(), {
    params,
  });
};

export const addMetric = async (payload: { name: string; description?: string }) => {
  const { data } = await httpPostAuthenticated<Metric>(metricEndpoints.metrics(), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Metric] });
  return data;
};

export const editMetric = async (
  metricId: string,
  payload: {
    name?: string;
    description?: string;
    category?: string | null;
    required_parameters?: string[];
    optional_parameters?: string[];
    references?: string[];
  }
) => {
  const { data } = await httpPatchAuthenticated(metricEndpoints.metric(metricId), payload);
  queryClient.invalidateQueries({ queryKey: [QueryKey.Metric, metricId] });
  return data;
};

export const deleteMetric = async (metricId: string) => {
  const resp = await httpDeleteAuthenticated(metricEndpoints.metric(metricId));
  queryClient.invalidateQueries({ queryKey: [QueryKey.Metric] });
  return resp;
};
