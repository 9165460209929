import { useQuery } from "react-query";
import queryClient, { QueryKey } from "../state/QueryStore";
import { getParameter, getParameters } from "../services/ParameterService";
import { ParameterFetchOptions } from "../models/types";

export const useParameters = (params: ParameterFetchOptions) => {
  return useQuery([QueryKey.Parameter, params], async () => {
    const { data } = await getParameters(params);
    return data;
  });
};

export const useParameter = (parameterId: string) => {
  return useQuery([QueryKey.Parameter, parameterId], async () => {
    const { data } = await getParameter(parameterId, { fairo_data: true });
    return data;
  });
};

export const fetchParameters = (params: ParameterFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.Parameter, params], async () => {
    const { data } = await getParameters(params);
    return data;
  });
};

export const searchParameters = async (searchText: string) => {
  const parameters = await fetchParameters({ search: searchText });
  return parameters.results;
};
