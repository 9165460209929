import { useQuery } from "react-query";
import { getResource, getResources } from "../services/ResourceService";
import queryClient, { QueryKey } from "../state/QueryStore";
import { getVersionHistory } from "../services/VersionHistoryService";

export const useResources = (params: any) => {
  return useQuery([QueryKey.Resource, params], async () => {
    const { data } = await getResources(params);
    return data;
  });
};

export const useResource = (resourceId: string, params?: { version_num?: number }) => {
  const searchForVersionNum = !!params?.version_num;
  const queryKey = searchForVersionNum
    ? [QueryKey.Resource, resourceId, params.version_num]
    : [QueryKey.Resource, resourceId];
  const queryFn = searchForVersionNum
    ? async () => {
        const { data } = await getVersionHistory({
          object_id: [resourceId],
          version_num: [params.version_num],
        });
        return JSON.parse(data.results[0].object);
      }
    : async () => {
        const { data } = await getResource(resourceId);
        return data;
      };
  return useQuery(queryKey, queryFn);
};

export const fetchResources = (params: any) => {
  return queryClient.fetchQuery([QueryKey.Resource, params], async () => {
    const { data } = await getResources(params);
    return data;
  });
};

export const searchResources = async (searchText: string) => {
  const resources = await fetchResources({ search: searchText });
  return resources.results;
};

export const fetchResource = async (resourceId: string) => {
  return queryClient.fetchQuery([QueryKey.Resource, resourceId], async () => {
    const { data } = await getResource(resourceId);
    return data;
  });
};
