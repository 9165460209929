import { useQuery } from "react-query";
import { MetricResultFetchOptions } from "../models/types";
import { getMetricResult, getMetricResults } from "../services/MetricResultService";
import queryClient, { QueryKey } from "../state/QueryStore";

export const useMetricResults = (params: MetricResultFetchOptions) => {
  return useQuery([QueryKey.MetricResult, params], async () => {
    const { data } = await getMetricResults(params);
    return data;
  });
};

export const useMetricResult = (metricId: string) => {
  return useQuery([QueryKey.MetricResult, metricId], async () => {
    const { data } = await getMetricResult(metricId);
    return data;
  });
};

export const fetchMetricResults = (params: MetricResultFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.MetricResult, params], async () => {
    const { data } = await getMetricResults(params);
    return data;
  });
};

export const fetchMetricResult = (metricResultId: string) => {
  return queryClient.fetchQuery([QueryKey.MetricResult, metricResultId], async () => {
    const { data } = await getMetricResult(metricResultId);
    return data;
  });
};

export const searchMetricResults = async (searchText: string) => {
  const metrics = await fetchMetricResults({ search: searchText });
  return metrics.results;
};

export const searchMetricResultsByMetric = async (metricId: string, searchText: string) => {
  const metrics = await fetchMetricResults({
    metric: [metricId],
    search: searchText,
  });
  return metrics.results;
};
