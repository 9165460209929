import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DefaultBadge, StatusBadge } from "../../../atoms/StatusBadge";
import { navigateToImpactAssessmentReview } from "../../../components/ImpactAssessmentNavigator";
import UserTag from "../../../components/UserTag";
import { useOrganizationContext } from "../../../context/OrganizationContext";
import { ExecutionTask, ExternalTask } from "../../../models/types";
import { ShowMore } from "../../../molecules/ShowMore";
import { LabelAndValue } from "../../../molecules/tasks/LabelAndValue";
import { getImpactAssessment } from "../../../services/ImpactAssessmentService";
import { getJiraAppIntegrationInfo } from "../../../services/PluginService";
import { getQuestionnaire } from "../../../services/QuestionnaireService";
import { navigateToObject } from "../../../utilities/GenericNavigateHelper";

type ReadOnlyTaskProps = {
  task: ExecutionTask;
  onClose: () => void;
};

export const ReadOnlyTask = ({ task, onClose }: ReadOnlyTaskProps) => {
  const navigate = useNavigate();
  const {
    setSelectedTask,
    setSelectedElement,
    setSelectedQuestionnaire,
    setSelectedImpactAssessment,
  } = useOrganizationContext();
  const handleJiraLink = async (item: ExternalTask) => {
    const integration = await getJiraAppIntegrationInfo();
    if (integration) {
      window.open(`${integration.data?.site_url}/browse/${item.key}`, "_blank");
    }
  };
  const hasJiraIntegration =
    task?.integration_type === "jira" &&
    !!task?.task_external_ids &&
    task?.task_external_ids.length > 0;
  const isCompleted = task?.status === "COMPLETED";
  const isQuestionnaireReviewTask = task?.task_type === "Section Review";
  const isQuestionReviewTask = task?.task_type === "Question Review";
  return (
    <Box display="flex" flexDirection="column" gap="10px">
      <LabelAndValue label="Type" value={task.task_type} badge />
      {task?.due_date && <LabelAndValue label="Due Date" value={task.due_date} />}
      {task?.parent_object_id && (
        <LabelAndValue
          onClick={() => {
            navigateToObject(
              navigate,
              task.parent_type,
              task.parent_object_id,
              setSelectedTask,
              setSelectedElement
            );
            onClose();
          }}
          label={`Related Object`}
          value={task?.parent_object?.name ?? task.parent_type}
        />
      )}
      <LabelAndValue
        label="Assignee"
        value={task.assignee}
        customRender={task.assignee_name ? <UserTag name={task.assignee_name} /> : <></>}
      />
      <LabelAndValue label="Status" value="" customRender={<StatusBadge status={task.status} />} />
      {hasJiraIntegration && (
        <Box display="flex" flexDirection="row" alignItems="center" gap="10px">
          <LabelAndValue
            label="External Issue"
            value=""
            customRender={
              <Box>
                {task?.task_external_ids?.map((externalId, index) => (
                  <DefaultBadge
                    key={index}
                    onClick={() => handleJiraLink(externalId)}
                    icon="jira"
                    value={externalId.key}
                  />
                ))}
              </Box>
            }
          />
        </Box>
      )}
      {task?.comments && task.comments !== "" && (
        <LabelAndValue
          direction="column"
          label="Comments"
          value=""
          customRender={<ShowMore description={task.comments} maxChar={250} richText={true} />}
        />
      )}
      {(task.task_type === "Answer Question" || isQuestionReviewTask) &&
        !isCompleted &&
        task?.metadata?.impact_assessment && (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
            gap="10px"
          >
            <Button
              variant="contained"
              onClick={() => {
                setSelectedQuestionnaire(task.parent_object_id);
                setSelectedImpactAssessment(task?.metadata?.impact_assessment ?? null);
              }}
              fullWidth={false}
            >
              Launch
            </Button>
          </Box>
        )}
      {isQuestionnaireReviewTask && !isCompleted && task?.metadata?.impact_assessment && (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
          gap="10px"
        >
          <Button
            variant="contained"
            onClick={async () => {
              if (task?.metadata?.impact_assessment) {
                const { data: impactAssessment } = await getImpactAssessment(
                  task.metadata.impact_assessment
                );
                const { data: questionnaire } = await getQuestionnaire(task.parent_object_id);
                navigateToImpactAssessmentReview({
                  navigate,
                  impactAssessmentId: impactAssessment.id,
                  impactAssessmentNumber: impactAssessment.impact_assessment_num,
                  questionnaireNumber: questionnaire?.questionnaire_num ?? 0,
                  questionnaireId: questionnaire?.id,
                });
                onClose();
              }
            }}
            fullWidth={false}
          >
            Launch
          </Button>
        </Box>
      )}
    </Box>
  );
};
