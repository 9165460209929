import { useQuery } from "react-query";
import queryClient, { QueryKey } from "../state/QueryStore";
import { getAnswer, getAnswers } from "../services/QuestionnaireAnswerService";
import { QuestionnaireAnswerFetchOptions } from "../models/types";

export const useQuestionnaireAnswers = (params: QuestionnaireAnswerFetchOptions) => {
  return useQuery([QueryKey.QuestionnaireAnswer, params], async () => {
    const { data } = await getAnswers(params);
    return data;
  });
};

export const fetchQuestionnaireAnswers = (params: QuestionnaireAnswerFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.QuestionnaireAnswer, params], async () => {
    const { data } = await getAnswers(params);
    return data;
  });
};

export const searchQuestionnaireAnswers = async (searchText: string) => {
  const questionnaires = await fetchQuestionnaireAnswers({
    search: searchText,
  });
  return questionnaires.results;
};

export const useQuestionnaireAnswer = (id: string) => {
  return useQuery([QueryKey.QuestionnaireAnswer, id], async () => {
    const { data } = await getAnswer(id);
    return data;
  });
};
