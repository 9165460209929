import { useEffect } from "react";
import { SearchableDropdown } from "../../components/SearchableDropdown";
import { useRole, useRoles } from "../../hooks/useRoles";
import { FieldHandlerProps, Role } from "../../models/types";
import { Awaitable } from "../../utilities/common";

export const RoleCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<Role[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const { data: role } = useRole(value || "");
  useEffect(() => {
    if (role && setObjectAttributes) {
      setObjectAttributes(role ?? {});
    }
  }, [role]);
  return (
    <SearchableDropdown<Role>
      label="Role"
      required={required}
      error={error}
      value={role || null}
      onChange={(newValue) => (newValue != null ? onChange(newValue.id) : onChange(null))}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [obj.name, obj.description ?? ""]}
    />
  );
};

export const RoleListCustomField = (
  props: FieldHandlerProps & {
    search: (searchText: string) => Awaitable<Role[]>;
    setObjectAttributes?: (obj: any) => void;
  }
) => {
  const { value, search, onChange, error, setObjectAttributes, required } = props;
  const { data: items } = useRoles({ id: value });
  useEffect(() => {
    if (items && items.results && items.results.length && setObjectAttributes) {
      setObjectAttributes(items.results[0] ?? {});
    }
  }, [items]);
  return (
    <SearchableDropdown<Role>
      label="Role"
      required={required}
      error={error}
      multiple={true}
      value={value.length === 0 ? [] : items?.results ?? []}
      onChange={(newValue) => newValue != null && onChange(newValue.map((item) => item.id))}
      getOptionLabel={(obj) => obj.name}
      getOptions={search}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      getSummaryDescription={(obj) => [obj.name, obj?.description ?? ""]}
    />
  );
};
