import { AssetIndicatorIcon } from "../components/AppIcons";
import { assetHooks } from "../state/QueryStore";
import { IGetListQueryParams } from "../state/QueryStoreHelper";
import AppStrings from "../strings";
import { ObjectDetailViewModel } from "./IObjectCommonFields";

class AssetDetailViewModel extends ObjectDetailViewModel {
  assetTypeId: string;
  asset_num: number;
  constructor(rawData: any) {
    super(rawData, rawData.asset_num);
    this.asset_num = rawData.asset_num;
    this.summary = rawData.name;
    this.assetTypeId = rawData.type;
  }

  get objectIndicatorName() {
    return AppStrings.assets.indicatorLabel;
  }

  get objectIndicatorIcon() {
    return <AssetIndicatorIcon />;
  }

  get objectName() {
    return AppStrings.assets.name;
  }

  get objectHooks() {
    return assetHooks as any;
  }
}

interface ICreateAssetRequestBody {
  name: string;
  description: string;
  /** asset type id */
  type: string;
}

interface IUpdateAssetRequestBody {
  name: string;
  description: string;
}

interface IGetAssetListRequestBody extends IGetListQueryParams {}

export { AssetDetailViewModel, AssetDetailViewModel as AssetListViewModel };
export type { ICreateAssetRequestBody, IUpdateAssetRequestBody, IGetAssetListRequestBody };
