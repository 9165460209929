import { Box, LinearProgress } from "@mui/material";

type QuestionnaireProgressBarProps = {
  questionIndex: number;
  questionnaireLength: number;
};

export const QuestionnaireProgressBar = ({
  questionIndex,
  questionnaireLength,
}: QuestionnaireProgressBarProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      paddingRight="10px"
      width="100%"
    >
      <LinearProgress
        sx={{
          height: "8px",
          width: "100%",
          borderRadius: "58px",
        }}
        variant="determinate"
        value={(questionIndex / questionnaireLength) * 100}
      />
    </Box>
  );
};
