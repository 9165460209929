import { Stack, TextField, Typography, useTheme } from "@mui/material";
import moment from "moment";
import CustomCalendar from "../molecules/CustomCalendar";
import CKEditorField from "./custom-fields/CKEditorField";

type DocumentationFilesDetailsFormProps = {
  name: string;
  setName: (name: string) => void;
  namePlaceholder?: string;
  description: string;
  setDescription: (description: string) => void;
  recurrence?: number;
  recurrenceStart: Date | null;
  setRecurrenceStart: (recurrenceStart: Date | null) => void;
  hideRecurrence?: boolean;
};

export const DocumentationFilesDetailsForm = (props: DocumentationFilesDetailsFormProps) => {
  const {
    name,
    setName,
    namePlaceholder,
    description,
    setDescription,
    recurrence,
    recurrenceStart,
    setRecurrenceStart,
    hideRecurrence,
  } = props;
  const theme = useTheme();
  const secondaryTextColor = theme.palette.custom.secondaryTypography;

  return (
    <Stack gap="10px">
      <Typography variant="h3" color={secondaryTextColor}>
        Name
      </Typography>
      <TextField
        value={name}
        onChange={(e) => setName(e.target.value)}
        required
        placeholder={namePlaceholder}
      />
      <Typography variant="h3" color={secondaryTextColor}>
        Description
      </Typography>
      <CKEditorField value={description} onChange={setDescription} />
      {!hideRecurrence && (
        <Typography variant="h3" color={secondaryTextColor}>
          Recurrence Start
        </Typography>
      )}
      {!hideRecurrence && <CustomCalendar value={recurrenceStart} onChange={setRecurrenceStart} />}
      {!hideRecurrence && (
        <Typography variant="body2">{`Next update due: ${
          recurrenceStart
            ? moment(recurrenceStart).add(recurrence, "days").format("MM-DD-YYYY")
            : ""
        }`}</Typography>
      )}
    </Stack>
  );
};
