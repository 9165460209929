import { Box, Button } from "@mui/material";
import { SmallModal } from "../../../components/SmallModal";
import { NameAndDescriptionField } from "../../custom-fields/NameAndDescription";
import { useEffect, useState } from "react";
import { IAStakeholder } from "../../../models/types";
import { IAStakeHolderField } from "../../custom-fields/IAStakeHolder";

type IAStakeHolderFieldModalProps = {
  open: boolean;
  onClose: () => void;
  onAdd: (value: string) => void;
  selectedForEdit:
    | ({
        id: string;
      } & IAStakeholder)
    | null;
  title: string;
};

export const IAStakeHolderCustomFieldModal = (props: IAStakeHolderFieldModalProps) => {
  const { open, onClose, onAdd, selectedForEdit, title } = props;
  const [value, setValue] = useState<string | null>(null);

  useEffect(() => {
    if (selectedForEdit) {
      setValue(JSON.stringify(selectedForEdit));
    }
  }, [selectedForEdit]);

  const handleClose = () => {
    setValue(null);
    onClose();
  };

  return (
    <SmallModal
      open={open}
      onClose={handleClose}
      title={`${selectedForEdit ? "Edit" : "Add"} ${title}`}
      size="medium"
    >
      <Box display="flex" flexDirection="column" gap="10px">
        <IAStakeHolderField
          value={value}
          onChange={setValue}
          required
          valueError={false}
          errorMessage=""
        />
        <Box display="flex" width="100%" justifyContent="flex-end">
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onAdd(value || "");
              handleClose();
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </SmallModal>
  );
};
