import { Notification, NotificationFetchOptions } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { httpGetAuthenticated, httpPatchAuthenticated } from "./ApiService";

const notificationEndpoints = {
  notifications: () => "/notifications",
  notification: (notificationId: string) => `/notifications/${notificationId}`,
  notificationReadAll: () => "/notifications/read_all",
};

export const getNotification = (notificationId: string) => {
  return httpGetAuthenticated<Notification>(notificationEndpoints.notification(notificationId));
};

export const getNotifications = (params: NotificationFetchOptions) => {
  return httpGetAuthenticated<ListResponse<Notification>>(notificationEndpoints.notifications(), {
    params,
  });
};

export const readAll = async () => {
  const { data } = await httpGetAuthenticated<{ message: string }>(
    notificationEndpoints.notificationReadAll()
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.Notification] });
  return data;
};

export const editNotification = async (notificationId: string, params: { read: boolean }) => {
  const { data } = await httpPatchAuthenticated(
    notificationEndpoints.notification(notificationId),
    params
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.Notification] });
  return data;
};
