import { Theme } from "@mui/material";
import { NavigateFunction } from "react-router-dom";
import { DefaultBadge } from "../../../../atoms/StatusBadge";
import UserTag from "../../../../components/UserTag";
import { AssignedToMe } from "../../../../models/types";
import { IColumnVisibility } from "../../../../molecules/ColumnVisibility";
import { TableTextWithEllipsis } from "../../../../molecules/TableTextWithEllipsis";
import { defaultDateDisplay } from "../../../../utilities/UIHelper";

type AssignedToMeTableColumnsProps = {
  navigate: NavigateFunction;
  setSelectedTask: (task: any) => void;
  theme: Theme;
};

export const AssignedToMeColumnsVisibility = ({
  navigate,
  setSelectedTask,
  theme,
}: AssignedToMeTableColumnsProps): IColumnVisibility<AssignedToMe>[] => {
  return [
    {
      field: "object",
      headerName: "Name / Summary",
      visible: true,
      columnMinWidth: 200,
      renderer: (data) => <TableTextWithEllipsis expanded={true} value={data.object?.name} />,
    },
    {
      field: "type",
      headerName: "Type",
      visible: true,
      columnMinWidth: 120,
      columnMaxWidth: 120,
      renderer: (data) => <DefaultBadge value={data.type} />,
    },
    {
      field: "updated_by",
      headerName: "Updated By",
      visible: true,
      columnMinWidth: 110,
      columnMaxWidth: 110,
      renderer: (data) => <UserTag showNameInitials hideName name={data.updated_by.name} />,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
      columnMinWidth: 120,
      columnMaxWidth: 120,
      renderer: (data) => defaultDateDisplay(data.updated_on),
    },
  ];
};
