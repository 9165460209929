import { TestCaseIndicatorIcon } from "../components/AppIcons";
import { testCaseHooks } from "../state/QueryStore";
import { IGetListQueryParams } from "../state/QueryStoreHelper";
import AppStrings from "../strings";
import { ObjectDetailViewModel, ObjectListViewModel } from "./IObjectCommonFields";

class TestCaseDetailViewModel extends ObjectDetailViewModel {
  test_case_num: number;
  preconditions: string;
  steps: string;
  constructor(rawData: any) {
    super(rawData, rawData.test_case_num);
    this.test_case_num = rawData.test_case_num;
    this.summary = rawData.name;
    this.preconditions = rawData.preconditions ?? "";
    this.steps = rawData.steps ?? "";
  }

  get objectIndicatorName() {
    return AppStrings.testCases.indicatorLabel;
  }
  get objectIndicatorIcon() {
    return <TestCaseIndicatorIcon />;
  }

  get objectName() {
    return AppStrings.testCases.name;
  }

  get objectHooks() {
    return testCaseHooks as any;
  }
}

interface ICreateTestCaseRequestBody {
  name: string;
  description: string;
  preconditions: string;
  steps: string;
}

interface IUpdateTestCaseRequestBody extends ICreateTestCaseRequestBody {}

interface IGetTestCaseListRequestBody extends IGetListQueryParams {}

export { TestCaseDetailViewModel as TestCaseListViewModel, TestCaseDetailViewModel };
export type { ICreateTestCaseRequestBody, IUpdateTestCaseRequestBody, IGetTestCaseListRequestBody };
