import { LoadingButton } from "@mui/lab";
import { Stack, TextField, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { DocumentationFilesDetailsForm } from "../../../components/DocumentationFilesDetailsForm";
import { SmallModal } from "../../../components/SmallModal";

type AddHyperlinkProps = {
  open: boolean;
  onClose: () => void;
  onAddHyperlink: (
    url: string,
    name: string,
    description: string,
    recurrenceStart: Date | null
  ) => void;
  recurrence?: number;
  document?: any;
  hideRecurrence?: boolean;
};

export const AddHyperlink = (props: AddHyperlinkProps) => {
  const { open, onClose, onAddHyperlink, recurrence, document, hideRecurrence } = props;

  const theme = useTheme();
  const secondaryTextColor = theme.palette.custom.secondaryTypography;

  const [url, setUrl] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [recurrenceStart, setRecurrenceStart] = useState<Date | null>(null);

  useEffect(() => {
    if (document) {
      setUrl(document?.value);
      setName(document?.name);
      setDescription(document?.description);
      setRecurrenceStart(document?.recurrence_start ? new Date(document?.recurrence_start) : null);
    } else {
      setUrl("");
      setName("");
      setDescription("");
      setRecurrenceStart(null);
    }
  }, [document]);

  return (
    <SmallModal open={open} onClose={onClose} size="small" title="Add Hyperlink">
      <Stack gap="10px">
        <Typography variant="h3" color={secondaryTextColor}>
          Url
        </Typography>
        <TextField
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          required
          placeholder="e.g. “www.fairo.ai”"
        />
        <DocumentationFilesDetailsForm
          name={name}
          setName={setName}
          namePlaceholder="e.g. “Fairo link”"
          description={description}
          setDescription={setDescription}
          recurrenceStart={recurrenceStart}
          setRecurrenceStart={setRecurrenceStart}
          recurrence={recurrence}
          hideRecurrence={hideRecurrence}
        />
        <LoadingButton
          variant="contained"
          onClick={() => onAddHyperlink(url, name, description, recurrenceStart)}
          disabled={!url}
        >
          Save
        </LoadingButton>
      </Stack>
    </SmallModal>
  );
};
