import { useQuery } from "react-query";
import { TestCaseFetchOptions } from "../models/types";
import { getTestCases } from "../services/DataService";
import queryClient, { QueryKey } from "../state/QueryStore";
import { getTestCase } from "../services/TestCaseService";
import { getVersionHistory } from "../services/VersionHistoryService";

export const useTestCases = (params: TestCaseFetchOptions) => {
  return useQuery([QueryKey.TestCase, params], async () => {
    const { data } = await getTestCases(params);
    return data;
  });
};

export const fetchTestCases = (params: TestCaseFetchOptions) => {
  return queryClient.fetchQuery([QueryKey.TestCase, params], async () => {
    const { data } = await getTestCases(params);
    return data;
  });
};

export const searchTestCases = async (searchText: string) => {
  const tests = await fetchTestCases({ search: searchText });
  return tests.results;
};

export const useTestCase = (testId: string, version_num?: number) => {
  const searchForVersionNum = !!version_num;
  const queryKey = searchForVersionNum
    ? [QueryKey.TestCase, testId, version_num]
    : [QueryKey.TestCase, testId];
  const queryFn = searchForVersionNum
    ? async () => {
        const { data } = await getVersionHistory({
          object_id: [testId],
          version_num: [version_num],
        });
        return JSON.parse(data.results[0].object);
      }
    : async () => {
        const { data } = await getTestCase(testId);
        return data;
      };
  return useQuery(queryKey, queryFn);
};

export const fetchTestCase = (testId: string) => {
  return queryClient.fetchQuery([QueryKey.TestCase, testId], async () => {
    const { data } = await getTestCase(testId);
    return data;
  });
};
