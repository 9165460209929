import { useQuery } from "react-query";
import {
  getDatabricksCatalogs,
  getDatabricksModels,
  getDatabricksQualityMonitor,
  getDatabricksSchemas,
  getDatabricksTables,
} from "../services/DatabricksService";
import { QueryKey } from "../state/QueryStore";

export const useDatabricksModels = (params: { catalog_name?: string; schema_name?: string }) => {
  return useQuery([QueryKey.Databricks, "models", params], async () => {
    const { data } = await getDatabricksModels(params);
    return data;
  });
};

export const useDatabricksCatalogs = () => {
  return useQuery([QueryKey.Databricks, "catalogs"], async () => {
    const { data } = await getDatabricksCatalogs();
    return data;
  });
};

export const useDatabricksSchemas = (params: { catalog_name: string }) => {
  return useQuery([QueryKey.Databricks, "schemas", params], async () => {
    if (params.catalog_name === "") {
      return [];
    }
    const { data } = await getDatabricksSchemas(params);
    return data;
  });
};

export const useDatabricksTables = (params: { catalog_name: string; schema_name: string }) => {
  return useQuery([QueryKey.Databricks, "tables", params], async () => {
    if (params.catalog_name === "" || params.schema_name === "") {
      return [];
    }
    const { data } = await getDatabricksTables(params);
    return data;
  });
};

export const useDatabricksQualityMonitor = (params: { table_name: string }) => {
  return useQuery([QueryKey.Databricks, "monitor", params], async () => {
    const { data } = await getDatabricksQualityMonitor(params);
    return data;
  });
};
