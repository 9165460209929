import { useState } from "react";
import { Awaitable } from "../utilities/common";
import { SmallModal } from "./SmallModal";
import { Box, IconButton, Stack, Typography, useTheme } from "@mui/material";
import Dropzone from "react-dropzone";
import { openSnackbar } from "../context/SnackbarContext";
import { DropZoneIndicator } from "./FileAttachmentForm";
import { LoadingButton } from "@mui/lab";
import { NavbarIcon } from "../atoms/navbar/Icon";

type ImportCSVProps = {
  open: boolean;
  onClose: () => void;
  addAttachment: (attachment: File) => Awaitable<void>;
};

export const MAX_FILE_SIZE_MB = 10;

export const ImportCSV = (props: ImportCSVProps) => {
  const { open, onClose, addAttachment } = props;
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <SmallModal open={open} onClose={onClose} title="Import CSV">
      <Stack gap="10px">
        <Typography variant="body2">Upload a CSV file to invite users</Typography>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h3"
            sx={{
              textDecoration: "underline",
            }}
            color={
              isDarkMode
                ? theme.palette.custom.whiteTypography
                : theme.palette.custom.blueTypography
            }
          >
            sample.csv
          </Typography>
          <a href={"/assets/files/sample.csv"} download="sample.csv">
            <IconButton sx={{ width: "35px", height: "35px" }}>
              <NavbarIcon
                variant={theme.palette.mode === "light" ? "download-logo" : "download-dark-logo"}
                sx={{ width: "10.29px", height: "15.44px" }}
              />
            </IconButton>
          </a>
        </Box>
        <Dropzone
          noClick
          multiple={false}
          onDrop={async (acceptedFiles, fileRejections) => {
            if (fileRejections.length) {
              const errorMessage = fileRejections[0].errors[0].message;
              if (/File is larger than/.test(errorMessage)) {
                // error doesn't display the size in human readable format. Show custom message
                openSnackbar(`File size limit is ${MAX_FILE_SIZE_MB}MB`, "error");
              } else {
                openSnackbar(errorMessage, "error");
              }
            } else if (acceptedFiles.length) {
              const fileToUpload = acceptedFiles[0];
              setLoading(true);
              try {
                await addAttachment(fileToUpload);
                openSnackbar("File uploaded successfully", "success");
                onClose();
              } catch {
                openSnackbar("Failed to upload file", "error");
              } finally {
                setLoading(false);
              }
            }
          }}
          maxSize={MAX_FILE_SIZE_MB * 1024 * 1024}
        >
          {({ getRootProps, getInputProps, isDragAccept, open: openFilePicker }) => (
            <Box {...getRootProps()} position="relative">
              <input {...getInputProps()} />
              {isDragAccept && <DropZoneIndicator />}
              <LoadingButton
                variant="contained"
                loading={loading}
                onClick={openFilePicker}
                fullWidth
              >
                Upload CSV
              </LoadingButton>
            </Box>
          )}
        </Dropzone>
      </Stack>
    </SmallModal>
  );
};
