import { MetricResult } from "../models/types";
import queryClient, { QueryKey } from "../state/QueryStore";
import { ListResponse } from "../utilities/ApiResponseHelper";
import { httpGetAuthenticated, httpPostAuthenticated } from "./ApiService";

const metricResultEndpoints = {
  results: () => "/metric_results",
  result: (resultId: string) => `/metric_results/${resultId}`,
};

export const getMetricResult = (resultId: string) => {
  return httpGetAuthenticated<MetricResult>(metricResultEndpoints.result(resultId));
};

export const getMetricResults = (params: any) => {
  return httpGetAuthenticated<ListResponse<MetricResult>>(metricResultEndpoints.results(), {
    params,
  });
};

export const addMetricResult = async (payload: {
  metric: string;
  value: string;
  assignee?: string;
  parameter_values: string[];
}) => {
  const { data } = await httpPostAuthenticated<MetricResult>(
    metricResultEndpoints.results(),
    payload
  );
  queryClient.invalidateQueries({ queryKey: [QueryKey.MetricResult] });
  return data;
};
