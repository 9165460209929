import { createContext, FunctionComponent, useState, ReactNode, useContext } from "react";
import { Alert, Button, Snackbar, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

interface ISnackbarContext {
  openSnackbar: (
    message: string,
    type: ISnackType,
    callback?: () => void,
    auditLogRequestId?: string
  ) => void;
  close: Function;
}

const SnackbarContext = createContext({} as ISnackbarContext);

interface ISnackbarProviderProps {
  children: ReactNode;
}

type ISnackType = "success" | "error" | "warning" | "info";

const snackListner: {
  listner?: (
    message: string,
    type: ISnackType,
    callback?: () => void,
    auditLogRequestId?: string
  ) => void;
} = { listner: undefined };

export const openSnackbar = (
  message: string,
  snackType: ISnackType,
  callback?: () => void,
  auditLogRequestId?: string
) => {
  snackListner.listner?.(message, snackType, callback, auditLogRequestId);
};

export const SnackbarProvider: FunctionComponent<ISnackbarProviderProps> = ({ children }) => {
  const [message, setMessage] = useState("");
  const [callback, setCallback] = useState<() => void>();
  const [snackType, setSnackType] = useState<ISnackType>("success");
  const [auditLogRequestId, setAuditLogRequestId] = useState<string>("");

  const [open, setOpen] = useState(false);

  const close = () => {
    setOpen(false);
    if (callback) {
      callback();
    }
  };

  const openSnackbar = (
    message: string,
    snackType: ISnackType,
    callback?: () => void,
    auditLogRequestId?: string
  ) => {
    setMessage(message);
    // react will call the `callback` thinking it's an updater function. Wrap it to avoid the issue
    setCallback(() => callback);
    setOpen(true);
    setSnackType(snackType);
    setAuditLogRequestId(auditLogRequestId ?? "");
  };
  snackListner.listner = openSnackbar;

  const location = useLocation();
  const currentPath = location.pathname;
  const navigate = useNavigate();

  return (
    <SnackbarContext.Provider value={{ openSnackbar, close }}>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={open}
        autoHideDuration={snackType !== "error" ? 4000 : null}
        onClose={close}
      >
        <Alert
          onClose={callback}
          severity={snackType}
          sx={{
            width: "100%",
            minWidth: "250px",
            padding: "2px 10px",
            color: "#fefefe",
          }}
          icon={false}
          variant="filled"
          action={
            <Button
              variant="text"
              sx={{
                color: "#FFFFFF",
                backgroundColor: "transparent",
                fontWeight: "700",
                textDecoration: "underline",
                textDecorationColor: "#FFFFFF",
                fontSize: "14px",
              }}
              onClick={close}
            >
              Dismiss
            </Button>
          }
        >
          <Typography variant="body2" sx={{ color: "#fefefe", paddingTop: "2px" }}>
            {message}
          </Typography>
          {auditLogRequestId && (
            <Button
              variant="text"
              sx={{
                padding: "0px",
                color: "#FFFFFF",
                backgroundColor: "transparent",
                fontWeight: "700",
                textDecoration: "underline",
                textDecorationColor: "#FFFFFF",
                fontSize: "14px",
              }}
              onClick={() => {
                navigate(`audit-log${currentPath}?id=${auditLogRequestId}`);
                close();
              }}
            >
              Go to Audit Log
            </Button>
          )}
        </Alert>
      </Snackbar>
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbarContext = () => {
  return useContext(SnackbarContext);
};
