import { Box } from "@mui/material";
import { AuditDetailsFormLoader } from "../../organisms/skeleton-loaders/AuditDetailsFormLoader";
import SecondaryBox from "../../atoms/box/SecondaryBox";
import { RectangularLoader } from "../../molecules/skeleton-loader/RectangularLoader";
import { ListViewLoader } from "./ListViewLoader";
import { AuditEvidence } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";

export const AuditDetailsLoader = () => {
  const columnsVisibility: IColumnVisibility<AuditEvidence>[] = [
    {
      field: "evidence_test",
      headerName: "Name",
      visible: true,
    },
    {
      field: "evidence_test",
      headerName: "Type",
      visible: true,
    },
    {
      field: "evidence_test",
      headerName: "Owner",
      visible: true,
    },
    {
      field: "updated_on",
      headerName: "Updated On",
      visible: true,
      columnMaxWidth: 120,
      columnMinWidth: 120,
    },
    {
      field: "status",
      headerName: "Status",
      visible: true,
    },
  ];

  return (
    <Box display="flex" flexDirection="column" gap="15px" paddingBottom="80px">
      <AuditDetailsFormLoader />
      <SecondaryBox gap="10px">
        <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
          <RectangularLoader width="200px" height="34px" />
          <RectangularLoader width="64px" height="34px" />
        </Box>
        <Box display="flex" flexDirection="row" gap="10px">
          <RectangularLoader width="25%" height="100px" />
          <RectangularLoader width="25%" height="100px" />
          <RectangularLoader width="25%" height="100px" />
          <RectangularLoader width="25%" height="100px" />
        </Box>
      </SecondaryBox>
      <ListViewLoader
        paddingBottom={false}
        columnsVisibility={columnsVisibility}
        tableSize="small"
      />
    </Box>
  );
};
