import { Button } from "@mui/material";
import { useState } from "react";
import { openSnackbar } from "../../context/SnackbarContext";
import { APIGetListHook } from "../../models/types";
import { IColumnVisibility } from "../../molecules/ColumnVisibility";
import { EmptyTable } from "../../molecules/EmptyTable";
import { TableTextWithEllipsis } from "../../molecules/TableTextWithEllipsis";
import { stripHTMLTags } from "../../utilities/common";
import { APITable } from "../APITable";
import { MetricWithDescriptionCustomFieldModal } from "../modals/custom-fields/MetricWithDescriptionCustomFieldModal";
import { MetricResultTestResultListCustomField } from "./MetricResultTestResultListCustomField";

type MetricWithDescriptionListCustomFieldProps = {
  value: string | null;
  onChange: (value: string | null) => void;
  edit: boolean;
  label: string;
};

type MetricWithDescription = {
  id: string;
  name: string;
  description: string;
};

export const MetricWithDescriptionListCustomField = (
  props: MetricWithDescriptionListCustomFieldProps
) => {
  const { value, onChange, edit, label } = props;

  const metrics: MetricWithDescription[] = JSON.parse(value || "[]");
  const metricIds = metrics.map((metric) => metric.id);
  const [open, setOpen] = useState(false);
  const [selectedForEdit, setSelectedForEdit] = useState<MetricWithDescription | null>(null);

  const useMetrics: APIGetListHook<any, any> = (params) => {
    const { page = 1, page_size: _pageSize = 5 } = params;
    const pageData = metrics.slice((page - 1) * _pageSize, page * _pageSize);
    return {
      data: {
        count: metrics.length,
        next: null,
        previous: null,
        results: pageData,
      },
      isLoading: false,
    };
  };

  const columnsVisibility: IColumnVisibility<MetricWithDescription>[] = [
    {
      field: "name",
      headerName: "Name",
      visible: true,
      columnMinWidth: 400,
      columnMaxWidth: 600,
      renderer: (metric) => <TableTextWithEllipsis value={metric.name} />,
    },
    {
      field: "description",
      headerName: "Reason",
      visible: true,
      renderer: (metric) => (
        <TableTextWithEllipsis value={stripHTMLTags(metric.description)} expanded />
      ),
    },
  ];

  const onAdd = (newValue: string) => {
    try {
      const isUpdate = selectedForEdit !== null;
      if (isUpdate) {
        const updatedMetric: MetricWithDescription = JSON.parse(newValue);
        onChange(
          JSON.stringify(
            metrics.map((metric) => (metric.id === updatedMetric.id ? updatedMetric : metric))
          )
        );
      } else {
        const newMetric: MetricWithDescription = JSON.parse(newValue);
        onChange(JSON.stringify([...metrics, newMetric]));
      }
    } catch (e) {
      openSnackbar(`Failed to add ${label}`, "error");
    } finally {
      setSelectedForEdit(null);
    }
  };

  return (
    <>
      <MetricWithDescriptionCustomFieldModal
        open={open}
        onClose={() => {
          setOpen(false);
          setSelectedForEdit(null);
        }}
        onAdd={onAdd}
        selectedForEdit={selectedForEdit}
        title={label}
        readonly={!edit}
      />
      <APITable
        useGetData={useMetrics}
        queryParams={{}}
        columnsVisibility={columnsVisibility}
        rowOnClick={(metric) => {
          setSelectedForEdit(metric);
          setOpen(true);
        }}
        secondComponent={edit ? <Button onClick={() => setOpen(true)}>Add</Button> : undefined}
        emptyTableComponent={
          <EmptyTable
            variant="metrics"
            label={label}
            description={`No ${label} added yet`}
            actionLabel={edit ? "Add" : undefined}
            action={edit ? () => setOpen(true) : undefined}
          />
        }
        tableSize="small"
        smallEmptyHeight
      />
      <MetricResultTestResultListCustomField
        emptyMetrics={metricIds.length === 0}
        value={JSON.stringify(metricIds)}
        model={""}
      />
    </>
  );
};
