import { useQuery } from "react-query";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { fetchUseCases } from "../hooks/useUseCases";
import { getUseCase } from "../services/UseCaseService";
import { getFinalNumberFromPath, getLastPathByRegex } from "../utilities/UIHelper";

const USE_CASE_NAVIGATOR_KEY = "use-case-navigator";

export const useUseCasePathState = (locationState: any, path: string) => {
  const useCaseId = locationState?.useCaseId;
  const edit = locationState?.edit;
  const lastPath = getLastPathByRegex(path);
  const number = getFinalNumberFromPath(lastPath);
  const navigate = useNavigate();
  return useQuery<{
    useCaseId: string;
    edit: boolean;
  }>(
    [USE_CASE_NAVIGATOR_KEY, path],
    async () => {
      if (useCaseId != null) {
        return { useCaseId, edit };
      }

      const useCases = await fetchUseCases({ use_case_num: [number] });
      const useCase = useCases.results.find((t) => t.use_case_num === Number(number));
      if (!useCase) {
        navigate("/oversight/use-cases");
        return { useCaseId: null, edit };
      }

      return { useCaseId: useCase.id, edit };
    },
    { suspense: true }
  );
};

const navigateToUseCase = async (params: {
  navigate: NavigateFunction;
  useCaseId: string;
  riskRegistryId?: string;
  useCaseNumber?: number;
  edit?: boolean;
}) => {
  const { useCaseId, useCaseNumber, edit, navigate, riskRegistryId } = params;
  let useCaseNum = useCaseNumber;
  if (!useCaseNum) {
    const { data: useCase } = await getUseCase(useCaseId);
    useCaseNum = useCase.use_case_num;
  }
  navigate(
    `/oversight/use-cases/use-case-${useCaseNum}${riskRegistryId ? `?risk=${riskRegistryId}` : ""}`,
    {
      state: {
        useCaseId,
        edit,
      },
    }
  );
};

export { navigateToUseCase };
