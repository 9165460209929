import { ICreateObjectFileAttachmentRequestBody } from "../models/IFileAttachment";
import {
  ICreateObjectCustomFieldRequestBody,
  IUpdateObjectCustomFieldRequestBody,
} from "../models/IObjectCustomField";
import {
  ApiEndpoint,
  httpDeleteAuthenticated,
  httpGetAuthenticated,
  httpPatchAuthenticated,
  httpPostAuthenticated,
  httpFormPostAuthenticated,
} from "../services/ApiService";
import { createOrUpdateParser, parsePaginatedResponseData } from "./ApiResponseHelper";

/** Helper to create CRUD and list methods for any object */
const objectMethods = <TCreateObjectRequestBody, TUpdateObjectRequestBody>(config: {
  baseEndpoint: ApiEndpoint;
}) => {
  const { baseEndpoint } = config;
  return {
    objectListGet: <T = any>(params: {
      /**key-values */
      queryParams: {
        page?: number;
        page_size?: number;
        filters?: string;
      };
      itemConverter?: (raw: any) => T;
    }) => {
      return getPaginatedListMethod({
        endpoint: baseEndpoint,
        queryParams: params.queryParams,
        itemConverter: params.itemConverter,
      });
    },

    objectGet: <T>(objectQueryId: string, itemConverter?: (raw: any) => T) => {
      return httpGetAuthenticated(baseEndpoint + "/" + objectQueryId, {}).then((response) => {
        const responseData: any = response.data;
        if (itemConverter) {
          return itemConverter(responseData);
        }

        return responseData;
      });
    },

    objectCreate: <T = any>(params: TCreateObjectRequestBody, itemConverter?: (raw: any) => T) => {
      return createOrUpdateParser<any>({
        call: () => httpPostAuthenticated(baseEndpoint, params),
        dataConverter: (raw) => {
          if (itemConverter) {
            return itemConverter(raw);
          }

          return raw;
        },
      });
    },

    objectUpdate: <T = any>(
      objectQueryId: string,
      params: TUpdateObjectRequestBody,
      itemConverter?: (raw: any) => T
    ) => {
      return createOrUpdateParser<any>({
        call: () => httpPatchAuthenticated(baseEndpoint + "/" + objectQueryId, params),
        dataConverter: (raw) => {
          if (itemConverter) {
            return itemConverter(raw);
          }

          return raw;
        },
      });
    },

    objectDelete: (objectQueryId: string) => {
      return httpDeleteAuthenticated(baseEndpoint + "/" + objectQueryId, {}).then((response) => {
        const responseData = response.data;
        return responseData;
      });
    },
  };
};

type ObjectMethods = ReturnType<typeof objectMethods>;

/** Helper to create file attachment methods for any object */
const objectFileAttachmentMethods = (config: {
  baseEndpoint: ApiEndpoint;
  /** will be passed in request object. ex: workflow */
  objectParamKey: string;
}) => {
  const { baseEndpoint, objectParamKey } = config;
  return {
    attachFileToObject: (objectId: string, params: ICreateObjectFileAttachmentRequestBody) => {
      let formData = new FormData();
      formData.append("file_object", params["file_object"]);
      formData.append(objectParamKey, objectId);
      return httpFormPostAuthenticated(baseEndpoint, formData);
    },
    removeFileFromObject: (fileId: string) => {
      // directly remove the file, it will cascade to objects
      return httpDeleteAuthenticated(ApiEndpoint.files + "/" + fileId, {});
    },
  };
};

type ObjectFileAttachmentMethods = ReturnType<typeof objectFileAttachmentMethods>;

/** Helper to manage custom field values for any object */
const objectCustomFieldValueMethods = (config: {
  baseEndpoint: ApiEndpoint;
  /** will be passed in request object. ex: workflow */
  objectParamKey: string;
}) => {
  const { baseEndpoint, objectParamKey } = config;
  return {
    objectCreateCustomFieldValue: (objectId: string, params: ICreateObjectCustomFieldRequestBody) =>
      createOrUpdateParser<ICreateObjectCustomFieldRequestBody>({
        call: () =>
          httpPostAuthenticated(baseEndpoint, {
            ...params,
            [objectParamKey]: objectId,
          }),
        dataConverter: (data) => data,
      }),
    objectUpdateCustomFieldValue: (
      customFieldValueId: string,
      params: IUpdateObjectCustomFieldRequestBody
    ) =>
      createOrUpdateParser<IUpdateObjectCustomFieldRequestBody>({
        call: () => httpPatchAuthenticated(baseEndpoint + "/" + customFieldValueId, params),
        dataConverter: (data) => data,
      }),
    objectDeleteCustomFieldValue: (customFieldValueId: string) =>
      httpDeleteAuthenticated(baseEndpoint + "/" + customFieldValueId, {}),
  };
};

type ObjectCustomFieldMethods = ReturnType<typeof objectCustomFieldValueMethods>;

const getPaginatedListMethod = <T = any>(params: {
  endpoint: string;

  /**key-values */
  queryParams: {
    page?: number;
    page_size?: number;
    filters?: string;
    joined?: boolean;
    is_active?: boolean;
    is_invited?: boolean;
  };
  itemConverter?: (raw: any) => T;
}) => {
  const baseEndpoint = params.endpoint;

  const requestQueryParams: any = params.queryParams;
  const requestQueryParamsList: string[] = [];
  for (const key in requestQueryParams) {
    if (Object.prototype.hasOwnProperty.call(requestQueryParams, key)) {
      const value = requestQueryParams[key];
      if (value !== null) {
        if (key === "filters") {
          requestQueryParamsList.push(`${value}`);
        } else {
          requestQueryParamsList.push(`${key}=${value}`);
        }
      }
    }
  }

  const getListEndpoint =
    requestQueryParamsList.length === 0
      ? baseEndpoint
      : baseEndpoint + "?" + requestQueryParamsList.join("&");

  return httpGetAuthenticated(getListEndpoint, {}).then((response) => {
    return parsePaginatedResponseData<any>(response.data, (raw) => {
      if (params.itemConverter) {
        return params.itemConverter(raw);
      }

      return raw;
    });
  });
};

const objectRelationships = (params: { baseEndpoint: string }) => {
  const { baseEndpoint } = params;
  return {
    addAction: (objectId: string, actionId: string) => {
      return httpPostAuthenticated(`${baseEndpoint}/${objectId}/add_action`, {
        action_id: actionId,
      });
    },
    removeAction: (objectId: string, actionId: string) => {
      return httpPostAuthenticated(`${baseEndpoint}/${objectId}/remove_action`, {
        action_id: actionId,
      });
    },
    addTestCase: (objectId: string, testId: string) => {
      return httpPostAuthenticated(`${baseEndpoint}/${objectId}/add_test_case`, {
        test_case_id: testId,
      });
    },
    removeTestCase: (objectId: string, testId: string) => {
      return httpPostAuthenticated(`${baseEndpoint}/${objectId}/remove_test_case`, {
        test_case_id: testId,
      });
    },

    addAsset: (objectId: string, assetId: string) => {
      return httpPostAuthenticated(`${baseEndpoint}/${objectId}/add_asset`, {
        asset_id: assetId,
      });
    },
    removeAsset: (objectId: string, assetId: string) => {
      return httpPostAuthenticated(`${baseEndpoint}/${objectId}/remove_asset`, {
        asset_id: assetId,
      });
    },
  };
};
type ObjectRelationshipsMethods = ReturnType<typeof objectRelationships>;

export {
  objectMethods,
  objectCustomFieldValueMethods,
  objectFileAttachmentMethods,
  getPaginatedListMethod,
  objectRelationships,
};
export type {
  ObjectMethods,
  ObjectCustomFieldMethods,
  ObjectFileAttachmentMethods,
  ObjectRelationshipsMethods,
};
