import { LoadingButton } from "@mui/lab";
import { Stack, Typography, useTheme } from "@mui/material";
import { useState } from "react";
import { UserCustomField } from "../../../components/CustomFieldForm";
import { SmallModal } from "../../../components/SmallModal";
import { openSnackbar } from "../../../context/SnackbarContext";
import { searchUsers } from "../../../hooks/useUsers";
import { TaskType } from "../../../models/types";
import {
  addExecutionTask,
  editExecutionTask,
  getExecutionTasks,
} from "../../../services/ExecutionPlanService";
import { useUsers } from "../../../services/UserSettingsService";
import { CustomAxiosError } from "../../../utilities/ErrorResponseHelper";

type QuestionAssignUserModalProps = {
  open: boolean;
  onClose: () => void;
  taskType: TaskType;
  questionnaireId: string;
  questionRef?: string;
  impactAssessmentId: string;
  setAssignedUser: (user: string) => void;
  userId?: string | null;
};

export const QuestionAssignUserModal = (props: QuestionAssignUserModalProps) => {
  const {
    open,
    onClose,
    taskType,
    questionRef,
    questionnaireId,
    impactAssessmentId,
    setAssignedUser,
    userId,
  } = props;
  const theme = useTheme();
  const textColor = theme.palette.custom.secondaryTypography;
  const [loading, setLoading] = useState<boolean>(false);

  const [user, setUser] = useState<string>(userId ?? "");

  const handleAssignUser = async () => {
    try {
      setLoading(true);
      // Check if already exists pending task for that before creating a new one
      if (taskType) {
        await addExecutionTask({
          parent_object_id: questionnaireId,
          parent_type: "Questionnaire",
          task_type: taskType,
          assignee: user,
          metadata: {
            question_ref: questionRef,
            impact_assessment: impactAssessmentId,
            assignees: user,
          },
          status: "PENDING",
        });
      } else {
        // Check existing tasks for the questionnaire
        const existingTasks = await getExecutionTasks({
          "metadata_impact_assessment[]": [impactAssessmentId],
          "parent_object_id[]": [questionnaireId],
          status: "PENDING",
        });
        const hasPendingReviewTask = existingTasks?.data?.results.some(
          (task) => task.task_type === "Section Review"
        );
        const hasPendingAnswerTask = existingTasks?.data?.results.some(
          (task) => task.task_type === "Answer Question"
        );
        if (hasPendingAnswerTask) {
          const taskId = existingTasks?.data?.results.find(
            (task) => task.task_type === "Answer Question"
          )?.id;
          if (taskId) {
            await editExecutionTask(taskId, {
              assignee: user,
            });
          } else {
            await addExecutionTask({
              parent_object_id: questionnaireId,
              parent_type: "Questionnaire",
              task_type: "Answer Question",
              assignee: user,
              metadata: {
                impact_assessment: impactAssessmentId,
                assignees: user,
              },
              status: "PENDING",
            });
          }
        } else if (hasPendingReviewTask) {
          const taskId = existingTasks?.data?.results.find(
            (task) => task.task_type === "Section Review"
          )?.id;
          if (taskId) {
            await editExecutionTask(taskId, {
              assignee: user,
            });
          } else {
            await addExecutionTask({
              parent_object_id: questionnaireId,
              parent_type: "Questionnaire",
              task_type: "Section Review",
              assignee: user,
              metadata: {
                impact_assessment: impactAssessmentId,
                assignees: user,
              },
              status: "PENDING",
            });
          }
        } else {
          // if no pending tasks and no task type, create a new review task
          await addExecutionTask({
            parent_object_id: questionnaireId,
            parent_type: "Questionnaire",
            task_type: "Section Review",
            assignee: user,
            metadata: {
              impact_assessment: impactAssessmentId,
              assignees: user,
            },
            status: "PENDING",
          });
        }
      }
      setAssignedUser(user);
      openSnackbar("Task assigned successfully", "success");
      onClose();
    } catch (error) {
      if (error instanceof CustomAxiosError) {
        error.showAuditLogSnackbar("Failed to create task");
      } else {
        openSnackbar("Failed to generate task, please try again", "error");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <SmallModal open={open} onClose={onClose} title="Assign User" size="small">
      <Stack gap="10px">
        <Typography variant="h3" color={textColor}>
          Select the user you want assign
        </Typography>
        <UserCustomField
          error={false}
          errorMessage=""
          onChange={setUser}
          searchUsers={searchUsers}
          useUser={useUsers}
          value={user}
        />
        <LoadingButton
          variant="contained"
          onClick={() => handleAssignUser()}
          loading={loading}
          disabled={!user}
        >
          Assign Task
        </LoadingButton>
      </Stack>
    </SmallModal>
  );
};
